<div class="mainContentDesktop">

  <ion-row>
    <!-- Col 1-->
    <ion-col size="12" size-xl="6">

      <div style="margin-left: 24px; margin-right: 24px;">

        <!-- Not logged in-->
        <div *ngIf="!GlobalFields.currentUserInfo"  style="padding: 15px; width: 100%; text-align: center">
          <h2 style="margin-top: 0; margin-bottom: 5px">Enter on Roomless</h2>
          <div style="color: #898989; font-size: 13px; margin-bottom: 15px">Rent a house or room for medium to long term directly online</div>
          <ion-button *ngIf="GlobalFields.anonymousUserType === Constants.TENANT_ID" color="primary" (click)="openLoginModal()" style="width: 100%" ><span style="color: white">Log in</span></ion-button>
          <ion-button *ngIf="GlobalFields.anonymousUserType === Constants.OWNER_ID" color="secondary" (click)="openLoginModal()" style="width: 100%" ><span style="color: white">Log in</span></ion-button>
        </div>

        <!-- Logged in -->
        <div *ngIf="GlobalFields.currentUserInfo">

          <div class="headerSection">
            <div class="headerProfile">
              <div>
                <h1>Hello, {{GlobalFields.currentUserInfo.firstName}}!</h1>
                <p>
                  <span *ngIf="GlobalFields.currentUserInfo.userTypeId === Constants.TENANT_ID" style="color: #1e8489">Tenant</span>
                  <span *ngIf="GlobalFields.currentUserInfo.userTypeId === Constants.OWNER_ID" style="color: #1e8489">Landlord</span>
                </p>
              </div>
              <div style="height:72px;width:72px;display:block">
                <img *ngIf="!GlobalFields.currentUserInfo.profilePicUrl" src="../../../../assets/imgs/user_pic-225x225.png" alt="{{GlobalFields.currentUserInfo.firstName}}" title="{{GlobalFields.currentUserInfo.firstName}}">
                <div class="imgProfileRounded" *ngIf="GlobalFields.currentUserInfo.profilePicUrl" [style.background]="'url(' +GlobalFields.currentUserInfo.profilePicUrl+')'" style="width: 72px; height: 72px; line-height: 76px"></div>
                <!--                        <img *ngIf="GlobalFields.currentUserInfo.profilePicUrl" src="{{GlobalFields.currentUserInfo.profilePicUrl}}" height="72" width="72" alt="{{GlobalFields.currentUserInfo.firstName}}" title="{{GlobalFields.currentUserInfo.firstName}}">-->
              </div>
            </div>
          </div>

        </div>
      </div>
    </ion-col>

    <!-- Col 2-->
    <ion-col size="12" size-xl="6">

      <div style="margin-left: 24px; margin-right: 24px;">

        <!-- ADMIN -->
        <div class="listSection menuCard" *ngIf="GlobalFields.currentUserInfo && GlobalFields.currentUserInfo.userTypeId === Constants.ADMIN_ID">
          <div class="titleSection">
            <div class="title">Admin Panel</div>
          </div>
          <a class="item" *ngIf="GlobalFields.checkPathPermission(PathConstants.adminDashboard)"
             [class.pageSelected]="isPageSelected(PathConstants.adminDashboard)" [routerLink]="[PathConstants.adminDashboard]" routerDirection="root">
            <div class="itemBox">
              <div class="itemIcon"><i class="fal fa-user-crown"></i></div>
              <div class="itemLabel"><div>Administration</div></div>
            </div>
          </a>
          <a class="item" *ngIf="GlobalFields.checkPathPermission(PathConstants.supplyDashboard)"
             [class.pageSelected]="isPageSelected(PathConstants.supplyDashboard)"
             [routerLink]="['/', PathConstants.supplyDashboard]" routerDirection="root"> <!-- TODO redirect to the right page like in header.html -->
            <div class="itemBox">
              <div class="itemIcon"><i class="fal fa-home"></i></div>
              <div class="itemLabel"><div>Supply Dashboard</div></div>
            </div>
          </a>
          <a class="item" *ngIf="GlobalFields.checkPathPermission(PathConstants.salesDashboard)"
             [class.pageSelected]="isPageSelected(PathConstants.salesDashboard)"
             [routerLink]="['/', PathConstants.salesDashboard, PathConstants.rentRequests]" routerDirection="root">
            <div class="itemBox">
              <div class="itemIcon"><i class="fal fa-table"></i></div>
              <div class="itemLabel"><div>Sales Dashboard</div></div>
            </div>
          </a>
          <a class="item" *ngIf="GlobalFields.checkPathPermission(PathConstants.seoDashboard)"
             [class.pageSelected]="isPageSelected(PathConstants.seoDashboard)" [routerLink]="[PathConstants.seoDashboard]" routerDirection="root">
            <div class="itemBox">
              <div class="itemIcon"><i class="fal fa-search"></i></div>
              <div class="itemLabel"><div>Seo Settings</div></div>
            </div>
          </a>

          <a class="item" *ngIf="GlobalFields.checkPathPermission(PathConstants.notificationDashboard)"
             [class.pageSelected]="isPageSelected(PathConstants.notificationDashboard)" [routerLink]="[PathConstants.notificationDashboard]" routerDirection="root">
            <div class="itemBox">
              <div class="itemIcon"><i class="fal fa-envelope"></i></div>
              <div class="itemLabel"><div>Notification dashboard</div></div>
            </div>
          </a>
          <a class="item"
             [class.pageSelected]="isRootPageSelected()" [routerLink]="['/']" routerDirection="root">
            <div class="itemBox">
              <div class="itemIcon"><i class="fal fa-chart-line"></i></div>
              <div class="itemLabel"><div>Insights</div></div>
            </div>
          </a>
        </div>


        <div style="border-bottom: 0px !important; padding-top: 24px !important; padding-bottom: 24px !important;" *ngIf="GlobalFields.currentUserInfo">
          <button class="btn-logout" (click)="logout()"><span>Exit</span></button>
        </div>
      </div>

    </ion-col>
  </ion-row>

</div>


