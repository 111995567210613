import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { NgModule } from '@angular/core';
import { MenuPage } from './menuPage';
import { TranslateModule } from '@ngx-translate/core';
import { MenuComponent } from './menuComponent/menuComponent';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AuthGuard } from '../../auth.guard';
import {PathConstants} from '../../PathConstants';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        LazyLoadImageModule,
        RouterModule.forChild([
            {
                path: '',
                component: MenuPage,
            },
            {
                path: PathConstants.profile,
                loadChildren: () => import('./profilePage/profilePage.module').then((m) => m.ProfilePageModule),
                canActivate: [AuthGuard],
            },
            {
                path: PathConstants.profile_old,
                redirectTo: PathConstants.profile
            }
        ]),
        TranslateModule,
    ],
    declarations: [MenuPage, MenuComponent],
    exports: [MenuComponent]
})
export class MenuPageModule {}
