import { Component } from '@angular/core';
import { Service } from '../../../services/Service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFields } from '../../../GlobalFields';
import { ModalController } from '@ionic/angular';
import { Constants } from '../../../Constants';
import { AuthenticationService } from '../../../services/AuthenticationService';
import { Router } from '@angular/router';
import { ModalLogin } from '../../login/modalLogin/modalLogin';
import { PathConstants } from 'src/app/PathConstants';

@Component({
  selector: 'menuComponent',
  templateUrl: 'menuComponent.html',
  styleUrls: ['menuComponent.scss'],
})
export class MenuComponent {
  GlobalFields = GlobalFields;
  Constants = Constants;
  PathConstants = PathConstants;

  constructor(
    public service: Service,
    public translate: TranslateService,
    public modalCtrl: ModalController,
    public authService: AuthenticationService,
    private router: Router
  ) {}

  async openLoginModal() {
    GlobalFields.sideMenuOpened = false;
    const loginModal = await this.modalCtrl.create({
      component: ModalLogin,
      componentProps: {
        borderless: true,
      },
    });

    await loginModal.present();
    const { data } = await loginModal.onWillDismiss();
    if (data) {
    }
  }

  isPageSelected(page: string) {
    return this.router.url.includes(page);
  }

  isRootPageSelected() {
    return this.router.url.endsWith('/');
  }

  closeSidebar() {
    this.service.closeSidebar();
  }

  logout() {
    this.authService.logout();
  }
}
