import { PathConstants } from "../../PathConstants";

/*
* Used only to set default permissions for a specific type.
 */
export enum AdminTypeEnum {
	SUPER_ADMIN = 1,
	HO_SALE = 2,
	HO_SUPPLY = 3,
	IT = 4,
	SUPPLY = 5,
	SALE = 6,
	SEO = 7,
}

export enum PermissionsNames {
	SALE_DASHBOARD = "SALE_DASHBOARD",
	SUPERADMIN = "ADMINISTRATION",
	SUPPLY_DASHBOARD = "SUPPLY_DASHBOARD",
	ADMINISTRATION_DASHBOARD = "ADMINISTRATION_DASHBOARD",
	AGENT_DASHBOARD = "AGENT_DASHBOARD",
	SEO_DASHBOARD = "SEO_DASHBOARD",
	PARTNER_DASHBOARD = "PARTNER_DASHBOARD"
}

export interface AdminTypes {
	type: number,
	key: AdminTypeEnum,
	defaultPermissions: PermissionsNames[]
}

export interface PermissionsDTO {
	id: number,
	code: PermissionsNames,
	description: string,
}

/**
 * Mapping between routing PathConstants and a set of permissions necessary to access them.
 */
export const PathPermissionsList = new Map<PathConstants, PermissionsNames[]>([
	[PathConstants.adminDashboard, [PermissionsNames.ADMINISTRATION_DASHBOARD, PermissionsNames.AGENT_DASHBOARD]],
	[PathConstants.salesDashboard, [PermissionsNames.SALE_DASHBOARD]],
	[PathConstants.supplyDashboard, [PermissionsNames.SUPPLY_DASHBOARD, PermissionsNames.PARTNER_DASHBOARD]],
	[PathConstants.seoDashboard, [PermissionsNames.SEO_DASHBOARD]],
	[PathConstants.notificationDashboard, [PermissionsNames.ADMINISTRATION_DASHBOARD, PermissionsNames.SALE_DASHBOARD, PermissionsNames.SUPPLY_DASHBOARD, PermissionsNames.AGENT_DASHBOARD, PermissionsNames.PARTNER_DASHBOARD]],
	[PathConstants.insightsDashboard, [PermissionsNames.ADMINISTRATION_DASHBOARD, PermissionsNames.SALE_DASHBOARD, PermissionsNames.SUPPLY_DASHBOARD]],
]);

/**
 * Mapping between internal dashboard pages and a set of permissions necessary to access them
 * */
export const SubPathPermissionsList = new Map<PathConstants, PermissionsNames[]>([
	[PathConstants.leads, [PermissionsNames.SUPPLY_DASHBOARD, PermissionsNames.SALE_DASHBOARD]],
	[PathConstants.listings, [PermissionsNames.SUPPLY_DASHBOARD, PermissionsNames.SALE_DASHBOARD]],
	[PathConstants.users, [PermissionsNames.SUPPLY_DASHBOARD, PermissionsNames.ADMINISTRATION_DASHBOARD, PermissionsNames.SALE_DASHBOARD]],
	[PathConstants.structures, [PermissionsNames.SUPPLY_DASHBOARD, PermissionsNames.SALE_DASHBOARD]],
	[PathConstants.partnerImport, [PermissionsNames.PARTNER_DASHBOARD]],
	[PathConstants.partnerExport, [PermissionsNames.PARTNER_DASHBOARD]],
	[PathConstants.settingsExport, [PermissionsNames.PARTNER_DASHBOARD]],
	[PathConstants.agents, [PermissionsNames.AGENT_DASHBOARD, PermissionsNames.ADMINISTRATION_DASHBOARD]],
	[PathConstants.customerCare, [PermissionsNames.AGENT_DASHBOARD, PermissionsNames.ADMINISTRATION_DASHBOARD]],
	[PathConstants.confirmedRequests, [PermissionsNames.SUPERADMIN, PermissionsNames.ADMINISTRATION_DASHBOARD]],
	[PathConstants.refundRequests, [PermissionsNames.SUPERADMIN, PermissionsNames.ADMINISTRATION_DASHBOARD]],
	[PathConstants.missingPayments, [PermissionsNames.SUPERADMIN, PermissionsNames.ADMINISTRATION_DASHBOARD]],
	[PathConstants.tenantsPayments, [PermissionsNames.ADMINISTRATION_DASHBOARD]],
	[PathConstants.landlordsPayments, [PermissionsNames.ADMINISTRATION_DASHBOARD]],
	[PathConstants.rentRequests, [PermissionsNames.SALE_DASHBOARD]],
	[PathConstants.visits, [PermissionsNames.SALE_DASHBOARD]],
	[PathConstants.places, [PermissionsNames.SEO_DASHBOARD]],
	[PathConstants.cities, [PermissionsNames.SEO_DASHBOARD]],
	[PathConstants.emails, [PermissionsNames.SALE_DASHBOARD, PermissionsNames.SUPPLY_DASHBOARD, PermissionsNames.ADMINISTRATION_DASHBOARD]],
	[PathConstants.contracts, [PermissionsNames.SUPPLY_DASHBOARD, PermissionsNames.ADMINISTRATION_DASHBOARD]],
	[PathConstants.myLeads, [PermissionsNames.SALE_DASHBOARD]],
	[PathConstants.supplyInsights, [PermissionsNames.ADMINISTRATION_DASHBOARD, PermissionsNames.SALE_DASHBOARD, PermissionsNames.SUPPLY_DASHBOARD]],
	[PathConstants.salesInsights, [PermissionsNames.ADMINISTRATION_DASHBOARD, PermissionsNames.SALE_DASHBOARD, PermissionsNames.SUPPLY_DASHBOARD]],
	[PathConstants.companyTargets, [PermissionsNames.ADMINISTRATION_DASHBOARD, PermissionsNames.SALE_DASHBOARD, PermissionsNames.SUPPLY_DASHBOARD]],
	[PathConstants.agentsInsights, [PermissionsNames.SUPERADMIN, PermissionsNames.SALE_DASHBOARD, PermissionsNames.ADMINISTRATION_DASHBOARD, PermissionsNames.AGENT_DASHBOARD]],
]);

