import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {AuthenticationService} from '../services/AuthenticationService';
import {AuthInfo} from '../entities/RoomlessHttpResponse';
import {GlobalFields} from '../GlobalFields';
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import { Constants } from '../Constants';
import { HelpersService } from '../services/helpers.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private helpersService: HelpersService,
		private toastrService: ToastService
	) {}

	/*
	* Intercept when token changes and redirect to login page.
	* Solution from: https://stackoverflow.com/questions/46017245/how-to-handle-unauthorized-requestsstatus-with-401-or-403-with-new-httpclient
	* */
	private handleAuthError(err: HttpErrorResponse): Observable<any> {
		if (err.status === 401 || err.status === 403) {
			if (GlobalFields.loadingSoft === true) {
				GlobalFields.loadingSoft = false;
			}

			// Logout user if unauthorized
			localStorage.removeItem('authRoomless');
			AuthenticationService.actualToken = undefined;
			GlobalFields.currentUserInfo = undefined;

			this.router.navigate(['/login']);
			this.toastrService.error('Please double check your credentials.', this.helpersService.findErrorTitle(err), Constants.ToastOptions);
			return of(err.message);
		}
		return throwError(err);
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = AuthInterceptor.getToken();
		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
					withCredentials: 'true'
				},
			});
		}

		if (!GlobalFields.avoidApplicationJson) //Used by upload to avoid
			request = request.clone({
				setHeaders: {
					'Content-Type': 'application/json'
				},
			});

		GlobalFields.avoidApplicationJson = false;
		// return next.handle(request);
		return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
	}

	public static getToken(): string | null {
		if (AuthenticationService.actualToken) {
			return AuthenticationService.actualToken;
		}

		if (GlobalFields.isPlatformBrowser()) {
			const authRoomless = localStorage.getItem('authRoomless');
			const authInfo = JSON.parse(authRoomless) as AuthInfo;
			return authInfo?.token;
		}

		return null;
	}
}
