import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './services/AuthenticationService';
import { GlobalFields } from './GlobalFields';

@Injectable()
export class AuthGuardAdmin implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (AuthenticationService.isLoggedIn() && GlobalFields.isAdmin()) {
      return true;
    }
    console.log('Not authenticated');
    this.router.navigate(['/login']);
    return false;
  }
}
