import { Component, Input } from '@angular/core';
import { GlobalFields } from '../../GlobalFields';
import { Constants } from '../../Constants';
import { AuthenticationService } from '../../services/AuthenticationService';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalLogin } from '../../components/login/modalLogin/modalLogin';
import { Service } from '../../services/Service';
import { PathConstants } from 'src/app/PathConstants';

@Component({
  selector: 'app-mobileFooter',
  templateUrl: 'mobileFooter.component.html',
  styleUrls: ['mobileFooter.component.scss'],
})
export class MobileFooterComponent {
  GlobalFields = GlobalFields;
  Constants = Constants;
  PathConstants = PathConstants;

  @Input() isKeyboardHide: boolean;

  backDropStatus = false;

  constructor(public service: Service, public modalCtrl: ModalController, private router: Router) {}

  goTo(url: string) {
    this.router.navigate([url, { language: 'it' }]);
    // this.router.navigateByUrl(url);
  }

  isSelected(tab: string) {
    return this.router.url.includes(tab);
  }

  getFooterHeight() {
    const footer = document.getElementById('footer');
    if (footer) {
      return Math.round(footer.offsetHeight / 2 - 15) + 'px';
    }
  }

  isLoggedIn(): boolean {
    return AuthenticationService.isLoggedIn();
  }

  async openLoginAddListing() {
    if (!this.GlobalFields.currentUserInfo) {
      const loginModal = await this.modalCtrl.create({
        component: ModalLogin,
        componentProps: {
          borderless: true,
          isAOwner: true,
        },
      });
      await loginModal.present();
      const { data } = await loginModal.onWillDismiss();
      if (data) {
        this.router.navigate(['/' + PathConstants.createListing]);
      }
    } else if (GlobalFields.currentUserInfo.userTypeId === Constants.OWNER_ID) {
      this.router.navigate(['/' + PathConstants.createListing]);
    } else {
      this.router.navigate(['/home']);
    }
  }

  openSidebar() {
    this.service.openSidebar();
  }

  closeSidebar() {
    this.service.closeSidebar();
  }

  isOwner() {
    if (!GlobalFields.currentUserInfo && GlobalFields.anonymousUserType === Constants.OWNER_ID) {
      return true;
    }
    return false;
  }

  isStudent() {
    if (!GlobalFields.currentUserInfo && GlobalFields.anonymousUserType === Constants.TENANT_ID) {
      return true;
    }
    return false;
  }

  closeButton() {
    const fabs = document.querySelectorAll('ion-fab');
    if (fabs && fabs.length > 0 && fabs[0]) {
      fabs[0].activated = false;
    }
    this.backDropStatus = false;
  }
}
