<ion-footer id="footerTabBar" translucent="false" [mode]="'ios'" *ngIf="GlobalFields.dataIsReady && !isSelected('/' + PathConstants.createListing) && !isSelected('/' + PathConstants.listingPageRent + '/') && !isSelected('/' + PathConstants.listingPageSale + '/') && isKeyboardHide">
    <ion-toolbar translucent="true" >
        <!-- Footer sloggati-->
        <table *ngIf="!GlobalFields.currentUserInfo" class="buttonsFooterTabs">
            <tr>
                <td [class.selectedTab]="isStudent() && ( isSelected('home')|| isSelected('home-proprietari'))" [class.selectedTabOwner]="isOwner() && (isSelected('home')|| isSelected('home-proprietari'))">
                    <button *ngIf="!GlobalFields.currentUserInfo && GlobalFields.anonymousUserType === Constants.TENANT_ID"
                            routerLink="/home" routerDirection="root">
                        <img *ngIf="isSelected('home')|| isSelected('home-proprietari')" [lazyLoad]="'./assets/imgs/logo/icon_red_selected.png'" alt="Roomless logo"/>
                        <img *ngIf="!isSelected('home') && !isSelected('home-proprietari')" [lazyLoad]="'./assets/imgs/logo/icon_deselected.png'" alt="Roomless logo"/>
                        <span style="position: relative; top: 1px;">Home</span>
                    </button>
                    <button *ngIf="!GlobalFields.currentUserInfo && GlobalFields.anonymousUserType === Constants.OWNER_ID"
                            routerLink="/home-proprietari" routerDirection="root">
                        <img *ngIf="isSelected('home')|| isSelected('home-proprietari')" [lazyLoad]="'./assets/imgs/logo/icon_green_selected.png'" alt="Roomless logo"/>
                        <img *ngIf="!isSelected('home') && !isSelected('home-proprietari')" [lazyLoad]="'./assets/imgs/logo/icon_deselected.png'" alt="Roomless logo"/>
                        <span style="position: relative; top: 1px;">Home</span>
                    </button>
                </td>

                <td [class.selectedTab]="isOwner() && isSelected(PathConstants.createListing)" [class.selectedTabOwner]="isOwner() && isSelected(PathConstants.createListing)"  *ngIf="!GlobalFields.currentUserInfo && GlobalFields.anonymousUserType === Constants.OWNER_ID">
                    <button (click)="openLoginAddListing()"> <i class="fal fa-plus" ></i> <span>{{"ADD" | translate}}</span></button>
                </td>
                <td [class.selectedTab]="isSelected(PathConstants.howItWorks)"  [class.selectedTabOwner]="isOwner() && isSelected(PathConstants.howItWorks)" >
                    <button [routerLink]="['/' + PathConstants.howItWorks]"  routerDirection="root"> <i class="fal fa-question-circle"></i> <span>Info</span></button>
                </td>
                <td>
                    <button *ngIf="!GlobalFields.sideMenuOpened" (click)="openSidebar()">
                        <i class="fal fa-bars"></i> <span>{{"MENU" | translate}}</span>
                    </button>
                    <button *ngIf="GlobalFields.sideMenuOpened" (click)="closeSidebar()">
                        <i class="fal fa-times colorPrimary"></i> <span>{{"MENU" | translate}}</span>
                    </button>
                </td>
        </table>
        <!-- Footer admin -->
        <table *ngIf="GlobalFields.currentUserInfo && GlobalFields.currentUserInfo.userTypeId === Constants.ADMIN_ID"  class="buttonsFooterTabs">
            <tr>
                <td [class.selectedTab]="isSelected('home')">
                    <button routerLink="/home" routerDirection="root">
                        <img *ngIf="isSelected('home')|| isSelected('home-proprietari')" src="./assets/imgs/logo/icon_red_selected.png" alt="Roomless logo"/>
                        <img *ngIf="!isSelected('home') && !isSelected('home-proprietari')" src="./assets/imgs/logo/icon_deselected.png" alt="Roomless logo"/>
                        <span style="position: relative; top: 1px;">Home</span>
                    </button>
                </td>
                <td [class.selectedTabOwner]="isSelected(PathConstants.createListing)">
                    <button [routerLink]="['/' + PathConstants.createListing]"  routerDirection="root"> <i class="fal fa-plus"></i> <span>{{"ADD" | translate}}</span></button>
                </td>
                <td [class.selectedTab]="isSelected('visite')">
                    <button [routerLink]="['/visite']"  routerDirection="root"> <i class="fal fa-door-open"></i> <span>{{"REQUESTS" | translate}}</span>
                        <span *ngIf="GlobalFields.num_Incoming_Virtual_Visit > 0" class="badgeNum">
                            {{GlobalFields.num_Incoming_Virtual_Visit}}
                        </span>
                    </button>
                </td>
                <td [class.selectedTab]="isSelected('admin-panel')">
                    <button [routerLink]="['/admin-panel']"  routerDirection="root"> <i class="fal fa-user-crown"></i> <span>Admin</span></button>
                </td>
                <td>
                    <button *ngIf="!GlobalFields.sideMenuOpened" (click)="openSidebar()">
                        <i class="fal fa-bars"></i>
                    </button>
                    <button *ngIf="GlobalFields.sideMenuOpened" (click)="closeSidebar()">
                        <i class="fal fa-times colorPrimary"></i>
                    </button>
                </td>
        </table>
        <!-- Footer owner loggato-->
        <table *ngIf="GlobalFields.currentUserInfo && GlobalFields.currentUserInfo.userTypeId === Constants.OWNER_ID"  class="buttonsFooterTabs">
            <tr>
                <td [class.selectedTabOwner]="isSelected('home')">
                    <button routerLink="/home" routerDirection="root">
                        <img *ngIf="isSelected('home')|| isSelected('home-proprietari')" src="./assets/imgs/logo/icon_green_selected.png" alt="Roomless logo"/>
                        <img *ngIf="!isSelected('home') && !isSelected('home-proprietari')" src="./assets/imgs/logo/icon_deselected.png" alt="Roomless logo"/>
                        <span style="position: relative; top: 1px;">Home</span>
                    </button>

                </td>
                <td [class.selectedTabOwner]="isSelected(PathConstants.createListing)">
                    <button [routerLink]="['/' + PathConstants.createListing]"  routerDirection="root"> <i class="fal fa-plus"></i> <span>{{"ADD" | translate}}</span></button>
                </td>
                <td [class.selectedTabOwner]="isSelected(PathConstants.desk)">
                    <button [routerLink]="['/' + PathConstants.desk]"  routerDirection="root"> <i class="fal fa-browser"></i> <span>Desk</span></button>
                </td>
                <td [class.selectedTabOwner]="isSelected(PathConstants.calendar)">
                    <button routerLink="/{{PathConstants.calendar}}" routerDirection="root"> <i class="fal fa-calendar"></i> <span>{{"AGENDA" | translate}}</span>
                        <span *ngIf="GlobalFields.num_Pending_Slot+GlobalFields.num_Incoming_Virtual_Visit+GlobalFields.num_Incoming_Physical_Visit > 0" class="badgeNum">
                            {{GlobalFields.num_Pending_Slot+GlobalFields.num_Incoming_Virtual_Visit+GlobalFields.num_Incoming_Physical_Visit}}
                        </span>
                    </button>
                </td>
                <td [class.selectedTabOwner]="isSelected('visite')">
                    <button [routerLink]="['/visite']"  routerDirection="root"> <i class="fal fa-door-open"></i> <span>{{"REQUESTS" | translate}}</span>
                        <span *ngIf="GlobalFields.num_Incoming_Virtual_Visit > 0" class="badgeNum">
                            {{GlobalFields.num_Incoming_Virtual_Visit}}
                        </span>
                    </button>
                </td>
                <!--<td></td>-->
                <td>
                    <button *ngIf="!GlobalFields.sideMenuOpened" (click)="openSidebar()">
                        <i class="fal fa-bars"></i>
                    </button>
                    <button *ngIf="GlobalFields.sideMenuOpened" (click)="closeSidebar()">
                        <i class="fal fa-times colorPrimary"></i>
                    </button>
                </td>
        </table>
    </ion-toolbar>
</ion-footer>
