import {Injectable} from "@angular/core";
import {CanActivate} from "@angular/router";
import {AuthenticationService} from "./services/AuthenticationService";
import {GlobalFields} from "./GlobalFields";
import {Observable, of} from "rxjs";
import {map, catchError} from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthenticationService) {}

	canActivate(next, state): Observable<boolean> {
		if (GlobalFields.currentUserInfo) {
			return of(true);
		}

		if (GlobalFields.isPlatformBrowser()) {
			return this.authService.isLoggedInObservable.pipe(
				map((status) => {
					return status;
				}),
				catchError((_) => {
					return of(false);
				}),
			);
		} else {
			return of(true);
		}
	}
}
