import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './auth.guard';
import {AuthGuardAdmin} from './authGuardAdmin';
import {QuicklinkModule, QuicklinkStrategy} from 'ngx-quicklink';
import {PathConstants} from './PathConstants';
import {PermissionGuard} from "./permission.guard";
import {AuthIsLoggedIn} from "./authIsLoggedIn";

const children: Routes = [
	{
		path: '',
		redirectTo: `/${PathConstants.insightsDashboard}`,
		pathMatch: 'full',
	},
	{
		path: PathConstants.login,
		loadChildren: () =>
			import('./components/login/loginPage/loginPage.module').then((m) => m.LoginPageModule),
	},
	{
		path: PathConstants.insightsDashboard,
		loadChildren: () =>
			import('./views/homepageDashboard/home.module').then((m) => m.HomeModule),
		canActivate: [AuthGuard, AuthIsLoggedIn],
		data: {path: PathConstants.insightsDashboard},
	},
	{
		path: PathConstants.adminDashboard,
		loadChildren: () =>
			import('./views/adminDashboard/admin-dashboard.module').then((m) => m.AdminDashboardModule),
		canActivate: [AuthGuard, AuthIsLoggedIn],
		data: {path: PathConstants.adminDashboard},
	},
	{
		path: PathConstants.salesDashboard,
		loadChildren: () =>
			import('./views/salesDashboard/sales-dashboard.module').then((m) => m.SalesDashboardModule),
		canActivate: [AuthGuard, AuthIsLoggedIn],
		data: {path: PathConstants.salesDashboard}

	},
	{
		path: PathConstants.supplyDashboard,
		loadChildren: () =>
			import('./views/supplyDashboard/supply-dashboard.module').then((m) => m.SupplyDashboardModule),
		canActivate: [AuthGuard, AuthIsLoggedIn],
		data: {path: PathConstants.supplyDashboard}
	},
	{
		path: PathConstants.seoDashboard,
		loadChildren: () =>
			import('./views/seoDashboard/seo-dashboard.module').then((m) => m.SeoDashboardModule),
		canActivate: [AuthGuard, AuthIsLoggedIn],
		data: {path: PathConstants.seoDashboard}
	},
  {
		path: PathConstants.notificationDashboard,
		loadChildren: () =>
			import('./views/emailDashboard/notification-dashboard.module').then((m) => m.NotificationDashboardModule),
		canActivate: [AuthGuard, AuthIsLoggedIn],
		data: {path: PathConstants.notificationDashboard}
	},
  {
		path: PathConstants.travelDashboard,
		loadChildren: () =>
			import('./views/travelDashboard/travel-dashboard.module').then((m) => m.TravelDashboardModule),
		canActivate: [AuthGuard, AuthIsLoggedIn],
		data: {path: PathConstants.travelDashboard}
	},

	{path: '**', redirectTo: '', pathMatch: 'full'},
];


const routes: Routes = [
	{
		path: '',
		children: children,
	},
	{path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
	imports: [
		QuicklinkModule,
		RouterModule.forRoot(routes, {preloadingStrategy: QuicklinkStrategy}),
		//RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy, enableTracing: true }),

	],
	exports: [RouterModule],
	providers: [AuthGuard, AuthGuardAdmin, AuthIsLoggedIn, PermissionGuard],
})
export class AppRoutingModule {
}
