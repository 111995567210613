<div *ngIf="!softLoading"
  style="position: absolute; height: 100%; width: 100%; z-index: 99999; background-color: white;">
  <div style="height: 100%; width: 100%; background-color: white;">
    <div *ngIf="GlobalFields.errorIni"
      style="position: fixed; text-align: center; margin: auto; bottom: 10%; width: 100%;">
      Server not avaiable, try again <br />
      <ion-button color="success"><i class="fas fa-redo-alt" style="margin-right: 5px;"></i> Refresh</ion-button>
      <br /><br />
      <span style="color: red; font-size: smaller;">{{GlobalFields.errorString}}</span>
    </div>
  </div>

  <!--<div class="cssload-spin-box"></div>-->

  <img src="../../../assets/imgs/logo/logo_roomless_for_spacest.png" alt="loading" style="
     height: 25px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -75px;
    " />
</div>

<div *ngIf="softLoading" style="height: 100%; width: 100%; z-index: 99999; position: absolute;">
  <div style="height: 100%; width: 100%; position: fixed; background-color: rgba(26, 26, 26, 0.6);"></div>
  <!--<div class="cssload-spin-box"></div>-->
  <img src="../../../assets/imgs/logo/logo_roomless_for_spacest.png" alt="loading" style="
      height: 25px;
      position: fixed;
      top: 50%;
      left: 50%;
      margin-top: -30px;
      margin-left: -75px;
    " />
</div>