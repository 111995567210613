<!-- Login -->
<div class="loginBox" [class.headerMargin]="!isAComponent" style="padding: 20px 10px;" [class.loginBoxDesktop]="borderless">

  <!-- Login -->
  <span *ngIf="viewMode === 1">

     <div class="login-logo">
      <div class="logo">
          <img style="height: 40px; cursor: pointer" src="./assets/imgs/logo/logo_roomless_for_spacest.png" alt="Roomless">
      </div>
    </div>

    <ion-text *ngIf="!hideTexts" color="dark" class="ion-text-center">
        <h1 style="margin-top: 0; margin-bottom: 5px">Enter on Roomless Admin Panel</h1>
    </ion-text>
    <hr style="background-color: #d2d2d2; margin: 30px 20px;">
    <div class="alert alert-danger" role="alert" *ngIf="errorMgs">
        {{errorMgs}}
    </div>
    <form #formLogin="ngForm" name="form" (ngSubmit)="login()" novalidate>
        <div>
            <span class="titleInput">Email or username</span>
            <input #usernameField class="form-control inputForm" type="text" autocapitalize="off"
                   [(ngModel)]="model.username" name="username"
                   #username="ngModel" [ngClass]="{ 'is-invalid': formLogin.submitted && username.invalid }"
                   required placeholder="Email"
            />
            <div *ngIf="formLogin.submitted && username.invalid" class="invalid-feedback">
                <div *ngIf="username.errors.required">Email required.</div>
            </div>
        </div>
        <br>
        <div>
            <span class="titleInput">Password</span>
            <input class="form-control inputForm" type="password" autocapitalize="off" [(ngModel)]="model.password"
                   name="password"
                   #password="ngModel" [ngClass]="{ 'is-invalid': formLogin.submitted && password.invalid }"
                   required placeholder="Password"/>
            <div *ngIf="formLogin.submitted && password.invalid" class="invalid-feedback">
                <div *ngIf="password.errors.required">Password required.</div>
            </div>
        </div>

        <div style="margin: 0 auto; margin: 10px auto; text-align: center;">
            <span (click)="viewMode = 3; clearErrorMsg();" style="color: #333; cursor: pointer"
                  class="text-underlineOnHover"><i class="fal fa-lock"
                                                   style="margin-right: 10px;"></i> Forgot password?</span>
        </div>

        <button class="btn-general btn-primary" type="submit">
            <span style="color: white">Log in</span>
        </button>
    </form>
  </span>
</div>

<div *ngIf="viewMode === 3" style="padding: 20px">

  <ion-text color="dark" class="ion-text-left">
    <h1 style="margin-top: 0; margin-bottom: 5px">Reset password</h1>
    <div style="font-size: 16px; margin-bottom: 15px">Enter the email address associated with your account.</div>
  </ion-text>

  <form #formRecoverPass="ngForm" name="form" (ngSubmit)="formRecoverPass.form.valid && forgotPass()" novalidate>
        <span>
            <div>
                <span class="titleInput">Email</span>
                <input class="form-control inputForm" type="text" autocapitalize="off" [(ngModel)]="newProfile.email"
                       name="email"
                       #email="ngModel" [ngClass]="{ 'is-invalid': formRecoverPass.submitted && email.invalid }"
                       required email placeholder="Email"
                />
                <div *ngIf="formRecoverPass.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required">Email required.</div>
                    <div *ngIf="email.errors.email">Invalid email.</div>
                </div>
            </div>
        </span>
    <div class="alert alert-danger" role="alert" *ngIf="errorMgs && newProfile.email !== ''">
      {{errorMgs}}
    </div>

    <div style="-webkit-box-align: center !important;
        text-align: justify !important;
        line-height: 0 !important;
        display: flex !important;
        flex-wrap: wrap !important;
        align-items: center !important; margin-top: 24px">
      <div style="-webkit-box-flex: 1 !important;
        display: inline-block !important;
        vertical-align: middle !important;
        text-align: left !important;
        flex-grow: 1 !important;">
        <div style="margin-top: 8px; margin: 0px !important;
        word-wrap: break-word !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 1.375em !important;
    color: #008489" (click)="viewMode = 1; clearErrorMsg();"><i class="far fa-chevron-left"></i> <span
          class="underlineOnHover">Back to login page</span></div>

      </div>

      <div style="display: inline-block !important;
        vertical-align: middle !important;">
        <button class="btn-general btn-primary" type="submit">
          <span style="color: white">Confirm</span>
        </button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="viewMode === 4" style="padding: 20px">

  <ion-text color="dark" class="ion-text-left">
    <h1 style="margin-top: 0; margin-bottom: 5px">Reset password</h1>

    <div class="alert alert-success" role="alert">
      <div
        style="font-size: 16px; margin-bottom: 15px">You have successfully completed the password recovery request, you will soon receive an email with a temporary password.</div>
    </div>
  </ion-text>

  <div style="-webkit-box-align: center !important;
        text-align: justify !important;
        line-height: 0 !important;
        display: flex !important;
        flex-wrap: wrap !important;
        align-items: center !important; margin-top: 24px">
    <div style="-webkit-box-flex: 1 !important;
        display: inline-block !important;
        vertical-align: middle !important;
        text-align: left !important;
        flex-grow: 1 !important;">
      <div style="margin-top: 8px; margin: 0px !important;
        word-wrap: break-word !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 1.375em !important;
        color: #008489" (click)="viewMode = 1; clearErrorMsg();"><i class="far fa-chevron-left"></i> <span
        class="underlineOnHover">Back to login page</span></div>
    </div>
  </div>
</div>
