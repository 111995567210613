import { Inject, Injectable } from '@angular/core';
import { Constants } from '../Constants';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Listing } from '../entities/listing/listing';
import { Filter } from '../entities/filter';
import { GlobalFields } from '../GlobalFields';
import { BillingDetails, Profile, TenantFunnelStatus } from '../entities/profile';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { RoomlessHttpResponse } from '../entities/RoomlessHttpResponse';
import { Place } from '../entities/place';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Structure } from '../entities/listing/structure';
import { AccessData } from '../entities/accessData';
import { Rent, Visit } from '../entities/calendar/Visit';
import { AdminAgents, AdminPartner, AgentsFilters } from '../entities/adminSettings';
import { ListingSales } from '../entities/sales/listingSales';
import { PathConstants } from '../PathConstants';
import { City } from '../entities/city';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { TrackingData } from '../entities/trackingData';

import { Coupon, LeadsCount, LocareChangeStatusDto, PaymentAccount, PaymentMethods, Transfer, UserActionTypeEnum } from "../Model";

import { RefundRequest } from "../entities/refunds/Refunds";
import { PayoutsFilter } from "../entities/transaction";
import { SupplyLead } from '../entities/supplyLead';
import { LocareContractData, LocareContractFilters } from "../entities/contractsLocare/contractsLocare";
import { InfoRequest } from '../entities/infoRequest';
import * as Insights from '../entities/Insights';
import { AuthInterceptor } from "../interceptors/auth.interceptor";
import { Invoice } from '../entities/invoice';

@Injectable({
  providedIn: 'root', // singleton
})
export class Service {

  GlobalFields: GlobalFields;

  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  serverURL = !GlobalFields.isTestEnvironment ? Constants.serverURL : Constants.testServerURL;
  uploadServerURL = !GlobalFields.isTestEnvironment ? Constants.uploadServerURL : Constants.testUploadServerURL;
  APIVersion = 'v1/'; //v1/, v2/

  public httpIgnoreInterceptor: HttpClient;

  constructor(
    private http: HttpClient,
    private httpIgnoreInterceptorHandler: HttpBackend,
    public platform: Platform,
    private location: Location,
    private router: Router,
    private navCrl: NavController,
    private route: ActivatedRoute,
    private menu: MenuController,
    @Inject(LOCAL_STORAGE) private localStorage: any
  ) {
    this.httpIgnoreInterceptor = new HttpClient(httpIgnoreInterceptorHandler); //used to do http calls ignoring the Interceptor
  }

  // Check if is localhost or beta environment
  setServerURl() {
    if (GlobalFields.isPlatformBrowser()) { //Or location is not present in server
      if ((location.hostname.includes('beta') || location.hostname.includes('localhost') || location.hostname.includes('vercel.app'))) {
        GlobalFields.isTestEnvironment = true;
        this.serverURL = !GlobalFields.isTestEnvironment
          ? Constants.serverURL
          : Constants.testServerURL;
      }

    }
  }

  getServices() {
    const url = this.serverURL + Constants.listing_module + 'services';
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getFeatures(listingType: number) {
    const url =
      this.serverURL + Constants.server_suffix1 + 'features?listingTypeIds=' + listingType;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  downloadCsvleadPrivate() {
    const url = this.serverURL + Constants.admin_module + 'lead/landlord/private/csv';
    return this.http.get<Blob>(url, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  downloadCsvleadProfessional() {
    const url = this.serverURL + Constants.admin_module + 'lead/landlord/professional/csv';
    return this.http.get<Blob>(url, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  getUserInfo() {
    this.setServerURl();
    const url = this.serverURL + Constants.user_module + 'user/info';
    return this.http.get<RoomlessHttpResponse>(url);
  }



  getRequiredDocumentsTemplates() {
    const url = this.serverURL + Constants.user_module + 'user/required-documents-templates';
    return this.http.get<RoomlessHttpResponse>(url);
  }

  editUserDetail(profile: Profile) {
    const url = this.serverURL + Constants.user_module + 'user/edit';
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(profile));
  }
  updateUserAgent(profile: Profile) {
    const url = this.serverURL + Constants.user_module + 'user/update-user-agent';
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(profile));
  }

  changeUserFunnelStatus(userId: number, status: number) {
    const url = this.serverURL + Constants.user_module + 'user/update-funnel-status';
    const body = {
      id: userId,
      statusFlow: status
    }
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(body));
  }

  deleteUser(userId: number) {
    const url = this.serverURL + Constants.user_module + 'user?userId=' + userId;
    return this.http.delete<RoomlessHttpResponse>(url);
  }

  editBillingDetail(billingDetails: BillingDetails) {
    const url = this.serverURL + Constants.user_module + 'billing/edit';
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(billingDetails));
  }

  editUserPassword(accessData: AccessData) {
    const url = this.serverURL + Constants.user_module + 'user/change_password';
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(accessData));
  }

  getUserByIdAdmin(id: number) {
    const url = this.serverURL + Constants.user_module + 'user?userId=' + id;
    return this.http.get<RoomlessHttpResponse>(url);
  }

  createAdminUser(profile: Profile) {
    const url = this.serverURL + Constants.user_module + 'user/register';
    return this.http.post<RoomlessHttpResponse>(url, profile);
  }

  changeUserStatus(id, status) {
    const url = this.serverURL + Constants.user_module + 'user/change_status';
    const body = {
      userId: id,
      statusId: status,
    };
    return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(body));
  }

  enterAsUser(id) {
    const url = this.serverURL + Constants.user_module + 'user/generate_user_token';
    const body = {
      id: id,
    };
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(body));
  }


  addManualInvoice(invoice: Invoice) {
    const url = `${this.serverURL}${Constants.admin_module}invoice/add-invoice`;
    return this.http.post<RoomlessHttpResponse>(url, invoice);
  }

  /* Permissions Endpoints */

  getAdminTypesPermissions() {
    const url = `${this.serverURL}${Constants.user_module}user/admin-types`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getSupportedPermissions() {
    const url = `${this.serverURL}${Constants.admin_module}permissions`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getCountryList() {
    const url = this.serverURL + Constants.place_module + 'countries';
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getUTMList() {
    const url = this.serverURL + Constants.user_module + 'user/utm/search';
    const body = {
      "type": 1, //1=source, 2=campaign, 3=medium
    };
    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  getAddressComponents(unformattedAddress: string) {
    const url = this.serverURL + this.APIVersion + Constants.listing_module + 'structure/address';
    const body = {
      unformattedAddress: unformattedAddress
    };
    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  getItalianProvinces() {
    const url = this.serverURL + Constants.place_module + "/state/province-italiane";
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getStatesList(countryId: number) {
    // province
    const url = this.serverURL + Constants.server_suffix1 + 'states?countryId=' + countryId;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getCitiesList(stateId: number) {
    // Comuni
    const url = this.serverURL + Constants.server_suffix1 + 'cities?stateId=' + stateId;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getLanguagesList() {
    const url = this.serverURL + Constants.place_module + 'languages';
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getCurrenciesList() {
    const url = this.serverURL + Constants.place_module + 'countries/currencies';
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getOccupationsList() {
    const url = this.serverURL + Constants.server_suffix1 + 'occupations';
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  createListing(listing: Listing) {
    const url = this.serverURL + Constants.listing_module + 'listing/create';
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(listing));
  }

  updateListingNote(id: number, note: string, salesNote: string) {
    const url = this.serverURL + Constants.listing_module + 'listing/update-note';
    const body = {
      id: id,
      note: note,
      salesNote: salesNote
    };
    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  updateListingSalesNote(id: number, note: string) {
    const url = this.serverURL + Constants.server_suffix1 + 'sale-listing/update-note';
    const body = {
      id: id,
      note: note,
    };
    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  updateVisitNote(id: number, note: string) {
    const url = this.serverURL + Constants.server_suffix1 + 'visit/update-note';
    const body = {
      id: id,
      note: note,
    };
    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  editListing(listing: Listing) {
    const url = this.serverURL + Constants.listing_module + 'listing/edit';
    return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(listing));
  }

  deleteListing(listingId: number) {
    const url = this.serverURL + Constants.listing_module + 'listing?listingId=' + listingId;
    return this.http.delete<RoomlessHttpResponse>(url);
  }

  editListingSale(listing: ListingSales) {
    const url = this.serverURL + Constants.server_suffix1 + 'sale-listing/edit';
    return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(listing));
  }

  createStructure(structure: Structure) {
    const url = this.serverURL + Constants.listing_module + 'structure/create';
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(structure));
  }

  editStructure(structure: Structure) {
    const url = this.serverURL + Constants.listing_module + 'structure/edit';
    return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(structure));
  }

  deleteStructure(structureId: number) {
    const url = this.serverURL + Constants.listing_module + 'structure?structureId=' + structureId;
    return this.http.delete<RoomlessHttpResponse>(url);
  }

  changeListingStatus(id, status) {
    const url = this.serverURL + Constants.listing_module + 'listing/change_status';
    const body = {
      listingId: id,
      statusId: status,
    };
    return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(body));
  }

  changeListingStatusSales(id, status) {
    const url = this.serverURL + Constants.server_suffix1 + 'sale-listing/change_status';
    const body = {
      listingId: id,
      statusId: status,
    };
    return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(body));
  }

  getOwnerListingsList(filter: any) {
    const url = this.serverURL + Constants.listing_module + 'listing/my_listings';

    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  getMyStructuresList(ownerId?: any, filter?: any) {
    let param = '';
    if (ownerId) {
      param = '?ownerId=' + ownerId;
    }
    const url = this.serverURL + Constants.listing_module + 'structure/my-structures' + param;
    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  uploadImg(file: Blob, name: string) { //Supports also pdf
    const url = this.uploadServerURL + Constants.rest_prefix + Constants.upload_module + 'upload'
    const body = new FormData();
    body.append('file', file, name);

    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    GlobalFields.avoidApplicationJson = true;

    return this.http.post<RoomlessHttpResponse>(url, body, { headers: headers });
  }

  uploadFile(file: Blob, name: string) {
    const url = this.uploadServerURL + Constants.server_suffix1 + 'stream';
    const body = new FormData();
    body.append('file', file, name);
    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    return this.httpIgnoreInterceptor.post<RoomlessHttpResponse>(url, body, { headers: headers });
  }

  getListingById(id: number) {
    const url = this.serverURL + Constants.listing_module + 'listing?listingId=' + id;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getCustomerCarePhoneNumber() {
    const url = this.serverURL + Constants.server_suffix1 + 'setting/customer_care_number';
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getListingByIdAuthorized(id: number) {
    const url = this.serverURL + Constants.listing_module + 'listing?listingId=' + id;
    return this.http.get<RoomlessHttpResponse>(url);
  }

  getStructureVisitsById(structureId: number) {
    let url =
      this.serverURL +
      Constants.server_suffix1 +
      'visit/visit-for-structure?structureId=' +
      structureId;
    const param = '';
    url = url + param;

    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getMyListingById(id: number) {
    const url = this.serverURL + Constants.listing_module + 'listing?listingId=' + id + '&lang=' + GlobalFields.currentLanguage;
    return this.http.get<RoomlessHttpResponse>(url);
  }

  getStructureById(id: any) {
    const url = this.serverURL + Constants.listing_module + 'structure?structureId=' + id;
    return this.http.get<RoomlessHttpResponse>(url);
  }

  getListingByUserId(filter: Filter) {
    const url = this.serverURL + Constants.listing_module + 'listing/search';
    return this.http.post<RoomlessHttpResponse>(url, filter, { headers: this.headers });
  }

  searchUser(filter: Filter) {
    const url = this.serverURL + Constants.user_module + 'user/search';
    // return this.http.post<RoomlessHttpResponse>(url, filter, {headers : this.headers});

    return this.http.post<RoomlessHttpResponse>(url, filter);
  }
  forceCallbellUserUpdate(userId: number, channelType?: number) { // channelType=2 if sales 3 if supply
    const url = this.serverURL + Constants.user_module + 'user/sync-callbell-contact';
    // return this.http.post<RoomlessHttpResponse>(url, filter, {headers : this.headers});
    return this.http.post<RoomlessHttpResponse>(url, { "userId": userId, "userType": channelType });
  }

  searchVisit(filter: Filter) {
    const url = this.serverURL + Constants.server_suffix1 + 'visit/search-for-admin';

    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  searchTransaction(filter: Filter) {
    const url = this.serverURL + Constants.transaction_module + 'transaction/search-for-admin';

    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  searchSentTransactions(filter: PayoutsFilter) {
    const url = this.serverURL + Constants.transaction_module + 'payout/completed/search';

    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  getMyTransactions(filter: Filter) {
    const url = this.serverURL + Constants.transaction_module + 'transaction/my_transactions';
    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  stripeTransactions(id: number) {
    const url = this.serverURL + Constants.transaction_module + `transaction/payment-links?rentRequestId=${id}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  generateStripeLink(body) {
    const url = this.serverURL + Constants.transaction_module + `transaction/payment-link`;
    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  getPlaceById(id: number, level: number, path: string) {
    const url = this.serverURL + Constants.place_module + 'place' + `?path=${path}&id=${id}&level=${level}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getPlaceByParent(upperId: number, level: number, path: string) {
    const url = this.serverURL + Constants.place_module + 'place' + `?path=${path}&upperId=${upperId}&level=${level}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  /*	changePlaceStatus(id, level, status) {
      const url = this.serverURL + Constants.place_module + 'place/change_status';
      const body = {
        placeId: id,
        placeLevel: level,
        statusId: status,
      };
      return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(body));
    }*/

  searchPlaces(filters: Filter) {
    const url = this.serverURL + Constants.place_module + 'place/search';

    return this.http.post<RoomlessHttpResponse>(url, filters);
  }

  createAdminPlaces(place: Place) {
    const url = this.serverURL + Constants.place_module + 'place/create';
    // return this.http.post<RoomlessHttpResponse>(url, filter, {headers : this.headers});

    return this.http.post<RoomlessHttpResponse>(url, place);
  }

  saveAdminPlaces(place: Place) {
    const url = this.serverURL + Constants.place_module + 'place/edit';

    return this.http.put<RoomlessHttpResponse>(url, place);
  }

  deleteAdminPlaces(placeId: number) {
    const url = this.serverURL + Constants.place_module + 'place/delete?placeId=' + placeId;

    return this.http.delete<RoomlessHttpResponse>(url);
  }

  searchAdminCities(filter: Filter) {
    const url = this.serverURL + Constants.place_module + 'city/search-for-admin';

    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  saveAdminCities(city: City) {
    const url = this.serverURL + Constants.place_module + 'city/edit';

    return this.http.put<RoomlessHttpResponse>(url, city);
  }

  getCityById(id: number) {
    const url = this.serverURL + Constants.place_module + 'city?cityId=' + id;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  searchAdminInfoRequests(filter: Filter) {
    const url = this.serverURL + Constants.listing_module + 'info-request/search';

    return this.http.post<RoomlessHttpResponse>(url, filter);
  }
  searchAdminMyRequests(filter: Filter) {
    const url = this.serverURL + Constants.listing_module + 'info-request/my_requests';

    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  addInfoRequest(infoRequest: Partial<InfoRequest>) {
    const url = this.serverURL + Constants.admin_module + 'lead';
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(infoRequest));
  }
  editInfoRequest(lead: Partial<InfoRequest>) {
    const url = this.serverURL + Constants.admin_module + 'lead';
    return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(lead));
  }

  uploadSalesLeadsCsv(file: Blob, source: number, status?: TenantFunnelStatus) {
    let url = `${this.serverURL}${Constants.listing_module}info-request/import-csv?type=${source}`;
    if (status) {
      url = url.concat(`&managed=${status}`);
    }
    const body = new FormData();
    body.append('file', file);


    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    GlobalFields.avoidApplicationJson = true;

    return this.http.post<RoomlessHttpResponse>(url, body, { headers: headers });
  }

  uploadSupplyLeadsCsv(file: Blob, source: number, status?: TenantFunnelStatus) {
    let url = this.serverURL + Constants.admin_module + 'lead/landlord/import-csv' + '?type=' + source;
    if (status) {
      url = url.concat(`&managed=${status}`);
    }

    const body = new FormData();
    body.append('file', file);


    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    GlobalFields.avoidApplicationJson = true;

    return this.http.post<RoomlessHttpResponse>(url, body, { headers: headers });
  }



  searchAdminSupplyLeads(filter: Filter) {
    const f = { ...filter };
    if (f.trackingData && f.trackingData.utmSource === "" || f.trackingData.utmSource == undefined) (
      f.trackingData = undefined
    )


    const url = this.serverURL + Constants.admin_module + 'lead/search';
    return this.http.post<RoomlessHttpResponse>(url, f);
  }


  createSupplyLead(lead: SupplyLead) {
    const url = this.serverURL + Constants.admin_module + 'lead';
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(lead));
  }

  getSupplyLeadById(leadId: number) {
    const url = this.serverURL + Constants.admin_module + 'lead?id=' + leadId;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  editSupplyLead(lead: SupplyLead) {
    const url = this.serverURL + Constants.admin_module + 'lead';
    return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(lead));
  }

  deleteSupplyLead(id: number) {
    /*const url = this.serverURL + Constants.user_module + 'user?userId=' + userId;
    return this.http.delete<RoomlessHttpResponse>(url);*/
  }


  searchAdminListings(filter: Filter) {
    const url = this.serverURL + Constants.listing_module + 'listing/search-for-admin';
    return this.http.post<RoomlessHttpResponse>(url, filter);
  }


  searchAdminListingsSales(filter: Filter) {
    const url = this.serverURL + Constants.server_suffix1 + 'sale-listing/search-for-admin';
    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  searchAdminStructure(filter: Filter) {
    const url = this.serverURL + Constants.listing_module + 'structure/search';
    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  publishRequest(listingsIds: number[]) {
    const url = this.serverURL + Constants.listing_module + 'listing/publish_request';

    const body = { ids: listingsIds };

    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  publishRequestSales(listingsIds: number[]) {
    const url = this.serverURL + Constants.server_suffix1 + 'sale-listing/publish_request';

    const body = { ids: listingsIds };

    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  getMyVisits(settings?: any) {
    const url = this.serverURL + Constants.server_suffix1 + 'visit/my_visits';

    let body = {};
    if (settings)
      body = {
        month: settings.month,
        year: settings.year,
        slotStatuses: settings.slotStatuses,
        sortField: settings.sortField,
        sortOrder: settings.sortOrder,
        pageSize: settings.pageSize,
        pageNumber: settings.pageNumber,
        requesterId: GlobalFields.isAdmin() ? GlobalFields.currentUserInfo.id : undefined,
        body: GlobalFields.currentLanguage
      };

    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  getVisitBySlotId(slotId: any) {
    const url = this.serverURL + Constants.server_suffix1 + 'visit/slot/?slotId=' + slotId + '&lang=' + GlobalFields.currentLanguage;

    return this.http.get<RoomlessHttpResponse>(url);
  }


  createVisit(visit: Visit) {
    const url = this.serverURL + Constants.server_suffix1 + 'visit/create';

    return this.http.post<RoomlessHttpResponse>(url, visit);
  }

  //Prenota una disponibilità
  bookVisit(visitId: number, slotType: number, listingId: number) {
    const url = this.serverURL + Constants.server_suffix1 + 'visit/book';

    const body = {
      visitId: visitId,
      slotType: slotType,
      listingId: listingId,

      credits: slotType === Constants.VISIT_TYPE_LOCO ? 1 : 0,
    };

    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  //Richiede una visita
  requestVisit(slotType: number, listingId: number, visit: Visit) {
    const url = this.serverURL + Constants.server_suffix1 + 'visit/request';

    let trackingData: TrackingData = GlobalFields.currentTrackingData ? GlobalFields.currentTrackingData : this.getTracking();

    const body = {
      listingId: listingId,
      startTimestamp: visit.startTimestamp,
      endTimestamp: visit.endTimestamp,
      entryDate: visit.entryDate,
      stayMonths: visit.stayMonths,
      customerNote: visit.customerNote,
      type: slotType,
      trackingData: trackingData
    };

    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  // usata per confermare, rifiutare, approvare, inviare alternative ecc
  changeStatusSlot(
    slotId: number,
    status?: number,
    msg?: string,
    proposes?: Visit[],
    proposalSlotId?: number
  ) {
    const url = this.serverURL + Constants.server_suffix1 + 'visit/confirm';

    const body = {
      status: status,
      slotId: slotId,
      message: msg,
      proposes: proposes,
      proposalSlotId: proposalSlotId,
    };

    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  // usata per eliminare una visita con slot liberi
  changeStatusVisit(visitId: number, status?: number) {
    const url = this.serverURL + Constants.server_suffix1 + 'visit/change_status';

    const body = {
      statusId: status,
      visitId: visitId,
    };

    return this.http.put<RoomlessHttpResponse>(url, body);
  }

  getRentById(rentId: any) {
    const url = this.serverURL + Constants.rent_module + 'rent?rentRequestId=' + rentId;

    return this.http.get<RoomlessHttpResponse>(url);
  }

  markDepositAsPaid(rentId: number) {
    const url = this.serverURL + Constants.rent_module + 'rent/mark-deposit-paid';
    const body = {
      id: rentId
    }
    return this.http.put<RoomlessHttpResponse>(url, body);
  }

  getAdditionalRentCharges() {
    const url = `${this.serverURL}${Constants.rent_module}rent/additional-charges`
    return this.http.get<RoomlessHttpResponse>(url);
  }

  searchRental(filter: Filter) {
    const url = this.serverURL + Constants.rent_module + 'rent/search-for-admin';

    return this.http.post<RoomlessHttpResponse>(url, filter);
  }
  searchLeads(filter: Filter) {
    const url = this.serverURL + Constants.admin_module + 'lead/search';
    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  calculatePrice(filter: any) {
    const url = this.serverURL + Constants.rent_module + 'rent/calculate-price';

    return this.httpIgnoreInterceptor.post<RoomlessHttpResponse>(url, filter);
  }

  updateRentNote(id: number, note: string) {
    const url = this.serverURL + Constants.rent_module + 'rent/update-note';
    const body = {
      id: id,
      note: note
    };
    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  updateRentAdministrationNote(id: number, administrationNotes: string) {
    const url = this.serverURL + Constants.rent_module + 'rent/update-note';
    const body = {
      id: id,
      administrationNotes: administrationNotes
    };
    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  changeStatusRentRequest(rentId: number, status?: number, message?: string, whenFree?: string) {
    const url = this.serverURL + Constants.rent_module + 'rent/change_status';
    const body = {
      rentRequestId: rentId,
      status: status,
      message: message,
      whenFree: whenFree //Used only in case of a rejection
    };

    return this.http.put<RoomlessHttpResponse>(url, body);
  }

  calculateRentFee(rentId: number, checkIn: string, checkout: string, firstMonth: number) {
    const url = this.serverURL + Constants.rent_module + 'rent/calculate-fee';
    const body = {
      rentRequestId: rentId,
      checkin: checkIn,
      checkout: checkout,
      firstMonth: firstMonth
    };

    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  savePaymentInfo(paymentInfo?: any, paymentType?: number, email?: string, accountOwner?: string, accountIBAN?: string, accountSwiftCode?: string) {
    const url = this.serverURL + Constants.user_module + 'user/payment_info';
    let body;
    if (paymentInfo)
      body = {
        'paymentInfo': paymentInfo
      };
    else
      body = {
        'paymentInfo': [
          {
            'type': paymentType,
            'email': email,
            'accountOwner': accountOwner,
            'accountIBAN': accountIBAN,
            'accountSwiftCode': accountSwiftCode
          }
        ]
      };
    return this.http.post<RoomlessHttpResponse>(url, body);
  }

  downloadCsvLeadGen() {
    const url = this.serverURL + Constants.user_module + 'lead/csv';
    const requestOptions: Object = {
      responseType: 'text'
    };

    return this.httpIgnoreInterceptor.get<string>(url, requestOptions);
  }

  editRentalRequest(rent: Rent) {
    const url = this.serverURL + Constants.rent_module + 'rent/edit';
    return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(rent));
  }
  setRequestManaged(request: any) {
    const url = this.serverURL + Constants.rent_module + 'rent/set-managed';
    return this.http.post<RoomlessHttpResponse>(url, request);
  }

  forceIBLeadToRequested(rentRequestId: number) {
    const url = this.serverURL + Constants.rent_module + 'rent/force-to-requested';
    const payload: {
      rentRequestId: number
    } = {
      rentRequestId: rentRequestId
    }
    return this.http.post<RoomlessHttpResponse>(url, payload);
  }

  /* Coupons */
  getCoupons(filters: any) {
    const url = `${this.serverURL}${Constants.admin_module}discount-code/search`;
    const payload = filters;
    return this.http.post<any>(url, payload);
  }

  deleteCoupon(id: number) {
    const url = `${this.serverURL}${Constants.admin_module}discount-code?discountCodeId=${id}`;
    //const url = `${this.serverURL}${Constants.admin_module}discountCode/id=${id}`;
    return this.http.delete<any>(url);
  }

  changeCouponStatus(id: number, isActive: boolean) {
    const url = `${this.serverURL}${Constants.admin_module}discount-code`;
    const payload = {
      id: id,
      isActive: isActive
    };
    return this.http.put<any>(url, payload);
  }

  createCoupon(coupon: Coupon) {
    const url = `${this.serverURL}${Constants.admin_module}discount-code`;
    const payload = coupon;
    return this.http.post<any>(url, payload);
  }

  editCoupon(coupon: Coupon) {
    const url = `${this.serverURL}${Constants.admin_module}discount-code`;
    const payload = coupon;
    return this.http.put<any>(url, payload);
  }







  /******** Sale Listing ********/

  createSaleListing(listing: ListingSales) {
    const url = this.serverURL + Constants.server_suffix1 + 'sale-listing/create';
    return this.http.post<RoomlessHttpResponse>(url, JSON.stringify(listing));
  }


  /******* Admin Panel ********/
  searchAdminPartners(filter: Filter) {
    const url = this.serverURL + Constants.admin_module + 'roomless-partner/search';
    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  editAdminPartner(partner: AdminPartner) {
    const url = this.serverURL + Constants.admin_module + 'roomless-partner/edit';
    return this.http.put<RoomlessHttpResponse>(url, partner);
  }

  getAdminSettings() {
    const url = this.serverURL + Constants.admin_module + 'setting';
    return this.http.get<RoomlessHttpResponse>(url);
  }

  editAdminSettings(key: string, value: any) {
    const url = this.serverURL + Constants.admin_module + 'setting';
    const body = {};
    body[key] = value;
    return this.http.put<RoomlessHttpResponse>(url, body);
  }

  getEstatePortalsNumbers() {
    const url = this.serverURL + Constants.user_module + 'voice-mail/get-all';
    return this.http.get<RoomlessHttpResponse>(url);
  }

  editEstatePortalsNumbers(id: number, phones: string[]) {
    const url = this.serverURL + Constants.user_module + 'voice-mail/edit';
    const body = {
      id: id,
      phones: phones
    };
    return this.http.put<RoomlessHttpResponse>(url, body);
  }

  getAgents(agentId: number) {
    const url = this.serverURL + Constants.admin_module + `agent?agentId=${agentId}`;
    return this.http.get<RoomlessHttpResponse>(url);
  }
  getAgentStatistics(agentId: number) {
    const url = this.serverURL + Constants.admin_module + `agent/statistic?agentId=${agentId}`;
    return this.http.get<RoomlessHttpResponse>(url);
  }

  searchAgents(filter: AgentsFilters) {
    const url = this.serverURL + Constants.admin_module + 'agent/search';
    //return this.http.post<RoomlessHttpResponse>(url, filter).pipe(delay(5000));
    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  createAgent(agent: AdminAgents) {
    const url = this.serverURL + Constants.admin_module + 'agent/create';
    return this.http.post<RoomlessHttpResponse>(url, agent);
  }

  editAgent(agent: AdminAgents) {
    const url = this.serverURL + Constants.admin_module + 'agent/edit';
    return this.http.put<RoomlessHttpResponse>(url, agent);
  }

  deleteAgent(agentId: number) {
    const url = this.serverURL + Constants.admin_module + 'agent?agentId=' + agentId;
    return this.http.delete<RoomlessHttpResponse>(url);
  }

  /* STRIPE Endpoints */

  /* Payments transfer */

  createTransfer(transfer: Transfer) {
    const url = `${this.serverURL}${Constants.transaction_module}transfer/create`;
    return this.http.post<RoomlessHttpResponse>(url, transfer);
  }
  sendTransfer(transferId: number) {
    const url = `${this.serverURL}${Constants.transaction_module}transfer/pay`;
    return this.http.post<RoomlessHttpResponse>(url, { id: transferId });
  }

  /* Payment accounts */
  getRefundRequest(id: number, rentRequestId: number) {
    const url = `${this.serverURL}${Constants.transaction_module}refund/request?id=${id}&rentRequestId=${rentRequestId}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  editRefundRequest(refundRequest: RefundRequest) {
    const url = `${this.serverURL}${Constants.transaction_module}refund/request`;
    return this.http.put<RoomlessHttpResponse>(url, refundRequest);
  }

  payRefundRequest(id: number) {
    const url = `${this.serverURL}${Constants.transaction_module}refund/pay`;
    return this.http.put<RoomlessHttpResponse>(url, { id: id });
  }

  deleteRefundRequest(id: number, rentRequestId: number) {
    const url = `${this.serverURL}${Constants.transaction_module}refund/request?id=${id}&rentRequestId=${rentRequestId}`;
    return this.http.delete<RoomlessHttpResponse>(url);
  }

  createRefundRequest(refundRequest: RefundRequest) {
    const url = `${this.serverURL}${Constants.transaction_module}refund/request`;
    return this.http.post<RoomlessHttpResponse>(url, refundRequest);
  }

  createPaymentAccount(account: PaymentAccount) {
    const url = `${this.serverURL}${Constants.transaction_module}payments/account/create`;
    return this.http.post<RoomlessHttpResponse>(url, account);
  }

  getUserPaymentAccount(userId: number) {
    const url = `${this.serverURL}${Constants.transaction_module}payments/search?userId=${userId}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  getPaymentAccount(accountId: number) {
    const url = `${this.serverURL}${Constants.transaction_module}payments/account?id=${accountId}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  editPaymentAccount(account: PaymentAccount) {
    const url = `${this.serverURL}${Constants.transaction_module}payments/account/edit`;
    return this.http.put<RoomlessHttpResponse>(url, account);
  }

  deletePaymentAccount(accountId: number) {
    const url = this.serverURL + Constants.transaction_module + `payments/account/delete?id=${accountId}`;
    return this.http.delete<RoomlessHttpResponse>(url);
  }

  deletePersonsDocuments(documentId: number) {
    const url = this.serverURL + Constants.transaction_module + `payments/person/document?id=${documentId}`;
    return this.http.delete<RoomlessHttpResponse>(url);
  }

  /* Payment methods */
  getPaymentMethod(methodId: number, userId: number) {
    const url = `${this.serverURL}${Constants.transaction_module}payments/details?id=${methodId}&userId=${userId}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  createPaymentMethod(method: PaymentMethods) {
    const url = `${this.serverURL}${Constants.transaction_module}payments/details/create`;
    return this.http.post<RoomlessHttpResponse>(url, method);
  }

  editPaymentMethod(method: PaymentMethods) {
    const url = `${this.serverURL}${Constants.transaction_module}payments/details/edit`;
    return this.http.put<RoomlessHttpResponse>(url, method);
  }

  deletePaymentMethod(methodId: number) {
    const url = this.serverURL + Constants.transaction_module + `payments/details/delete?id=${methodId}`;
    return this.http.delete<RoomlessHttpResponse>(url);
  }

  markLandlordFeeAsPaid(rentRequestId: number) {
    const url = `${this.serverURL}${Constants.rent_module}rent/mark-landlord-fee-paid`;
    return this.http.put<RoomlessHttpResponse>(url, { id: rentRequestId });
  }

  markBankTransferAsSent(rentRequestId: number) {
    const url = `${this.serverURL}${Constants.rent_module}rent/mark-bank-transfer-sent`;
    return this.http.put<RoomlessHttpResponse>(url, { id: rentRequestId });
  }

  markAllLandlordPaid(rentRequestId: number) {
    const url = `${this.serverURL}${Constants.rent_module}rent/mark-all-landlord-paid `;
    return this.http.put<RoomlessHttpResponse>(url, { id: rentRequestId });
  }

  markAllTenantPaid(rentRequestId: number) {
    const url = `${this.serverURL}${Constants.rent_module}rent/mark-all-tenant-paid `;
    return this.http.put<RoomlessHttpResponse>(url, { id: rentRequestId });
  }

  // Locare endpoints
  locareDataById(rentRequestId?: number, id?: number, isPrint?: boolean) {
    let request = `rentRequestId=${rentRequestId}`
    if (!rentRequestId && id) {
      request = `id=${id}`
    }

    const print = isPrint ? '/print' : '';

    const url = `${this.serverURL}${Constants.rent_module}locare?${request}${print}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  // Locare endpoints
  printContractById(rentRequestId: number) {
    const body = { rentRequestId: rentRequestId, }
    const url = `${this.serverURL}${Constants.rent_module}locare/print`;

    GlobalFields.avoidApplicationJson = true;

    const token = AuthInterceptor.getToken();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'withCredentials': 'true',
      'Authorization': `Bearer ${token}`
    })
    return this.httpIgnoreInterceptor.post<any>(url, body, { headers: headers, responseType: 'blob' as 'json' });
  }

  printContractDraftById(rentRequestId: number) {
    const body = { rentRequestId: rentRequestId, }
    const url = `${this.serverURL}${Constants.rent_module}locare/print-draft`;

    GlobalFields.avoidApplicationJson = true;

    const token = AuthInterceptor.getToken();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'withCredentials': 'true',
      'Authorization': `Bearer ${token}`
    })
    return this.httpIgnoreInterceptor.post<any>(url, body, { headers: headers, responseType: 'blob' as 'json' });
  }

  printContractRliById(rentRequestId: number) {
    const body = { rentRequestId: rentRequestId, }
    const url = `${this.serverURL}${Constants.rent_module}locare/rli`;

    GlobalFields.avoidApplicationJson = true;

    const token = AuthInterceptor.getToken();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'withCredentials': 'true',
      'Authorization': `Bearer ${token}`
    })
    return this.httpIgnoreInterceptor.post<any>(url, body, { headers: headers, responseType: 'blob' as 'json' });
  }
  printContractReceiptById(rentRequestId: number) {
    const body = { rentRequestId: rentRequestId, }
    const url = `${this.serverURL}${Constants.rent_module}locare/ricevuta`;

    GlobalFields.avoidApplicationJson = true;

    const token = AuthInterceptor.getToken();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'withCredentials': 'true',
      'Authorization': `Bearer ${token}`
    })
    return this.httpIgnoreInterceptor.post<any>(url, body, { headers: headers, responseType: 'blob' as 'json' });
  }

  ufficiTerritoriali() {
    const url = `assets/files/uffici_territoriali_locare.json`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  editLocareContractDraft(locareData: LocareContractData) {
    const url = `${this.serverURL}${Constants.rent_module}locare`;
    return this.http.put<RoomlessHttpResponse>(url, locareData);
  }

  createLocareContractDraft(locareData: LocareContractData) {
    const url = `${this.serverURL}${Constants.rent_module}locare`;
    return this.http.post<RoomlessHttpResponse>(url, locareData);
  }

  createLocareContract(locareData: LocareContractData) {
    const url = `${this.serverURL}${Constants.rent_module}locare/register`;
    return this.http.post<RoomlessHttpResponse>(url, locareData);
  }

  searchLocareContract(locareFilters: LocareContractFilters) {
    const url = `${this.serverURL}${Constants.rent_module}locare/search`;
    return this.http.post<RoomlessHttpResponse>(url, locareFilters);
  }

  searchLocareRents(filter: Filter) {
    const url = `${this.serverURL}${Constants.rent_module}locare/search-for-admin`;
    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  changeLocareStatus(filter: LocareChangeStatusDto) {
    const url = `${this.serverURL}${Constants.rent_module}locare/change-status-locare`;
    return this.http.post<RoomlessHttpResponse>(url, filter);
  }

  

  propertyContractTypes(countryId: number, lang: string) {
    const url = `${this.serverURL}${Constants.listing_module}listing/contract-types?countryId=${countryId}&lang=${lang}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  salesDashboardInsights(insightsFilters: Insights.SalesRequest) {
    const query = `from=${insightsFilters.from}&to=${insightsFilters.to}&currency=${insightsFilters.currency ?? null}&countryId=${insightsFilters.countryId ?? null}`;

    const url = `${this.serverURL}${Constants.rent_module}dashboard/global?${query}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }
  supplyDashboardInsights(insightsFilters: Insights.SupplyRequest) {
    const query = `from=${insightsFilters.from}&to=${insightsFilters.to}&userId=${insightsFilters.userId ?? null}&countryId=${insightsFilters.countryId ?? null}`;

    const url = `${this.serverURL}${Constants.listing_module}dashboard/global?${query}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  salesAgentsDashboardInsights(agentsFilters: Insights.AgentFilters) {
    const query = `from=${agentsFilters.from}&to=${agentsFilters.to}&currency=${agentsFilters.currency ?? null}`;

    const url = `${this.serverURL}${Constants.rent_module}dashboard/agents?${query}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  supplyAgentsDashboardInsights(agentsFilters: Insights.SupplyAgentFilters) {
    const query = `from=${agentsFilters.from}&to=${agentsFilters.to}`;
    const url = `${this.serverURL}${Constants.listing_module}dashboard/agents?${query}`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: this.headers });
  }

  editUserLead(user: any) {
    const url = this.serverURL + Constants.admin_module + 'lead';
    return this.http.put<RoomlessHttpResponse>(url, user);
  }
  editUserNotes(notes: any) {
    const url = this.serverURL + Constants.user_module + 'user/note';
    return this.http.put<RoomlessHttpResponse>(url, notes);
  }

  createUserLead(user: any) {
    const url = this.serverURL + Constants.admin_module + 'lead';
    return this.http.post<RoomlessHttpResponse>(url, user);
  }

  searchVisitsById(visit: any) {

    const url = this.serverURL + Constants.visits_module + 'visit/search';
    return this.http.post<RoomlessHttpResponse>(url, visit);
  }
  createVisitById(visit: any) {

    const url = this.serverURL + Constants.visits_module + 'visit';
    return this.http.post<RoomlessHttpResponse>(url, visit);
  }
  editVisitById(visit: any) {

    const url = this.serverURL + Constants.visits_module + 'visit';
    return this.http.put<RoomlessHttpResponse>(url, visit);
  }
  deleteVisitById(id: number) {

    const url = this.serverURL + Constants.visits_module + 'visit?id=' + id;
    return this.http.delete<RoomlessHttpResponse>(url);
  }
  searchFollowUpsById(followUp: any) {

    const url = this.serverURL + Constants.user_module + 'followup/search';
    return this.http.post<RoomlessHttpResponse>(url, followUp);
  }
  createFollowUpById(followUp: any) {

    const url = this.serverURL + Constants.user_module + 'followup';
    return this.http.post<RoomlessHttpResponse>(url, followUp);
  }
  editFollowUpById(followUp: any) {

    const url = this.serverURL + Constants.user_module + 'followup';
    return this.http.put<RoomlessHttpResponse>(url, followUp);
  }
  deleteFollowUpById(id: number) {

    const url = this.serverURL + Constants.user_module + 'followup?id=' + id;
    return this.http.delete<RoomlessHttpResponse>(url);
  }

  setActionsManaged(data: { userId: number, actions: UserActionTypeEnum[] }) {
    const url = this.serverURL + Constants.user_module + 'user/set-actions-managed ';
    return this.http.put<RoomlessHttpResponse>(url, data);
  }

  // TODO: fix payload type --> create type ad hoc
  getUserBadges(filters: Filter) {
    const url = this.serverURL + Constants.user_module + 'user/badge';
    return this.http.post<RoomlessHttpResponse>(url, filters);
  }


  companyTargets(revalidateCache?: boolean) {
    let headers;
    if (revalidateCache) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json', "Cache-Control": "public, no-cache" });
    }
    const url = `${this.serverURL}${Constants.rent_module}dashboard/targets`;
    return this.http.get<RoomlessHttpResponse>(url, { headers: headers ? headers : this.headers });
  }

  setCompanyTargets(target: Insights.Target[]) {
    const url = `${this.serverURL}${Constants.rent_module}dashboard/targets`;
    return this.http.put<RoomlessHttpResponse>(url, target);
  }

  sponsoredListing(id: number, sponsored: boolean) {
    const url = this.serverURL + Constants.listing_module + 'listing/worthy';
    const body = {
      id: id,
      worthy: sponsored,
    };
    return this.http.put<RoomlessHttpResponse>(url, JSON.stringify(body));
  }

  getMailsList(filters) {
    const url = this.serverURL + Constants.admin_module + 'notification/search';

    filters.type = 1; //Email
    return this.http.post<RoomlessHttpResponse>(url, filters);
  }
  sendNotification(filters) {
    const url = this.serverURL + Constants.admin_module + 'notification/send';
    return this.http.post<RoomlessHttpResponse>(url, filters);
  }

  getWhatsappList(filters) {
    const url = this.serverURL + Constants.admin_module + 'notification/search';
    filters.type = 2; //Whatsapp
    return this.http.post<RoomlessHttpResponse>(url, filters);
  }

  /************* UTILITIES **************/

  openSidebar() {
    GlobalFields.sideMenuOpened = true;
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  closeSidebar() {
    GlobalFields.sideMenuOpened = false;
    this.menu.enable(true, 'first');
    this.menu.close('first');
  }

  composeSearchUrlBaseOnFilters(replaceUrl?: boolean, changeType?: number) {
    if (changeType != undefined) //Usato per cambiare il tipo di listing in pagina search
      GlobalFields.selectedSearchListingType = changeType;
    const path = GlobalFields.selectedSearchListingType == Constants.SALE_LISTING_TYPE ? '/' + PathConstants.searchPageSale : '/' + PathConstants.searchPageRent;
    let placePath = '';
    if (GlobalFields.placeSelected) //Yes place, starts with '/'
      placePath = GlobalFields.placeSelected.landingUrl + '/';
    else
      placePath = '/' //No place

    let queryParams = '';

    if (GlobalFields.searchFilter) {
      queryParams = '?';
      // Tolgo il paging per adesso perchè abbiamo messo l'infinite scroll
      /*  if (GlobalFields.searchFilter.pageNumber)
            queryParams = queryParams + "p=" + GlobalFields.searchFilter.pageNumber + "&";
            */
      if (GlobalFields.searchFilter.address) {
        queryParams = queryParams + 'addr=' + GlobalFields.searchFilter.address + '&';
      }
      if (GlobalFields.searchFilter.radius) {
        queryParams = queryParams + 'r=' + GlobalFields.searchFilter.radius + '&';
      } else if (GlobalFields.placeSelected) {
        if (GlobalFields.placeSelected.level == 1)
          GlobalFields.searchFilter.radius = Constants.defaultRadiusSearch
        else if (GlobalFields.placeSelected.level == 2)
          GlobalFields.searchFilter.radius = Constants.defaultRadiusSearchDistrict
        else if (GlobalFields.placeSelected.level == 3)
          GlobalFields.searchFilter.radius = Constants.defaultRadiusSearchPOI
        queryParams = queryParams + 'r=' + GlobalFields.searchFilter.radius + '&';
      }
      if (GlobalFields.searchFilter.listingTypes && GlobalFields.searchFilter.listingTypes.length > 0) {
        queryParams = queryParams + 'types=' + GlobalFields.searchFilter.listingTypes.toString() + '&';
        if (GlobalFields.searchFilter.numBedrooms && GlobalFields.searchFilter.listingTypes.includes(Constants.ESTATE_LISTING_TYPE.id)) {
          queryParams = queryParams + 'n_bedr=' + GlobalFields.searchFilter.numBedrooms.toString() + '&';
        }
        if (GlobalFields.searchFilter.otherFreeRooms && GlobalFields.searchFilter.listingTypes.includes(Constants.ACCOMODATION_LISTING_TYPE.id)) {
          queryParams = queryParams + 'other_free_bedr=' + GlobalFields.searchFilter.otherFreeRooms.toString() + '&';
        }
        if (GlobalFields.searchFilter.maxOtherRooms && GlobalFields.searchFilter.listingTypes.includes(Constants.ACCOMODATION_LISTING_TYPE.id)) {
          queryParams = queryParams + 'max_other_rooms=' + GlobalFields.searchFilter.maxOtherRooms + '&';
        }
      }
      if (GlobalFields.searchFilter.numBathrooms) {
        queryParams = queryParams + 'n_baths=' + GlobalFields.searchFilter.numBathrooms.toString() + '&';
      }
      if (GlobalFields.searchFilter.fromPrice !== undefined) {
        queryParams = queryParams + 'from_p=' + GlobalFields.searchFilter.fromPrice + '&';
      }
      if (GlobalFields.searchFilter.toPrice) {
        queryParams = queryParams + 'to_p=' + GlobalFields.searchFilter.toPrice + '&';
      }
      if (GlobalFields.searchFilter.checkInDate || GlobalFields.searchFilter.whenFreeWithin) {
        let date = undefined;
        let date_type = undefined;
        if (GlobalFields.searchFilter.checkInDate) {
          date = GlobalFields.searchFilter.checkInDate;
          date_type = 1;
        } else if (GlobalFields.searchFilter.whenFreeWithin) {
          date = GlobalFields.searchFilter.whenFreeWithin;
          date_type = 2;
        }
        if (date && date_type) {
          queryParams = queryParams + 'd=' + date + '&';
          queryParams = queryParams + 'dt=' + date_type + '&';
        }
      }
      if (GlobalFields.searchFilter.numMonthsRental) {
        queryParams = queryParams + 'num_months=' + GlobalFields.searchFilter.numMonthsRental + '&';
      }

      if (GlobalFields.searchFilter.mq) {
        queryParams = queryParams + 'mq=' + GlobalFields.searchFilter.mq + '&';
      }
      if (GlobalFields.searchFilter.services && GlobalFields.searchFilter.services.length > 0) {
        queryParams = queryParams + 'ser=' + GlobalFields.searchFilter.services.toString() + '&';
      }
      if (GlobalFields.searchFilter.sortField) {
        queryParams = queryParams + 'sort=' + GlobalFields.searchFilter.sortField + '&';
      }
      if (GlobalFields.searchFilter.sortOrder) {
        queryParams = queryParams + 'order=' + GlobalFields.searchFilter.sortOrder + '&';
      }
    }
    if (replaceUrl) {
      const haveToReload = !this.router.url.includes(PathConstants.searchPageRent) || changeType != undefined;

      const lan = GlobalFields.currentLanguage ? '/' + GlobalFields.currentLanguage : '';

      if (haveToReload) {
        this.navCrl.navigateRoot(lan + path + placePath + queryParams);


        /* if(changeType)
           if (GlobalFields.isPlatformBrowser())
             setTimeout(() => {
               GlobalFields.window.location.reload()
             }, 100);
 */

      } else {
        this.location.replaceState(lan + path + placePath + queryParams);
      }
    } else {
      return path + placePath + queryParams;
    }
  }

  getPlaceFromUrl() {
    const url = this.router.url;
    const paths = this.router.url.split('/');
    let place;
    if (paths.length > 3 && !paths[3].includes('?')) {
      place = paths[3];
    } else if (paths.length > 3) {
      place = paths[3].split('?')[0];
    }
    return place;
  }

  getUserTypeFromUrl() {
    const url = this.router.url;
    const paths = this.router.url.split('/');
    let place;
    if (paths.length > 3 && !paths[3].includes('?')) {
      place = paths[3];
    } else if (paths.length > 3) {
      place = paths[3].split('?')[0];
    }
    return place;
  }


  getTrackingQueryParam() {
    const tracking: TrackingData = {}

    //Its a loop for each query param
    this.route.queryParams.subscribe((params) => {
      // @ts-ignore TODO fix TS error
      if (params != {}) {
        if (params['utm_source'] != 'email' ||
          //Skipping transitional emails
          (params['utm_source'] == 'email' && params['utm_medium'] != 'notif_email')
        ) {
          tracking.utmSource = params['utm_source']
          tracking.utmMedium = params['utm_medium']
          tracking.utmCampaign = params['utm_campaign']
        }
        tracking.gclid = params['gclid']
        if (params['nestpick_transaction_id'])
          tracking.externalTransactionId = params['nestpick_transaction_id']
        else if (params['external_transaction_id'])
          tracking.externalTransactionId = params['external_transaction_id']

        //If there are query params getting them
        // @ts-ignore TODO fix TS error
        if (tracking && tracking != {} && (
          tracking.utmSource != undefined ||
          tracking.utmMedium != undefined ||
          tracking.utmCampaign != undefined ||
          tracking.gclid != undefined ||
          tracking.externalTransactionId != undefined
        )
        ) {
          tracking.createdAt = new Date().getTime()
          GlobalFields.currentTrackingData = tracking
          this.storeTracking(GlobalFields.currentTrackingData)
        }
        //Otherwise trying from the storage
        else {
          GlobalFields.currentTrackingData = this.getTracking();
        }

      }
    })
  }

  storeTracking(trackingData: TrackingData) {
    if (GlobalFields.isPlatformBrowser()) {
      this.localStorage.setItem('trackingRoomless', JSON.stringify(trackingData));
    }
  }

  getTracking(): TrackingData | undefined {

    let res: TrackingData;

    if (GlobalFields.isPlatformBrowser()) {
      const trackingRoomless = this.localStorage.getItem('trackingRoomless') == 'undefined' ? '{}' : this.localStorage.getItem('trackingRoomless');
      res = JSON.parse(trackingRoomless) as TrackingData;
      if (res) {
        //Checking if the tracking stored are older then 3 Days
        const createdAtDate = new Date(res.createdAt)
        const nowDate = new Date()
        if (((nowDate.getTime() - createdAtDate.getTime()) / (1000 * 3600 * 24)) > 3) { // More then 3 days
          res = undefined;
          this.storeTracking(undefined)
        } else if (res && res.utmCampaign == 'email' && res.utmMedium == 'notif_email') {
          res.utmCampaign = undefined
          res.utmMedium = undefined
          res.utmSource = undefined
        }
      }
    }

    return res
  }


  setTransparentBackgroundStatusBar() {
    /*if (this.platform.is('android')) {
      this.statusBar.overlaysWebView(false);
    } else {
      this.statusBar.overlaysWebView(true);
      this.statusBar.styleLightContent();
    }*/
  }

  setWhiteBlackBackgroundStatusBar() {
    /*if (this.platform.is('android'))
      this.setTransparentBackgroundStatusBar();
    else {
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByName('white');
      this.statusBar.styleDefault();
    }*/
  }

  setPrimaryColorBackgroundStatusBar() {
    /*if (this.platform.is('android'))
      this.setTransparentBackgroundStatusBar();
    else {
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#eb626c');
      this.statusBar.styleLightContent();
    }*/
  }

  clearAllCache() {
    // this.cache.clearAll();
  }

  fetchAgents(type?: number): Promise<any> {
    return new Promise((resolve) => {
      this.searchAgents({
        sortField: 'CREATED_AT',
        sortOrder: 'DESC',
        pageSize: 50,
        pageNumber: 0,
        type: type
      }).subscribe(resolve);
    });
  }

  getLastSevenDaysSalesLeadsCount(listingIds: number[]){
    const url = `${this.serverURL}${Constants.listing_module}listing/num-lead`;
    const payload = {
      listingIds: listingIds
    }
    return this.http.post<LeadsCount[]>(url, payload);
  }

  makePartnerIB(rentId: number){
    const url = `${this.serverURL}${Constants.transaction_module}/partner-instant-booking`;
    const payload = {
      id: rentId
    }
    return this.http.post<RoomlessHttpResponse>(url, payload);
  }

  deleteDocument(documentId: number) {
    const url = `${this.serverURL}${Constants.user_module}user/document`;
    const body = { id: documentId };
    return this.http.delete<RoomlessHttpResponse>(url, { headers: this.headers, body: body });
  }

}
