import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFields } from '../../GlobalFields';
import { Constants } from '../../Constants';
import { ModalLogin } from './modalLogin/modalLogin';
import { AuthenticationService } from '../../services/AuthenticationService';
import { AuthInfo } from '../../entities/RoomlessHttpResponse';
import { Profile } from '../../entities/profile';
import { NgForm } from '@angular/forms';
import { Service } from '../../services/Service';
import { PathConstants } from 'src/app/PathConstants';
import { Router } from '@angular/router';
import { AdminTypeEnum } from 'src/app/entities/permissions/permissions';

@Component({
  selector: 'loginComponent',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss', '../../utilities/header/header.component.scss'],
})
export class LoginComponent implements OnInit {
  GlobalFields = GlobalFields;
  Constants = Constants;
  @ViewChild('formLogin', { static: false }) formLogin: NgForm;
  @ViewChild('usernameField', { static: false }) usernameField: ElementRef;
  @ViewChild('formSignUp', { static: false }) formSignUp: NgForm;

  @Output() loginComplete = new EventEmitter<boolean>();
  @Input() isAComponent: boolean;
  @Input() hideTexts: boolean;
  @Input() borderless: boolean;
  @Input() isAOwner?: boolean;


  model: any = {};
  errorMgs = '';
  username: string;
  password: string;
  newProfile: Profile;
  viewMode = 1;

  countries: any[];

  /* Phone validation to avoid "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked". https://angular.io/errors/NG0100 */
  detectedInvalidPhone = false;
  showPrefixError = false;

  constructor(
    private service: Service,
    private authService: AuthenticationService,
    public modalCtrl: ModalController,
    public translate: TranslateService,
    public router: Router
  ) {
    GlobalFields.showMenu = false;
  }

  ngOnInit() {

    this.newProfile = new Profile();
    if (GlobalFields.anonymousUserType === Constants.OWNER_ID) {
      this.newProfile.userTypeId = Constants.OWNER_ID;
    } else {
      this.newProfile.userTypeId = Constants.TENANT_ID;
    }

    /* if(GlobalFields.countries && GlobalFields.countries.length>0)
       this.initCountries();
     else
       this.service.getCountryList().subscribe(
         (res) => {
           if (res.success) {
             GlobalFields.countries = res.data;
             this.initCountries();
           } else {
             console.log(res.errorMessage);
           }
         },
         (err) => {
           console.log(err.message);
         }
       );*/

  }

  initCountries() {
    this.countries = []
    GlobalFields.countries.forEach((el) => {
      this.countries.push({ label: el.name + ' (' + el.phonePrefix + ')', value: el.id, icon: './assets/imgs/flags/' + el.code.toLowerCase() + '.png' });
    });
  }

  keyDownFunction(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  async openLoginModal() {
    const loginModal = await this.modalCtrl.create({
      component: ModalLogin,
    });
    return await loginModal.present();
  }

  login() {
    this.GlobalFields.loadingSoft = true;
    if (this.formLogin.form.valid) {
      this.authService.login(this.model.username.trim(), this.model.password.trim()).subscribe(
        (res) => {
          if (res.success) {
            this.GlobalFields.loadingSoft = false;
            this.authService.storeToken(res.data as AuthInfo);
            this.service.getUserInfo().subscribe(result => {
              if (result.success) {
                GlobalFields.currentUserInfo = result.data;
                this.loginComplete.emit(true);
                this.authService.emitIsLoggedIn(true);

                if (result.data.adminTypeId === AdminTypeEnum.LOCARE){
                  //this.router.navigate(['/', PathConstants.salesDashboard]);
                  this.router.navigate(["/sales/locare"]);
                }
              }
            });


            // this.loginComplete.emit(true);
            // this.authService.emitIsLoggedIn(true);
            // console.log(GlobalFields.currentUserInfo)
            // console.log(res)
            // if (GlobalFields.isLocareUser()){
            //   this.router.navigate(['/', PathConstants.salesDashboard]);
            //   // this.router.navigate(["/sales/locare"]);
            // }
          } else {
            this.errorMgs = !this.errorMgs.includes('LOGIN_REGISTRATION_ERRORS') ? this.errorMgs : "There is an error, please contact the support."
            this.GlobalFields.loadingSoft = false;
          }
        },
        (err) => {
          console.error(err)
          if (err.error && err.error.errorCode) {
            this.errorMgs = this.translate.instant('LOGIN_REGISTRATION_ERRORS.' + err.error.errorCode)
            this.errorMgs = !this.errorMgs.includes('LOGIN_REGISTRATION_ERRORS') ? this.errorMgs : "There is an error, please contact the support."
            console.error(this.errorMgs)
          } else
            this.errorMgs = err.message;
          this.GlobalFields.loadingSoft = false;
        }
      );
    }
    else {
      this.usernameField.nativeElement.focus();
      this.GlobalFields.loadingSoft = false;
    }

    // console.log(window.localStorage.getItem('authRoomless'));
    
  }

  externalSignIn(arrTmp) {
    this.authService.externalSignIn(arrTmp).subscribe(
      (res) => {
        if (res.success) {
          this.authService.storeToken(res.data as AuthInfo);
          this.GlobalFields.loadingSoft = false;
          this.loginComplete.emit(true);
        } else {
          this.errorMgs = !this.errorMgs.includes('LOGIN_REGISTRATION_ERRORS') ? this.errorMgs : "There is an error, please contact the support."
          this.GlobalFields.loadingSoft = false;
        }
      },
      (err) => {
        console.error(err)
        if (err.error && err.error.errorCode) {
          this.errorMgs = this.translate.instant('LOGIN_REGISTRATION_ERRORS.' + err.error.errorCode)
          this.errorMgs = !this.errorMgs.includes('LOGIN_REGISTRATION_ERRORS') ? this.errorMgs : "There is an error, please contact the support."
        } else
          this.errorMgs = err.message;
        this.GlobalFields.loadingSoft = false;
      }
    );
  }


  signUp() {
    this.GlobalFields.loadingSoft = true;
    this._isValidPhoneNumber(this.newProfile.phone);
    this._hasPrefix(this.newProfile.phonePrefixId) ? this.showPrefixError = false : this.showPrefixError = true;
    if (this.formSignUp.form.valid) {
      this.newProfile.username = this.newProfile.email;
      this.authService.registration(this.newProfile).subscribe(
        (res) => {
          if (res.success) {
            const data = res.data as { id: any; token: string };
            GlobalFields.currentUserInfo = this.newProfile;
            GlobalFields.currentUserInfo.username = this.newProfile.email;
            GlobalFields.currentUserInfo.id = data.id;
            this.authService.storeToken({ username: this.newProfile.email, token: data.token });
            this.loginComplete.emit(true);
            this.GlobalFields.loadingSoft = false;
          } else {
            this.errorMgs = "There is an error, please contact the support."
            this.GlobalFields.loadingSoft = false;
          }
          this.GlobalFields.loadingSoft = false;
        },
        (err) => {
          if (err.error && err.error.errorCode) {
            this.errorMgs = this.translate.instant('LOGIN_REGISTRATION_ERRORS.' + err.error.errorCode)
            this.errorMgs = !this.errorMgs.includes('LOGIN_REGISTRATION_ERRORS') ? this.errorMgs : "There is an error, please contact the support."
          } else
            this.errorMgs = err.message;
          this.GlobalFields.loadingSoft = false;
        }
      );
    } else {
      this.GlobalFields.loadingSoft = false;
    }
  }

  forgotPass() {
    if (this.newProfile.email !== undefined && this.newProfile.email !== '') {
      this.GlobalFields.loadingSoft = true;
      this.authService.forgotPass(this.newProfile.email).subscribe(
        (res) => {
          if (res.success) {
            const data = res.data;
            this.newProfile.email = '';
            this.viewMode = 4;
            this.errorMgs = '';
          } else {
            this.errorMgs = "There is an error, please contact the support."
            this.GlobalFields.loadingSoft = false;
          }
          this.GlobalFields.loadingSoft = false;
        },
        (err) => {
          if (err.error && err.error.errorCode) {
            this.errorMgs = this.translate.instant('LOGIN_REGISTRATION_ERRORS.' + err.error.errorCode)
            this.errorMgs = !this.errorMgs.includes('LOGIN_REGISTRATION_ERRORS') ? this.errorMgs : "There is an error, please contact the support."
          } else
            this.errorMgs = err.message;
          this.GlobalFields.loadingSoft = false;
        }
      );
    }
  }

  checkIfNumber(ev): boolean {
    const charCode = (ev.which) ? ev.which : ev.keyCode;
    // Allow arrow keys to be pressed
    if (charCode === 37 || charCode === 39) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isPhoneInput(): boolean {
    return this._isValidPhoneNumber(this.newProfile.phone);
  }

  _isValidPhoneNumber(phone: string, minLength = 8, maxLength = 13): boolean {
    this.detectedInvalidPhone = false;

    if (!phone) {
      this.detectedInvalidPhone = true;
      return false;
    }

    // in case of trailing spaces when copying/pasting
    const trimmedPhone = phone.trim();

    if (trimmedPhone.length < minLength || trimmedPhone.length > maxLength) {
      this.detectedInvalidPhone = true;
      return false;
    } else {
      this.detectedInvalidPhone = false;
    }

    return true;
  }

  _hasPrefix(prefix: number): boolean {
    if (!prefix) return false;
    return true;
  }

  isPrefixInput(): void {
    if (!this.newProfile.phonePrefixId) {
      this.showPrefixError = true;
    } else {
      this.showPrefixError = false;
    }
  }

  clearErrorMsg() {
    return (this.errorMgs = '');
  }

  logout() {
    this.authService.logout();
  }
}
