import { Injectable } from '@angular/core';
import { Rent } from "../entities/calendar/Visit";
import { Constants } from "../Constants";
import { Callbell, Profile } from "../entities/profile";
import { BusinessType, PaymentAccount } from "../Model";
import { PathConstants } from "../PathConstants";
import { Listing } from "../entities/listing/listing";
import { GlobalFields } from '../GlobalFields';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { InfoRequest } from "../entities/infoRequest";
import { SearchPlaceTranslations } from "../entities/place";
import { AdminAgents } from '../entities/adminSettings';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {


  constructor() { }

  /**
   * Convert date object in string yy-MM-dd
   * @date date to convert
   * @return date in string yy-MM-dd
   */
  public convertDateInString = (date: Date): string => {
    if (!(date instanceof Date)) return;

    const year = date.getFullYear();
    // JS calculate months from 0 to 11
    let month: number | string = date.getMonth() + 1;
    // if month is smaller than 10 add 0, avoid safari issue invalid date
    if (month < 10) {
      month = '0' + month
    }
    // avoid issues with material datepicker, add 0 if day is less than 10
    let day: number | string = date.getDate();
    if (day < 10) {
      day = '0' + day
    }

    return `${year}-${month}-${day}`;
  }

  public getTodayAsString = (): string => {
    const today = new Date();
    return this.convertDateInString(today);
  }

  convertDate(inputDate: string): string {
    try {
      const [year, month, day] = inputDate.split('-');

      const date = new Date(`${year}-${month}-${day}`);

      const formattedDay = date.getDate().toString().padStart(2, '0');
      const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
      const formattedYear = date.getFullYear();

      return `${formattedDay}/${formattedMonth}/${formattedYear}`;
    } catch (error) {
      console.error("Error converting date:", error.message);
      return "Invalid date";
    }
  }




  public getThisWeekIntervalAsString = (): { start: string, end: string } => {
    const today = new Date();
    const weekStart = startOfWeek(today, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(today, { weekStartsOn: 1 });
    return { start: this.convertDateInString(weekStart), end: this.convertDateInString(weekEnd) };
  }

  public getThisMonthIntervalAsString = (): { start: string, end: string } => {
    const today = new Date();
    const monthStart = startOfMonth(today);
    const monthEnd = endOfMonth(today);
    return { start: this.convertDateInString(monthStart), end: this.convertDateInString(monthEnd) };
  }

  public getThisMonthPreviousYearIntervalAsString = (): { start: string, end: string } => {
    let today = new Date();
    today.setFullYear(today.getFullYear() - 1);
    const monthStart = startOfMonth(today);
    const monthEnd = endOfMonth(today);
    return { start: this.convertDateInString(monthStart), end: this.convertDateInString(monthEnd) };
  }

  /*
  * By default if no params are passed returns current year intervals
  * @optional @param dateToYear date to get year interval
  * @optional @param lastYear get last year interval
  * */
  public getYearIntervalAsString = (dateToYear = new Date(), lastYear?: boolean): { start: string, end: string } => {
    const year = lastYear ? dateToYear.getFullYear() - 1 : dateToYear.getFullYear();
    // return {start: `2023-01-01`, end: `2023-12-31`}; // TODO restore current year
    return { start: `${year}-01-01`, end: `${year}-12-31` };
  }

  public getPreviousYearIntervalAsString = (dateToYear = new Date(), lastYear?: boolean): { start: string, end: string } => {
    const year = lastYear ? dateToYear.getFullYear() - 1 : dateToYear.getFullYear();
    // return {start: `2023-01-01`, end: `2023-12-31`}; // TODO restore current year
    return { start: `${year - 1}-01-01`, end: `${year - 1}-12-31` };
  }




  public getWhatsappMsg(lang: string) {
    switch (lang) {
      case 'it':
        return "Ciao, sono $agentName di Spacest, piacere di conoscerti.\n\nHo ricevuto il tuo messaggio per questo annuncio $listingUrl $sourceLabel.\nAl link trovi tutte le informazioni e puoi prenotare direttamente online cliccando su affitta subito.\n\nSe questo immobile non rispecchiasse i tuoi requisiti, dai uno sguardo al nostro sito qui: $websiteURL\nOppure chiedi a me e ti darò una mano nella ricerca.\n\nPer qualsiasi domanda non esitare a rispondere a questo messaggio oppure chiamarmi. Buona giornata."
      case 'de':
        return "Hallo, ich bin $agentName von Spacest, gerne helfe ich dir heute weiter.\n\nIch habe deine Nachricht für das Inserat $listingUrl gesehen $sourceLabel.\nUnter dem Link findest du alle Informationen und du kannst auch direkt online buchen, indem du auf \"Jetzt Mieten\" klickst. Falls dieses Objekt nicht deinen Anforderungen entspricht, dann werfe einen Blick auf $websiteURL oder wende dich mit Fragen direkt an mich. Ich lasse dir auch gerne ein paar Vorschläge zukommen.\n\n Ich freue mich, dich bei deiner Suche zu unterstützen.\n\n Viele Grüße"
      case 'fr':
        return "Bonjour, je suis $agentName de Spacest, enchanté(e) de faire votre connaissance.\n\nJ'ai reçu votre message pour cette annonce $listingUrl $sourceLabel.\nSur le lien, vous trouverez toutes les informations et vous pourrez réserver directement en ligne en cliquant sur louez dès maintenant.\n\nSi ce logement ne correspond pas à vos attentes, je vous invite à consulter notre site web : $websiteURL\nOu n'hésitez pas à me contacter et je vous aiderai dans vos recherches.\n\nSi vous avez des questions, n'hésitez pas à répondre à ce message ou à m'appeler. Je vous prie d'agréer, Madame, Monsieur, l'expression de mes salutations distinguées"
      case 'es':
        return "Hola, soy $agentName de Spacest, un placer saludarte.\n\nHe visto tu mensaje por este anuncio $listingUrl $sourceLabel.\nEn el link puedes encontrar más información y puedes alquilarlo directaremente online clickeando en alquila ahora.\n\nSi este alojamiento no se ajusta a tus necesidades, consulte nuestra página web: $websiteURL\nO no dude en preguntarme y le ayudaré con su investigación.\n\nSi tiene alguna pregunta, por favor no dude en responder este mensaje o llamarme. Saludos"
      case 'pt':
        return "Olá, sou o $agentName da Spacest, prazer em conhecê-lo.\n\nVi a sua mensagem para esta listagem $listingUrl $sourceLabel.\nNo link encontrará toda a informação e poderá reservar diretamente online clicando em arrendar agora.\n\nSe este alojamento não satisfaz os seus requisitos, por favor veja o nosso website aqui: $websiteURL\nOu sinta-se à vontade para me perguntar e eu irei ajudá-lo com a sua pesquisa.\n\nSe tiver alguma questão, por favor não hesite em responder a esta mensagem ou telefonar-me. Com os melhores cumprimentos"
      default: //English
        return "Hi, I am $agentName from Spacest, nice to meet you.\n\nI saw your message for this listing $listingUrl $sourceLabel.\nAt the link you will find all the information and you can book directly online by clicking on rent now.\n\nIf this accommodation does not meet your requirements, please have a look at our website here: $websiteURL\nOr feel free to ask me and I will help you with your research.\n\nIf you have any questions, please do not hesitate to reply to this message or call me. Best regards"
    }
  }

  public monthsNamesByIndex() {
    return [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
  }

  /*
      'January',
      'February',
      'March',
      'April',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
  * */


  public getCountry(listingData: Listing): string {

    let country = ''

    if (listingData.address && listingData.address.addressCountryCode) {
      country = listingData.address.addressCountryCode;
    } else if (listingData.structure && listingData.structure.address.addressCountryCode) {
      country = listingData.structure.address.addressCountryCode;
    } else if (listingData.accomodationDetails && listingData.accomodationDetails.address?.addressCountryCode) {
      country = listingData.accomodationDetails.address.addressCountryCode;
    }

    return country

  }

  public getCountryId(listingData: Listing): number {

    let country = 1;

    if (listingData.address && listingData.address.addressCountryId) {
      country = listingData.address.addressCountryId;
    } else if (listingData.structure && listingData.structure.address.addressCountryId) {
      country = listingData.structure.address.addressCountryId;
    } else if (listingData.accomodationDetails && listingData.accomodationDetails.address?.addressCountryId) {
      country = listingData.accomodationDetails.address.addressCountryId;
    }

    return country;
  }

  // Get enum name from value
  public getEnumName = function (enumType: object, value: number | string) {
    if (!value) return ''
    return Object.values(enumType).indexOf(value);
  }
  public getPlacesTranslations = (): SearchPlaceTranslations[] => {
    const placeTranslations: SearchPlaceTranslations[] = []

    GlobalFields.languagesList.forEach(lang => {
      placeTranslations.push({ lang: lang.code, name: '', description: '', description1: '', description2: '', title: '', subtitle: '' })
    })

    return placeTranslations
  }


  // Get enum Value from Name
  public getEnumKeyByIndex = function (enumType: object, value?: string) {
    if (!value) return ''
    return Object.keys(enumType).indexOf(value);
  }

  /*
  * Used to validate input fields allowing to type only numbers, backspace
   */
  public checkIfNumber(ev): boolean {
    const charCode = (ev.which) ? ev.which : ev.keyCode;
    // Allow arrow keys to be pressed
    if (charCode === 37 || charCode === 39) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /*
  * Used to disable input of commas in number fields
  */
  public disallowComma(ev): boolean {
    const charCode = (ev.which) ? ev.which : ev.keyCode;
    if (charCode === 188) {
      return false;
    }
    return true;
  }

  // Calculate Refund totals by recipient
  public calculateTotalToTenant(rent: Rent) {
    let total = 0;
    if (rent)
      total += rent.totalPaid;
    if (rent.deposit && this.showDeposit(rent)) {
      total += rent.deposit;
    }

    return total;
  }

  /**
   * Deposit is enabled for all countries. Previously we checked if this.isListingFromCountry(Constants.ITALY_ID, listing)
   */
  public showDeposit(rent: Rent) {
    return rent.depositStatus; //If not null and not 0, 0 = deposit not to be collected
  }

  /*
  * Returns true if listing address country is equal to countryId
  * */
  isListingFromCountry(countryId: number, listing: Listing): boolean {
    return countryId === this._checkIfListingIsFromCountry(listing);
  }

  /*
  * Find listing country from address
   */
  _checkIfListingIsFromCountry(listing: any): number {
    if (listing?.structure && listing?.structure?.address) {
      listing.structure.address.addressCountryId;
    } else if (listing?.address) {
      return listing.address.addressCountryId;
    } else if (listing?.accomodationDetails?.address) {
      return listing.accomodationDetails.address.addressCountryId;
    }
    console.warn('addressCountryId missing on address', listing?.id);
    return -1;
  }

  public calculateToLandlordTotal(rent: Rent) {
    let total = 0;
    if (rent && rent.bankTransferStatus !== Constants.BANK_TRANSFER_SENT) {
      total += this.calculateMonth(rent);
    }
    if (rent && rent.landlordFee &&
      (rent.landlordFeeStatus === Constants.LAND_FEE_STATUS_DEDUCED || rent.landlordFeeStatus === Constants.LAND_FEE_STATUS_PAID || rent.landlordFeeStatus === Constants.LAND_FEE_STATUS_TO_BE_PAID)) {
      total += rent.landlordFee;
    }
    return total;
  }

  public calculateToRoomlessFromLandlord(rent: Rent) {
    let total = 0;
    if (rent && rent.bankTransferStatus === Constants.BANK_TRANSFER_SENT) {
      if (rent.deposit && this.showDeposit(rent)) {
        total += rent.deposit;
      }

      if (rent.tenantFeeSplit && rent.bankTransferStatus === Constants.BANK_TRANSFER_SENT) {
        total += rent.tenantFeeSplit;
      }
      total += this.calculatePartOfFirstMonth(rent);
    }
    return total;
  }

  public calculatePartOfFirstMonth(rent: Rent) {
    let total = 0;
    total += this.calculateMonth(rent);
    total -= this.sumLandlordFeeAndServices(rent);
    return total;
  }

  public findErrorTitle(err: any): string {
    // nested errors
    if (err.status === 429) {
      return 'Too many requests, please wait a few minutes and try again.';
    }
    if (err.error?.errorDetails && err.error?.errorDetails.message) {
      return err.error?.errorDetails.message;
    }

    if (err.error?.errorCode) {
      return err.error?.errorCode;
    }
    // plain errors
    if (err.errorDetails && err.errorDetails.message) {
      return err.errorDetails.message;
    }

    if (err.errorCode) {
      return err.errorCode;
    }
    if (err.errorDetails && err.errorDetails.message) {
      return err.errorDetails.message;
    }
    if (err.errorMessages && err.errorMessages.length > 0) {
      return err.errorMessages[0];
    }

    return 'Generic Error'
  }

  public findErrorMessage(err: any): string {
    // nested errors
    if (err.status === 429) {
      return;
    }
    if (err.error?.errorMessages && err.error?.errorMessages.length > 0) {
      return err.error.errorMessages[0];
    }

    if (err.error?.message) {
      return err.error?.message;
    }
    // plain errors
    if (err.errorMessages && err.errorMessages.length > 0) {
      return err.errorMessages[0];
    }

    if (err.message) {
      return err.message;
    }
    return 'Generic Error'
  }

  /*
  * Used to calculate the total of landlord fee and services in Manage Refund: "To Roomless from landlord"
  */
  public sumLandlordFeeAndServices(rent: Rent) {
    let sum = 0;
    if (rent.landlordFee && (rent.landlordFeeStatus === Constants.LAND_FEE_STATUS_DEDUCED || rent.landlordFeeStatus === Constants.LAND_FEE_STATUS_TO_BE_DEDUCED)) {
      sum += rent.landlordFee;
    }
    if (rent.additionalCharges && rent.additionalCharges.length > 0) {
      for (let charge of rent.additionalCharges) {
        sum += charge.amount;
      }
    }
    return sum;
  }

  public calculateMonth(rent: Rent) {
    if (rent)
      return Math.round(rent.totalPaid - rent.fees);
  }

  cleanPhone(phone: string) {
    if (phone != null) {
      return phone.replace(/[\s,\-,\+,\(,\)]+/g, "").trim();
    } else {
      return phone;
    }
  }




  public openWhatsappLink(user: Profile, infoRequest?: InfoRequest, showWhatsappMsg?: boolean, forceLang?: string, source?: number, forcePhone?: string) {
    if (user) {
      let url = `https://wa.me/${this.cleanPhone(forcePhone ? forcePhone : user.phone)}`;

      const sourceMessage = this.getSourceMessage(forceLang ? forceLang : user.primaryLanguage.toLowerCase(), source);
      const text = this.decodeWhatsappMessage(forceLang ? forceLang : user.primaryLanguage, infoRequest, sourceMessage);

      if (showWhatsappMsg) {
        url += `?text=${encodeURIComponent(text)}`;
      }

      GlobalFields.window.open(url, '_blank');
    }
  }


  getSourceLabelFromValue(value: number): string {
    const selectedItem = Constants.SUPPLY_LEAD_SOURCE.find(item => item.value === value);
    return selectedItem?.label || '';
  }

  getSourceLangFromValue(lang: string): string {
    const selectedItem = Constants.SOURCE_MESSAGE.find(item => item.lang === lang);
    return selectedItem?.label || '';
  }
  private getSourceMessage(forceLang: string, source: number): string {
    const lang = this.getSourceLangFromValue(forceLang);
    const sourceFromValue = this.getSourceLabelFromValue(source);
    return sourceFromValue ? ` ${lang}${sourceFromValue}` : '';
  }

  private decodeWhatsappMessage(language: string, infoRequest: InfoRequest, sourceLabel: string): string {
    let text = this.getWhatsappMsg(language);
    text = text.replace("$websiteURL", GlobalFields.websiteURL);
    text = text.replace("$sourceLabel", sourceLabel);
    text = text.replace("$agentName", infoRequest?.requester?.agent?.firstName || infoRequest?.agent?.firstName || GlobalFields.currentUserInfo.firstName);
    text = text.replace("$listingUrl", `${GlobalFields.websiteURL}/${language}/rent-listing/${infoRequest?.listing?.id}`);
    return text;
  }



  public populatePaymentInfoFromBillingInfo(userProfile: Profile): PaymentAccount {
    const billing = userProfile?.billingInfo;

    let prefillForm = {} as PaymentAccount;

    if (billing) {

      if (!billing.type) { // if no type value, PRIVATE by default
        billing.type = BusinessType.PRIVATE;
      }

      prefillForm.businessType = billing.type;

      if (billing.type === BusinessType.PRIVATE) {

        prefillForm = { ...prefillForm, ...this._privatePaymentFromUser(userProfile, prefillForm) };

        if (billing.firstName) {
          prefillForm.firstName = billing.firstName;
        }
        if (billing.lastName) {
          prefillForm.lastName = billing.lastName;
        }

      } else if (billing.type === BusinessType.COMPANY) {

        if (billing.email) {
          prefillForm.email = billing.email;
        }

        if (billing.vatNumber) {
          prefillForm.taxId = billing.vatNumber;
        }

        if (billing.businessName) {
          prefillForm.companyName = billing.businessName;
        }
      }
      // shared fields

      if (billing.nationalityId) {
        prefillForm.addressCountryId = billing.nationalityId;
      }

      if (billing.state) {
        prefillForm.addressState = billing.state;
      }

      if (billing.city) {
        prefillForm.addressCity = billing.city;
      }

      if (billing.address) {
        prefillForm.addressLine1 = billing.address;
      }

      if (billing.houseNumber) {
        prefillForm.addressNumber = billing.houseNumber;
      }

      if (billing.zipCode) {
        prefillForm.addressPostalCode = billing.zipCode;
      }
      // prefills fields that exists for company type
      prefillForm = { ...prefillForm, ...this._companyPaymentFromUser(userProfile, prefillForm) };

    } else {
      prefillForm.businessType = BusinessType.PRIVATE;

      prefillForm = { ...prefillForm, ...this._privatePaymentFromUser(userProfile, prefillForm) };
    }
    return prefillForm;
  }

  _privatePaymentFromUser(userProfile: Profile, prefillForm: PaymentAccount): Partial<PaymentAccount> {
    if (userProfile.firstName) {
      prefillForm.firstName = userProfile.firstName;
    }

    if (userProfile.lastName) {
      prefillForm.lastName = userProfile.lastName;
    }

    if (userProfile.gender) {
      prefillForm.gender = userProfile.gender === 3 ? 2 : userProfile.gender; // if "OTHER" gender set to female. Stripe only allows binary gender.
    }

    if (userProfile.birthDate) {
      prefillForm.dateOfBirth = new Date(userProfile.birthDate);
    }

    if (userProfile.email) {
      prefillForm.email = userProfile.email;
    }

    if (userProfile.phonePrefixId) {
      prefillForm.phonePrefixId = userProfile.phonePrefixId;
    }

    if (userProfile.phone) {
      prefillForm.phone = userProfile.phone;
    }

    return prefillForm;
  }

  _companyPaymentFromUser(userProfile: Profile, prefillForm: PaymentAccount): Partial<PaymentAccount> {
    if (userProfile.email) {
      prefillForm.email = userProfile.email;
    }

    if (userProfile.phonePrefixId) {
      prefillForm.phonePrefixId = userProfile.phonePrefixId;
    }

    if (userProfile.phone) {
      prefillForm.phone = userProfile.phone;
    }
    return prefillForm;
  }

  /*
  * Returns the dashboard where to redirect the user
  * */
  filterUserUrlByPage(dashboardSource: string): string {

    if (dashboardSource.includes(PathConstants.salesDashboard)) {
      return `/${PathConstants.salesDashboard}/${PathConstants.users}`;
    }
    if (dashboardSource.includes(PathConstants.supplyDashboard)) {
      return `/${PathConstants.supplyDashboard}/${PathConstants.users}`;
    }
    // default
    return `/${PathConstants.supplyDashboard}/${PathConstants.users}`;
  }

  filterUserListingsUrlByPage(dashboardSource: string): string {

    if (dashboardSource.includes(PathConstants.salesDashboard)) {
      return `/${PathConstants.salesDashboard}/${PathConstants.listings}`;
    }
    if (dashboardSource.includes(PathConstants.supplyDashboard)) {
      return `/${PathConstants.supplyDashboard}/${PathConstants.listings}`;
    }
    // default
    return `/${PathConstants.supplyDashboard}/${PathConstants.listings}`;
  }

  // This function checks that there are no empty strings in the object (used in lead [sales and supply])
  public cleanEmptyStrings(obj: any): any {

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'string' && obj[key] === '' || (typeof obj[key] === 'object' && obj[key] === null)) {
          obj[key] = undefined;
        }
        else if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.cleanEmptyStrings(obj[key]);
        }
      }
    }
    return obj;
  }
  _sortAgentsByName(agentsList: AdminAgents[]) {
    return agentsList.sort((agent1, agent2) => agent1.firstName.toLowerCase() > agent2.firstName.toLowerCase() ? 1 : -1);
  }
}
