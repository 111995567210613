<div class="nav">
  <div class="nav-box">
    <div class="nav-logo">
      <div class="logo">
        <a
          *ngIf="GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.LOCARE"
          title="Roomless" class="logo-link">
          <img style="max-height: 40px; max-width: 114px; width: 100%; cursor: pointer" src="./assets/imgs/logo/logo_roomless_for_spacest.png" alt="Roomless">
        </a>
        <a
          *ngIf="GlobalFields.currentUserInfo.adminTypeId !== AdminTypeEnum.LOCARE"
          (click)='changeRoute(PathConstants.insightsDashboard)'
          title="Roomless" class="logo-link">
          <img style="max-height: 40px; max-width: 114px; width: 100%; cursor: pointer" src="./assets/imgs/logo/logo_roomless_for_spacest.png" alt="Roomless">
        </a>
        <a  *ngIf="GlobalFields.currentUserInfo.adminTypeId !== AdminTypeEnum.LOCARE"
            class="btn-menu after-logo"
            style='cursor: pointer;'
            [class.pageSelected]="isPageSelected(PathConstants.insightsDashboard)"
            (click)='changeRoute(PathConstants.insightsDashboard)'>
            Insights  <i class="fal fa-chart-line"></i>
        </a>

        <a
            *ngIf="GlobalFields.currentUserInfo.adminTypeId !== AdminTypeEnum.LOCARE"
            class="btn-menu"
            [href]="!GlobalFields.isTestEnvironment ? Constants.prodSiteURL + '/' + PathConstants.searchPageRent: 'https://beta.roomlessrent.com/' + PathConstants.searchPageRent" target="_blank">
            Website   <i class="fal fa-external-link"></i>
        </a>
      </div>
    </div>
    <div class="nav-serch">
    </div>
    <div class="nav-menu">
      <nav>
        <ul class="menu-list">

          <li class="menu-item dropdown " *ngIf="GlobalFields.currentUserInfo && GlobalFields.checkPathPermission(PathConstants.travelDashboard)">
            <button [class.pageSelected]="isPageSelected(PathConstants.travelDashboard)" type="button" class="btn-menu" aria-haspopup="true" aria-expanded="false"
                    aria-busy="false" [routerLink]="['/', PathConstants.travelDashboard]" routerDirection="root">
              Travel Dashboard
            </button>
          </li>

          <li class="menu-item dropdown " *ngIf="GlobalFields.currentUserInfo && GlobalFields.checkPathPermission(PathConstants.notificationDashboard)">
            <button [class.pageSelected]="isPageSelected(PathConstants.notificationDashboard)" type="button" class="btn-menu" aria-haspopup="true" aria-expanded="false"
                    aria-busy="false" [routerLink]="['/', PathConstants.notificationDashboard, PathConstants.emails]" routerDirection="root">
              Notification Dashboard
            </button>
          </li>

          <li class="menu-item dropdown " *ngIf="GlobalFields.currentUserInfo && GlobalFields.checkPathPermission(PathConstants.seoDashboard)">
            <button [class.pageSelected]="isPageSelected(PathConstants.seoDashboard)" type="button" class="btn-menu" aria-haspopup="true" aria-expanded="false"
                    aria-busy="false" [routerLink]="['/', PathConstants.seoDashboard, PathConstants.places]" routerDirection="root">
              SEO
            </button>
          </li>

          <li class="menu-item dropdown " *ngIf="GlobalFields.currentUserInfo && GlobalFields.checkPathPermission(PathConstants.adminDashboard)">
            <button [class.pageSelected]="isPageSelected(PathConstants.adminDashboard)" type="button" class="btn-menu" aria-haspopup="true" aria-expanded="false"
                    aria-busy="false" (click)="changeRoute(PathConstants.adminDashboard)">
              Administration
            </button>
          </li>

          <li class="menu-item dropdown " *ngIf="GlobalFields.currentUserInfo && GlobalFields.checkPathPermission(PathConstants.supplyDashboard)">
            <button [class.pageSelected]="isPageSelected(PathConstants.supplyDashboard)" type="button" class="btn-menu" aria-haspopup="true" aria-expanded="false"
                    aria-busy="false" (click)="changeRoute(PathConstants.supplyDashboard)">
              Supply Dashboard
            </button>
          </li>

          <li class="menu-item dropdown " *ngIf="GlobalFields.currentUserInfo && GlobalFields.checkPathPermission(PathConstants.salesDashboard)">
            <button [class.pageSelected]="isSalesDashboard()" type="button" class="btn-menu" aria-haspopup="true" aria-expanded="false"
                    aria-busy="false" (click)="changeRoute(PathConstants.salesDashboard)">
              Sales Dashboard
            </button>
          </li>

          <li class="menu-item dropdown" *ngIf="GlobalFields.currentUserInfo">

            <button type="button" class="btn-menu" aria-haspopup="true" aria-expanded="false"
                    aria-busy="false">
              <!-- Admin-->
              <span *ngIf="GlobalFields.currentUserInfo && GlobalFields.currentUserInfo.userTypeId === Constants.ADMIN_ID">
                            <div class="imgProfile" style="background-color: #ff646a;">
                                <i class="fal fa-user-crown" style="font-size: 13pt;color: white;"></i>
                            </div>
                        </span>
              <b *ngIf="GlobalFields.currentUserInfo && GlobalFields.currentUserInfo.userTypeId === Constants.ADMIN_ID"> {{GlobalFields.currentUserInfo.firstName}}</b>
              <span *ngIf="GlobalFields.currentUserInfo && GlobalFields.currentUserInfo.userTypeId !== Constants.ADMIN_ID" style="display: inline-flex; vertical-align: middle;">
                <div class="imgProfileRounded" *ngIf="GlobalFields.currentUserInfo.profilePicUrl" [style.background]="'url(' +GlobalFields.currentUserInfo.profilePicUrl+')'"></div>
                <div *ngIf="!GlobalFields.currentUserInfo.profilePicUrl" class="imgProfile">
                  <i class="fal fa-image" style="font-size: 13pt;color: #eeeeee;"></i>
                </div>
              </span>
              <span>
                <span class="d-none d-lg-block" *ngIf="GlobalFields.currentUserInfo.userTypeId !== Constants.ADMIN_ID"> {{GlobalFields.currentUserInfo.firstName}} {{GlobalFields.currentUserInfo.lastName}}</span>
                <i class="far fa-chevron-down" style="margin-left: 5px"></i>
              </span>
            </button>
            <ul class="submenu">
              <li><button type="button" (click)="logout()">Exit</button></li>
            </ul>
          </li>

          <li class="menu-item dropdown" *ngIf="!GlobalFields.currentUserInfo">
            <button type="button" class="btn-menu" aria-haspopup="true" aria-expanded="false"
                    aria-busy="false"  (click)="openLoginModal()">
              <span class="menuIcon"><i class="fal fa-user"></i></span>
            </button>
          </li>

          <li class="menu-item dropdown hideDesktop showTablet">
            <button *ngIf="!GlobalFields.sideMenuOpened" (click)="openSidebar()" style="height: 100%; background-color: white">
              <i class="fal fa-bars"  style="font-size: 22px; margin-top: 5px"></i>
            </button>
            <button *ngIf="GlobalFields.sideMenuOpened" (click)="closeSidebar()" style="height: 100%; background-color: white">
              <i class="fad fa-times colorPrimary"  style="font-size: 22px"></i>
            </button>
          </li>

        </ul>
      </nav>
    </div>
  </div>
</div>
