import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import {PathConstants} from '../../../PathConstants';

@Component({
  selector: 'modalLogin',
  templateUrl: 'modalLogin.html',
  styleUrls: ['modalLogin.scss'],
})
export class ModalLogin {
  @Input() notRedirect?: boolean;
  @Input() borderless?: boolean;
  @Input() isAOwner?: boolean;

  constructor(private modalCrtl: ModalController, private router: Router) {}

  dismissModalLogin() {
    this.modalCrtl.dismiss({ dismissed: true });

    if (this.isAOwner) {
      this.router.navigate(['/' + PathConstants.createListing]);
    }
    /* else{
            if (!this.notRedirect)
                this.router.navigate(['/home']);
        }*/
  }

  closeModal() {
    this.modalCrtl.dismiss();
  }
}
