
<ion-header style="background-color: white; border-bottom: 1px solid #ebebeb;" *ngIf="isModal">
  <ion-toolbar style="padding: 0 10px 0 10px;">
    <ion-buttons slot="start"  (click)="closeModal()">
      <i class="far fa-times" style="font-size: 16pt;"></i>
    </ion-buttons>
    <h1>{{"COMPLETE_REG" | translate}}</h1>
  </ion-toolbar>
</ion-header>



<ion-content class="cards-bg social-cards">
    <div class="mainContentDesktop">

        <h1 class="hideMobile" style="text-align: center; padding-top: 75px">{{"COMPLETE_REG" | translate}}</h1>
        <h1 class="hideDesktop" style="text-align: center;">{{"COMPLETE_REG" | translate}}</h1>

        <div style="text-align: center">
            <img src="./assets/imgs/blocco_affitto/incontra.svg" style="height: 150px">
        </div>

        <!-- Social/Google login-->
        <div style="max-width: 500px; margin: 0 auto; padding: 20px" *ngIf="GlobalFields.currentUserInfo.userTypeId === undefined">
          <div style="margin-bottom: 20px"><b>{{"YOU_ALMOST_THERE" | translate}} </b> <span [innerHTML]="'ONE_LAST_STEP_REG' | translate"></span></div>
          <span class="titleInput">{{"WHAT_KIND_USER_YOU_ARE" | translate}}</span>
          <ion-radio-group [(ngModel)]="newProfile.userTypeId">
              <ion-item>
                  <ion-label>{{"TENANT" | translate}}</ion-label>
                  <ion-radio slot="start"  [value]="Constants.TENANT_ID"></ion-radio>
              </ion-item>

              <ion-item>
                  <ion-label>{{"OWNER" | translate}}</ion-label>
                  <ion-radio slot="start" [value]="Constants.OWNER_ID"></ion-radio>
              </ion-item>
          </ion-radio-group>

          <div class="row row-condensed space-4" style="margin-top: 20px">
              <label class="text-left col-sm-12" for="phone">
                  {{"TELEPHONE_NUMBER" | translate}} <i class="fal fa-lock-alt text-danger" mdbTooltip="Privato" placement="top"></i>
              </label>
              <div class="col-sm-12">
                <div class="numberPrefixBox">
                  <div id="prefixField" *ngIf="countries && countries.length>0">
                    <mdb-select id="phonePrefixId" name="phonePrefixId" [(ngModel)]="newProfile.phonePrefixId" [options]="countries" [filterEnabled]="true"
                                [multiple]="false" [allowClear]="true" placeholder="{{'PREFIX' | translate}} *"></mdb-select>
                  </div>
                  <div id="phoneField">
                    <i class="fal fa-phone colorSecondary"></i>
                    <input type="tel" id="phone" name="phone" [(ngModel)]="newProfile.phone" size="30" value="" class="form-control" style="margin-top: -38px; margin-left: 15px;">
                  </div>
                </div>
                <span style="color:red; display: none" id="phoneErr">{{"10_DIGIT_NUMBER" | translate}}</span>
                <div class="space-top-1 text-description-field colorSecondary"  style="font-size: 9pt;">
                  {{"YOUR_PHONE_NUMBER_NOT_PUBLIC" | translate}}
                </div>
              </div>
          </div>
          <br>
          <div>
              <ion-item lines="none">
                  <ion-checkbox slot="start" style="margin: 0 !important;" [(ngModel)]="newProfile.acceptMarketing"></ion-checkbox>
                  <ion-label style="color: #484848 !important; text-overflow: unset; white-space: unset; padding: 5px; line-height: 1.2;font-size: 9pt !important;">
                      {{"MARKETING_CONSENSE" | translate}}
                  </ion-label>
              </ion-item>
          </div>

          <br>
          <div style="font-size: 8pt; margin: 0 auto; margin: 10px auto; text-align: center; background: #fff;" [innerHTML]="'BY_REGISTERING_DECLARE_ACCEPT_PRIVACY' | translate">
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="errorMgs">
            {{errorMgs}}
          </div>

          <div style="text-align: center">
            <button class="generalRoomlessBtb registerBtn backgroundSecondary" type="submit"style="font-weight-bold: bold" (click)="newProfile.acceptPolicy = true; saveRoleType()">
                {{"REGISTER_ACCEPT_CONTINUE" | translate}}
            </button>
          </div>

        </div>

        <!-- Landlord scraper login -->
        <div style="max-width: 500px; margin: 0 auto; padding: 20px" *ngIf="GlobalFields.currentUserInfo.email === undefined">
            <div style="margin-bottom: 20px"><b>{{"YOU_ALMOST_THERE" | translate}} </b> <span [innerHTML]="'ONE_LAST_STEP_REG' | translate"></span></div>

            <div class="row row-condensed space-4" style="margin-top: 20px">
                <label class="text-left col-sm-12" for="phone">
                    Email <i class="fal fa-lock-alt text-danger" mdbTooltip="Privato" placement="top"></i>
                </label>
                <div class="col-sm-12">
                    <input id="email" name="email" [(ngModel)]="newProfile.email" size="30" type="text" value="" class="form-control">
                    <span style="color:red; display: none" id="emailErr">Inserisci la tua mail</span>
                    <div class="text-muted space-top-1 text-description-field" style="opacity: 1!important;">
                        {{"HIDDEN_EMAIL" | translate}}
                    </div>
                </div>
            </div>

            <div class="alert alert-danger" role="alert" *ngIf="errorMgs">
                {{errorMgs}}
            </div>


            <br>
            <div>
                <ion-item lines="none">
                    <ion-checkbox slot="start" style="margin: 0 !important;" [(ngModel)]="newProfile.acceptMarketing"></ion-checkbox>
                    <ion-label style="color: #484848 !important; text-overflow: unset; white-space: unset; padding: 5px; line-height: 1.2;font-size: 9pt !important;">
                        {{"MARKETING_CONSENSE" | translate}}
                    </ion-label>
                </ion-item>
            </div>

            <br>
            <div style="font-size: 8pt; margin: 0 auto; margin: 10px auto; text-align: center; background: #fff;" [innerHTML]="'BY_REGISTERING_DECLARE_ACCEPT_PRIVACY' | translate">
            </div>

            <div style="text-align: center">
                <ion-button class="generalRoomlessBtb registerBtn backgroundSecondary" type="submit" style="font-weight-bold: bold" (click)="newProfile.acceptPolicy = true; confirmRegistration()">
                    {{"REGISTER_ACCEPT_CONTINUE" | translate}}
                </ion-button>
            </div>

        </div>


        <!-- Come funziona proprietari-->
      <span *ngIf="GlobalFields.currentUserInfo.userTypeId === Constants.OWNER_ID">
      <br>
      <div style="border-top: solid 1px #e3e7ea; margin: 10px 25px;"></div>

      <h2 style="text-align: center; font-weight: bold">Scopri i vantaggi di Roomless</h2>

      <ion-row style="flex-wrap: wrap-reverse; text-align: center">
        <!-- In mobile le colonne sono in verticale e in ordine inverso --->
        <ion-col class="textDescrCreation" size-xl="6" size-xs="12">
          <h3>Nessun costo di commissione</h3>
          <p>Puoi ricevere <b>visite virtuali</b> o in loco senza commissioni. Aggiungere un annuncio su Roomless è totalmente <b>GRATUITO</b> per il proprietario di casa!
          </p>
          <br>
          <h3>Trova nuovi potenziali inquilini</h3>
          <p>Roomless ti permette di raggiungere tanti nuovi potenziali inquilini interessati al tuo annuncio. Inizia a ricevere richieste in pochi minuti!</p>
        </ion-col>
        <ion-col  size-xl="6" size-xs="12">
          <h3>Ricevi richieste di visite e di affitto</h3>
          <p>Crea ora il tuo annuncio GRATUITAMENTE ed inizia a ricevere tante richieste da potenziali nuovi inquilini: <b>basta chiamate, scambi email o massaggi.</b> Automatizza il tuo affitto.</p>
          <br>
          <h3>Ricevi richieste di affitto</h3>
          <p>Crea ora il tuo annuncio per iniziare a ricevere richieste di affitto.<br>
            L'inquilino pagherà in anticipo le mensilità, tu riceverai la quota versata al momento del suo arrivo in casa.<br><b>Garanzia Roomless al 100%</b></p>
        </ion-col>
      </ion-row>
    </span>

    </div>

</ion-content>


<ion-footer  style="padding: 5px; border-top: solid #dbdbdb 1px; box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px !important;" *ngIf="isModal">
    <div style="text-align: center; width: 100%">
        <ion-button class="registerBtn" type="submit" color="secondary" style="font-weight-bold: bold" (click)="saveRoleType()">
      {{"CONFIRMATION" | translate}}
        </ion-button>
    </div>
</ion-footer>

