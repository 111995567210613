import { SelectItem } from './services/SelectIonicItem';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { Listing } from './entities/listing/listing';
import { CurrencyObject } from './Model';
/* import { LeadStatus } from "./entities/supplyLead"; */
/* import { SalesLeadStatus } from "./entities/infoRequest"; */
import { LandlordFunnelStatus, LeadManagementStatusEnum, ListingStatusEnum, TenantFunnelStatus } from "./entities/profile";

export class Constants {


  public static gtagID = 'GTM-WVNHR59';
  public static UNDER_CONSTRUCTION = false; // used to activate the maintenance page

  // public static serverURL = 'https://ec2-3-15-184-173.us-east-2.compute.amazonaws.com:8443/';
  public static serverURL = 'https://apigateway.roomlessrent.com/';
  public static testServerURL = 'https://apigateway-dev.roomlessrent.com/';
  public static version = 'v1'; //v1 or v2

  public static uploadServerURL = "https://api.roomlessrent.com/";
  public static testUploadServerURL = 'http://ec2-3-15-222-149.us-east-2.compute.amazonaws.com:8080/';
  public static server_suffix1 = 'roomless-rest-server/';
  public static rest_prefix = 'rest/';

  // New endpoints paths for lambdas
  public static admin_module = Constants.version + '/admin-svc/'; // admin microservice
  public static visits_module = Constants.version + '/visit-svc/'; // visits microservice
  public static listing_module = 'listing-svc/'; // listing microservice
  public static upload_module = 'upload-svc/'; // upload microservice
  public static user_module = Constants.version + '/user-svc/'; // user microservice
  public static place_module = 'place-svc/'; // place microservice
  public static rent_module = Constants.version + '/rent-svc/'; // rent microservice
  public static transaction_module = 'transaction-svc/'; // transaction microservice

  public static prodSiteURL = "https://spacest.com"
  public static devSiteURL = "https://beta.roomlessrent.com"

  public static prodAdminPanel = "https://secure.roomlessrent.com"
  public static devAdminPanel = "https://beta-adminpanel-spacest.vercel.app"

  public static GoogleMapsKEY = 'AIzaSyC_5lNMQ9lbNoGxJ_EtKADLD6zPRSu7TZo';

  // ***** SEARCH CONSTANTS *****
  public static defaultRadiusSearch = 20; //km
  public static defaultRadiusSearchDistrict = 2; //km
  public static defaultRadiusSearchPOI = 1; //km
  public static defaultPagingSearch = 60;
  public static minDefaultPriceRange = 0;
  public static maxDefaultPriceRange = 30000;
  public static maxDefaultPriceRangeSale = 30000000;

  public static defaultFollowUpTime = "13:00";


  // ***** CITIES IDS *****
  public static milanoCityID = 1690;

  // ***** PARTNERS IDS *****

  public static ITALIAN_WAY_ID = 10821;
  public static MILANO_STANZE_ID = 13622;

  // ***** PARTNERS NAMES *****
  public static partnerNames = {
    IDEALISTA: 'Idealista',
    IMMOBILIARE: 'Immobiliare',
    IMMOSCOUT: 'Immoscout24',
    IOAFFITTO: 'ioAffitto',
    COLOCS: 'LaCarteDesColocs',
    WGGESUCHT: 'WG-Gesucht',
    GESUCHT2: 'WG-Gesucht (Second account)',
    XPAT: 'XPAT',
    RENTBERRY: 'Rentberry',
    SEARCH_SMARTLY: 'SearchSmartly',
    SOlUZIONE_PORTALI: 'SoluzionePortali',
    SELOGER: 'Seloger',
    ZOOPLA: 'Zoopla',
    WIKICASA: 'Wikicasa',
    NETSPICK: 'Netspick',
    ERASMUSPLAY: 'Erasmusplay',
    PISOS: 'Pisos',
    LIBERO_RUBIRKK: 'Libero Rubrikk',
    RENTOLA: 'Rentola',
    IMMOJEUNE: 'Immojeune',
    IMOVIRTUAL: 'Imovirtual',
    LEBONCOIN: 'Leboncoin',
    IMMOWEB: 'Immoweb',
    IAMEXPAT: 'IamExpat',
    FOTOCASA: 'Fotocasa',
    SMARTGARANT: 'SmartGarant',
    WILLHABEN: 'Willhaben',
    AIRBNB: 'Airbnb',
    GOOGLE: 'Google',
  };

  public static FILTER_LISTING_TYPE = [
    { label: 'Single/Shared room', value: 1 },
    { label: 'Entire property', value: 2 }
  ]

  public static FILTER_ROOM_TYPE = [
    { label: 'Single room', value: 1 },
    { label: 'Shared room', value: 2 }
  ]

  /*   public static FILTER_LANDLORD_LEADS_STATUSES = [
      { label: 'New', value: LeadStatus.NEW },
      { label: 'Interested', value: LeadStatus.INTERESTED },
      { label: 'Not interested', value: LeadStatus.NOT_INTERESTED },
      { label: 'Doubt', value: LeadStatus.DOUBT },
      { label: 'Priority', value: LeadStatus.PRIORITY },
      { label: 'Completed', value: LeadStatus.COMPLETED },
      { label: 'Deleted', value: LeadStatus.DELETED },
      { label: 'Unknown', value: LeadStatus.UKNOWN },
    ] */
  /*
    public static FILTER_SALES_LEADS_STATUSES = [
      { label: 'New', value: SalesLeadStatus.NEW },
      { label: 'Contacted', value: SalesLeadStatus.CONTACTED },
      { label: 'Proposals sent', value: SalesLeadStatus.PROPOSALS_SENT },
      { label: 'Not interested', value: SalesLeadStatus.NOT_INTERESTED },
      { label: 'Visit Organized', value: SalesLeadStatus.VISIT_ORGANIZED },
      { label: 'Request sent', value: SalesLeadStatus.REQUEST_SENT },
      { label: 'Completed', value: SalesLeadStatus.COMPLETED },
      { label: 'Deleted', value: SalesLeadStatus.DELETED },
      { label: 'Unknown', value: LeadStatus.UKNOWN },
    ]
   */



  // ***** USER FUNNEL STATUS CONSTANTS ******
  public static TENANT_FUNNEL_STATUSES = [
    { label: 'New', value: TenantFunnelStatus.NEW, color: '#bdbdbd' },
    { label: 'Contacted', value: TenantFunnelStatus.SALES_CONTACTED, color: 'rgba(33,150,243,0.4)' },
    { label: 'To send options', value: TenantFunnelStatus.SALES_TO_SEND_OPTIONS, color: '#03A9F4' },
    { label: 'Proposals sent', value: TenantFunnelStatus.SALES_PROPOSAL_SENT, color: '#007bff' },
    { label: 'To organize visit', value: TenantFunnelStatus.SALES_READY_TO_ORGANIZE_VISIT, color: '#F3D000' },
    { label: 'Visit Organized', value: TenantFunnelStatus.SALES_VISIT_ORGANIZED, color: '#e68c04' },
    { label: 'Ready to book', value: TenantFunnelStatus.SALES_READY_TO_BOOK, color: '#F3D000' },
    { label: 'Request sent', value: TenantFunnelStatus.SALES_REQUEST_SENT, color: '#cddc39' },
    { label: 'Completed', value: TenantFunnelStatus.COMPLETED, color: '#3eaf61' },
    { label: 'Not interested', value: TenantFunnelStatus.NOT_INTERESTED, color: '#d71032' }
  ]
  // ***** USER FUNNEL STATUS CONSTANTS ******
  public static LEAD_STATUS_MANAGEMENT = [
    { label: 'New', value: LeadManagementStatusEnum.NEW, color: '#bdbdbd' },
    { label: 'Managed', value: LeadManagementStatusEnum.MANAGED, color: 'rgba(33,150,243,0.4)' },
  ]

  // ***** SUPPLY LEAD STATUS CONSTANTS ******
  public static LANDLORD_FUNNEL_STATUSES = [
    { label: 'New', value: LandlordFunnelStatus.NEW, color: '#bdbdbd' },
    { label: 'Contacted', value: LandlordFunnelStatus.CONTACTED, color: 'rgba(33,150,243,0.4)' },
    { label: 'Interested', value: LandlordFunnelStatus.SUPPLY_INTERESTED, color: '#2277ca' },
    { label: 'Not interested', value: LandlordFunnelStatus.NOT_INTERESTED, color: '#d71032' },
    { label: 'Doubt', value: LandlordFunnelStatus.SUPPLY_DOUBT, color: '#ffe029' },
    { label: 'Priority', value: LandlordFunnelStatus.SUPPLY_PRIORITY, color: '#007bff' },
    { label: 'Completed', value: LandlordFunnelStatus.COMPLETED, color: 'mediumseagreen' },
    { label: 'Deleted', value: LandlordFunnelStatus.SUPPLY_DELETED, color: '#484848' }
  ]



  /*   // ***** SALES LEAD STATUS CONSTANTS ******
    public static SALES_STATUSES = [
      { label: 'New', value: SalesLeadStatus.NEW, color: '#bdbdbd' },
      { label: 'Contacted', value: SalesLeadStatus.CONTACTED, color: 'rgba(33,150,243,0.4)' },
      { label: 'Proposals sent', value: SalesLeadStatus.PROPOSALS_SENT, color: '#007bff' },
      { label: 'Not interested', value: SalesLeadStatus.NOT_INTERESTED, color: '#d71032' },
      { label: 'Visit Organized', value: SalesLeadStatus.VISIT_ORGANIZED, color: '#ffe029' },
      { label: 'Request sent', value: SalesLeadStatus.REQUEST_SENT, color: '#cddc39' },
      { label: 'Completed', value: SalesLeadStatus.COMPLETED, color: '#3eaf61' },
      { label: 'Deleted', value: SalesLeadStatus.DELETED, color: '#484848' }
    ] */

  // ***** SUPPLY LEAD STATUS CONSTANTS ******
  /*   public static SUPPLY_STATUSES = [
      { label: 'New', value: LeadStatus.NEW, color: '#bdbdbd' },
      { label: 'Interested', value: LeadStatus.INTERESTED, color: 'rgba(33,150,243,0.4)' },
      { label: 'Not interested', value: LeadStatus.NOT_INTERESTED, color: '#d71032' },
      { label: 'Doubt', value: LeadStatus.DOUBT, color: '#ffe029' },
      { label: 'Priority', value: LeadStatus.PRIORITY, color: '#007bff' },
      { label: 'Completed', value: LeadStatus.COMPLETED, color: 'mediumseagreen' },
      { label: 'Deleted', value: LeadStatus.DELETED, color: '#484848' }
    ]
   */

  public static USER_QUALITY_STATUSES = [
    { label: 'Bad client 👎🏻', value: 0, color: '#E16970' },
    { label: 'Normal client 👍🏻', value: 1, color: '#FFBB36' },
    { label: 'Very good client 🤩', value: 2, color: '#08C2A0' },
  ]


  // ***** SUPPLY LEAD SOURCE CONSTANTS ******
  public static SUPPLY_LEAD_SOURCE: SelectItem[] = [
    { label: 'Roomless', value: 0 },
    { label: 'Immobiliare', value: 1 },
    { label: 'Idealista IT', value: 2 },
    { label: 'Idealista ES', value: 3 },
    { label: 'Idealista PT', value: 4 },
    { label: 'Facebook', value: 5 },
    { label: 'WG Gesucht', value: 6 },
    { label: 'Subito', value: 7 },
    { label: 'ImmoScout24', value: 8 },
    { label: 'Immojeune', value: 9 },
    { label: 'Pisos.com', value: 10 },
    { label: 'Io Affitto', value: 11 },
    { label: 'Lead creata per messaggio ricevuto da callbell', value: 12 },
    { label: 'Roomless (help)', value: 13 },
    { label: 'Se Loger', value: 14 },
    { label: 'Imovirtual', value: 15 },
    { label: 'Amber', value: 16 }
  ]

  public static SOURCE_MESSAGE: { lang: string, label: string, }[] = [
    { lang: 'it', label: ' che hai visto su ', },
    { lang: 'en', label: ' you have seen on ', },
    { lang: 'de', label: ' sie sahen am ', },
    { lang: 'es', label: ' que viste en ', },
    { lang: 'fr', label: ' que vous avez vu sur ', },
    { lang: 'pt', label: ' que viu no ', },
  ]

  public static LEAD_SOURCE = {
    SALES_DASHBOARD: 0,
    SUPPLY_DASHBOARD: 1
  }


  // ***** USER CONSTANTS ******
  public static ADMIN_ID = 1;
  public static TENANT_ID = 2;
  public static OWNER_ID = 3;
  public static CAMPUS_ID = 4;

  // ***** OCCUPATIONS CONSTANTS ******
  public static STUDENT_OCCUPATION = 1;
  public static WORKER_OCCUPATION = 2;

  public static STATUS_USER_LIST: SelectItem[] = [
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' },
  ];

  // ***** LISTING CONSTANTS ******

  public static STATUS_OFFLINE = 1;
  public static STATUS_PUBLISHED = 2;
  public static STATUS_EXPIRED = 3;
  public static STATUS_DELETED = 4;
  public static STATUS_PENDING = 5;
  public static STATUS_REJECTED = 6;
  public static STATUS_DRAFT = 7; //Not eligible to go online

  public static RENT_LISTING_TYPE = 0;
  public static SALE_LISTING_TYPE = 1;

  public static ACCOMODATION_LISTING_TYPE = {
    id: 1,
    name: 'Stanza',
    plural_name: 'Stanze',
    icon: 'fas fa-bed',
    img_cover:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEIe0M4pXKOsakPIVpJN-agKbER4BOkFddXQlZme4XLKAB0Nab',
  };

  public static ESTATE_LISTING_TYPE = {
    id: 2,
    name: 'Casa',
    plural_name: 'Case',
    icon: 'fas fa-home',
    img_cover: 'http://www.inthemoodfordesign.eu/wordpress/wp-content/uploads/2016/05/5399.5.jpg',
  };

  public static RESIDENCE_LISTING_TYPE = {
    id: 3,
    name: 'Residenza',
    plural_name: 'Residenze',
    icon: 'fas fa-building',
    img_cover:
      'https://roomlessrent.com/wp-content/uploads/job-manager-uploads/job_gallery/2019/06/20190419-768x512.jpg',
  };

  public static SINGLE_ROOM_TYPE = {
    id: 1,
    name: 'Stanza completa',
    plural_name: 'Stanze complete',
  };

  public static SHARED_ROOM_TYPE = {
    id: 2,
    name: 'Posto letto',
    plural_name: 'Stanze condivise',
  };

  public static UTILITIES_WATER_ID = 1;
  public static UTILITIES_ELECTRICITY_ID = 2;
  public static UTILITIES_GAS_ID = 3;
  public static UTILITIES_INTERNET_ID = 4;

  public static ACCOMODATION_IN_HOUSE = 1;
  public static ACCOMODATION_IN_CAMPUS = 2;

  public static BED_SINGLE_ID = 1;
  public static BED_BUNK_ID = 2;
  public static BED_QUEEN_ID = 3;
  public static BED_KING_ID = 4;

  public static GENDER_MALE_ID = 1;
  public static GENDER_FEMALE_ID = 2;
  public static GENDER_BOTH_ID = 3;

  public static APARTMENT_HOUSE_ID = 1;
  public static INDEPENDENT_HOUSE_ID = 2;

  // ****** STRUCTURE CONSTANTS ******

  public static STRUCTURE_HOUSE = 1;
  public static STRUCTURE_CAMPUS = 2;


  // ****** SALES CONSTANTS ******

  public static SALE_TYPE_MONO = 1;
  public static SALE_TYPE_BILO = 2;
  public static SALE_TYPE_TRILO = 3;
  public static SALE_TYPE_QUADRI = 4;
  public static SALE_TYPE_APT = 5;
  public static SALE_TYPE_ATTICO = 6;
  public static SALE_TYPE_SCHIERA = 7;
  public static SALE_TYPE_BIFAM = 8;
  public static SALE_TYPE_INDIP = 9;
  public static SALE_TYPE_VILLA = 10;


  public static GARDEN_PRIVATE_ID = 0;
  public static GARDEN_SHARED_ID = 1;
  public static GARDEN_NONE_ID = 2;

  public static HEATING_AUTONOMOUS_ID = 1;
  public static HEATING_CENTRALIZED_ID = 2;
  public static HEATING_NONE_ID = 3;

  public static listingSaleEnergyClassOptions: SelectItem[] = [
    { value: 1, label: 'A4' },
    { value: 2, label: 'A3' },
    { value: 3, label: 'A2' },
    { value: 4, label: 'A1' },
    { value: 5, label: 'A+' },
    { value: 6, label: 'A' },
    { value: 7, label: 'B' },
    { value: 8, label: 'C' },
    { value: 9, label: 'D' },
    { value: 10, label: 'F' },
    { value: 11, label: 'G' },
    { value: 12, label: 'CERTIFICATION_PENDING' },
    { value: 13, label: 'CERTIFICATION_EXEMPT' },
    { value: 14, label: 'CERTIFICATION_NOT_CLASSIFIABLE' },
  ];

  public static listingSalePropCondOptions: SelectItem[] = [
    { value: 1, label: 'PROP_COND_NEW' },
    { value: 2, label: 'PROP_COND_EXCELLENT' },
    { value: 3, label: 'PROP_COND_GOOD' },
    { value: 4, label: 'PROP_COND_RENOVATED' }
  ];


  // ****** VISIT CONSTANTS ******

  public static VISIT_TYPE_LOCO = 1;
  public static VISIT_TYPE_VIRTUAL = 2;

  public static VISIT_STATUS_INACTIVE = 0;
  public static VISIT_STATUS_ACTIVE = 1;

  public static SLOT_STATUS_CANCELLED = -1;
  public static SLOT_STATUS_FREE = 0;
  public static SLOT_STATUS_BOOKED = 1;
  public static SLOT_STATUS_CONFIRMED = 2;
  public static SLOT_STATUS_EXPIRED = 3;
  public static SLOT_STATUS_DELETED = 4;
  public static SLOT_STATUS_REFUND = 5;
  public static SLOT_STATUS_PROPOSED = 6;
  public static SLOT_STATUS_REQUESTED = 7;
  public static SLOT_STATUS_REJECTED = 8;
  public static SLOT_STATUS_ACCEPTED = 9;

  // ****** RENT CONSTANTS ******

  /**
   * Cancelled by the user before the owner approval
   */
  public static RENT_STATUS_CANCELLED = -1;
  /*
  * Not instant booking, pending owner approval
   */
  public static RENT_STATUS_REQUESTED = 0;
  /*
  * Accepted by the owner
  * */
  public static RENT_STATUS_ACCEPTED = 1;
  /*
  * Rejected by the owner
  * */
  public static RENT_STATUS_REJECTED = 2;
  /*
  * Set when a refund is approved
  * */
  public static RENT_STATUS_REFUND = 3;
  /*
  * Completed, approved and paid (both instant booking and not IB)
  * */
  public static RENT_STATUS_PAYED_BY_OCCUPANT = 4;  // Pagata dal richiedente
  /*
  * Set when Instant Booking is not confirmed after 48/72 hours
  * */
  public static RENT_STATUS_IS_EXPIRED = 8;
  /*
  * Instant booking lead (user arrived on payment form but has not completed the payment)
  * */
  public static RENT_STATUS_INST_BOOKING_LEAD = 9;
  /*
  * Instant booking, funds blocked on tenant's payment method and waiting for owner approval
  * */
  public static RENT_STATUS_INST_BOOKING_TO_BE_CONFIRMED = 10;
  /*
  * Set when a refund request is requested for the rent request and its waiting for approval
   */
  public static TO_BE_REFUNDED = 11;
  /*
  * Alternative proposals (dates/listings) to be accepted
  * */
  public static WAITING_TENANT_ACTION = 12;

  // Additional landlord services

  public static LANDLORD_SERVICES_REDACTION_COSTS = 1;
  public static LANDLORD_SERVICES_REGISTRATION_COSTS = 2;
  public static LANDLORD_SERVICES_REGISTRATION_FEES = 3;
  public static LANDLORD_SERVICES_OTHER = 4;

  // ****** TRANSACTION CONSTANTS ******

  public static TRANSACTION_CREDIT = 1;
  public static TRANSACTION_REFUND = 2;
  public static TRANSACTION_BOOK_VISIT = 3;


  // ****** BANK TRANSFER CONSTANTS ******

  public static BANK_TRANSFER_NOT_NEEDED = 0;
  public static BANK_TRANSFER_WAITING = 1;
  public static BANK_TRANSFER_SENT = 2;

  // ****** DEPOSIT STATUS CONSTANTS ******

  public static DEPOSIT_NOT_NEEDED = 0;
  public static DEPOSIT_PENDING_PAYMENT = 1;
  public static DEPOSIT_PAID = 2;

  // ****** TRANSATIONS CONSTANTS ******
  public static PAYPAL_SOURCE = 1;
  public static BANK_TRANSF_SOURCE = 2;
  public static STRIPE_SOURCE = 3;

  // ****** GEO CONSTANTS ******

  public static GLOBAL_ID = -1
  public static ITALY_ID = 1;

  // ****** PLACES CONSTANTS *****
  public static PLACES_TYPES: SelectItem[] = [
    { value: 11, code: 'citta', label: 'Città', icon: 'fal fa-city' },
    { value: 8, code: 'chiesa', label: 'Chiesa', icon: 'fal fa-church' },
    { value: 10, code: 'consulting', label: 'Consulting', icon: 'fal fa-briefcase' },
    { value: 9, code: 'football', label: 'Stadio', icon: 'far fa-futbol' },
    { value: 5, code: 'hospital', label: 'Ospedale', icon: 'fal fa-hospital-symbol' },
    { value: 2, code: 'industry', label: 'Industria', icon: 'fal fa-industry-alt' },
    { value: 3, code: 'monument', label: 'Monumento', icon: 'fal fa-archway' },
    { value: 7, code: 'museum', label: 'Museo', icon: 'fal fa-paint-brush-alt' },
    { value: 6, code: 'restaurant', label: 'Ristorante', icon: 'fal fa-utensils-alt' },
    { value: 1, code: 'station', label: 'Stazione', icon: 'fal fa-subway' },
    { value: 4, code: 'uni', label: 'Università', icon: 'fal fa-university' },
  ];

  public static LISTING_STATUS = [
    { label: 'Offline', value: ListingStatusEnum.HIDDEN, color: '#D9D9D9' },
    { label: 'Public', value: ListingStatusEnum.PUBLIC, color: '#3FAF61' },
    { label: 'Expired', value: ListingStatusEnum.EXPIRED, color: '#AED5FB' },
    { label: 'Deleted', value: ListingStatusEnum.DELETED, color: '#D71032' },
    { label: 'Under Approval', value: ListingStatusEnum.UNDER_APPROVAL, color: '#FFE029' },
    { label: 'Rejected', value: ListingStatusEnum.REJECTED, color: '#000000' },
    { label: 'Draft', value: ListingStatusEnum.DRAFT, color: '#08C2A082' }
  ]

  public static PLACES_STATUS_ACTIVE = 1;
  public static PLACES_STATUS_INACTIVE = 0;

  // ****** SORT CONSTANTS ******

  public static PUBLISHED_DATE = 'PUBLISHED_DATE';
  public static CREATED_DATE = 'CREATED_DATE';
  public static PRICE = 'PRICE';
  public static NAME = 'NAME';
  public static DISTANCE = 'DISTANCE';
  public static RELEVANCE = 'RELEVANCE';

  // ****** OTHERS ******

  public static ToastOptions = { progressBar: true, extendedTimeOut: 5000, opacity: 1 };
  public static ToastLongerOptions = { progressBar: true, timeOut: 10000, extendedTimeOut: 10000, opacity: 1 };

  // ****** ROOM CONSTANTS ******

  public static ROOMS_TYPES: SelectItem[] = [
    { value: 1, label: 'Foto principale' },
    { value: 2, label: 'Camera' },
    { value: 3, label: 'Cucina' },
    { value: 4, label: 'Bagno' },
    { value: 5, label: 'Salotto' },
    { value: 6, label: 'Esterno' },
    { value: 7, label: 'Altro' },
  ];


  // ****** STRIPE CONSTANTS ******

  public static LAND_FEE_STATUS_TO_BE_DEDUCED = 1;
  public static LAND_FEE_STATUS_DEDUCED = 2;
  public static LAND_FEE_STATUS_TO_BE_PAID = 3;
  public static LAND_FEE_STATUS_PAID = 4;
  public static LAND_FEE_STATUS_REFUNDED = 5;
  public static LAND_FEE_STATUS_DEDUCED_REFUNDED = 6;
  public static LAND_FEE_STATUS_PENDING_PAYMENT = 7;


  // ****** CALENDAR ******

  public static myDatePickerOptionsIT: IMyOptions = {
    dayLabels: { su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mer', th: 'Gio', fr: 'Ven', sa: 'Sab' },
    dayLabelsFull: {
      su: 'Domenica',
      mo: 'Lunedì',
      tu: 'Martedì',
      we: 'Mercoledì',
      th: 'Giovedì',
      fr: 'Venerdì',
      sa: 'Sabato',
    },
    monthLabels: {
      1: 'Gen',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'Mag',
      6: 'Giu',
      7: 'Lug',
      8: 'Ago',
      9: 'Set',
      10: 'Ott',
      11: 'Nov',
      12: 'Dic',
    },
    monthLabelsFull: {
      1: 'Gennaio',
      2: 'Febbraio',
      3: 'Marzo',
      4: 'Aprile',
      5: 'Maggio',
      6: 'Giugno',
      7: 'Luglio',
      8: 'Agosto',
      9: 'Settembre',
      10: 'Ottobre',
      11: 'Novembre',
      12: 'Dicembre',
    },
    todayBtnTxt: 'Oggi',
    clearBtnTxt: 'Cancella',
    closeBtnTxt: 'Chiudi',
    dateFormat: 'dd-mm-yyyy',
    firstDayOfWeek: 'mo',
    minYear: 2021,
    maxYear: 2025,
    showTodayBtn: false,
    showClearDateBtn: true,
    closeAfterSelect: true,
    editableDateField: false,
    inputAutoFill: false,
    disableDateRanges: [],
  };

  public static myDatePickerOptionsEN: IMyOptions = {
    dayLabels: { su: 'Sun', mo: 'Mon', tu: 'Tue', we: 'Wed', th: 'Thu', fr: 'Fri', sa: 'Sat' },
    dayLabelsFull: {
      su: 'Sunday',
      mo: 'Monday',
      tu: 'Tuesday',
      we: 'Wednesday',
      th: 'Thursday',
      fr: 'Friday',
      sa: 'Saturday',
    },
    monthLabels: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    },
    monthLabelsFull: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    },
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    dateFormat: 'dd-mm-yyyy',
    firstDayOfWeek: 'su',
    minYear: 2021,
    maxYear: 2025,
    showTodayBtn: false,
    showClearDateBtn: true,
    closeAfterSelect: true,
    editableDateField: false,
    inputAutoFill: false,
    disableDateRanges: [],
  };

  public static myDatePickerOptionsDE: IMyOptions = {
    dayLabels: { su: 'Sonn', mo: 'Mon', tu: 'Dien', we: 'Mitt', th: 'Donn', fr: 'Frei', sa: 'Sam' },
    dayLabelsFull: {
      su: 'Sonntag',
      mo: 'Montag',
      tu: 'Dienstag',
      we: 'Mittwoch',
      th: 'Donnerstag',
      fr: 'Freitag',
      sa: 'Samstag',
    },
    monthLabels: {
      1: 'Januar',
      2: 'Februar',
      3: 'März',
      4: 'April',
      5: 'Mai',
      6: 'Juni',
      7: 'Juli',
      8: 'August',
      9: 'September',
      10: 'Oktober',
      11: 'November',
      12: 'Dezember',
    },
    monthLabelsFull: {
      1: 'Januar',
      2: 'Februar',
      3: 'März',
      4: 'April',
      5: 'Mai',
      6: 'Juni',
      7: 'Juli',
      8: 'August',
      9: 'September',
      10: 'Oktober',
      11: 'November',
      12: 'Dezember',
    },
    todayBtnTxt: 'Heute',
    clearBtnTxt: 'Löschen',
    closeBtnTxt: 'Schließen',
    dateFormat: 'dd-mm-yyyy',
    firstDayOfWeek: 'mo',
    minYear: 2021,
    maxYear: 2025,
    showTodayBtn: false,
    showClearDateBtn: true,
    closeAfterSelect: true,
    editableDateField: false,
    inputAutoFill: false,
    disableDateRanges: [],
  };

  public static myDatePickerOptionsES: IMyOptions = {
    dayLabels: { su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mié', th: 'Jue', fr: 'Vie', sa: 'Sáb' },
    dayLabelsFull: {
      su: 'Domingo',
      mo: 'Lunes',
      tu: 'Martes',
      we: 'Miércoles',
      th: 'Jueves',
      fr: 'Viernes',
      sa: 'Sábado',
    },
    monthLabels: {
      1: "Enero",
      2: "Febrero",
      3: "Marzo",
      4: "Abril",
      5: "Mayo",
      6: "Junio",
      7: "Julio",
      8: "Agosto",
      9: "Septiembre",
      10: "Octubre",
      11: "Noviembre",
      12: "Diciembre"
    },
    monthLabelsFull: {
      1: "Enero",
      2: "Febrero",
      3: "Marzo",
      4: "Abril",
      5: "Mayo",
      6: "Junio",
      7: "Julio",
      8: "Agosto",
      9: "Septiembre",
      10: "Octubre",
      11: "Noviembre",
      12: "Diciembre"
    },
    todayBtnTxt: 'Hoy',
    clearBtnTxt: 'Borrar',
    closeBtnTxt: 'Cerrar',
    dateFormat: 'dd-mm-yyyy',
    firstDayOfWeek: 'su',
    minYear: 2021,
    maxYear: 2025,
    showTodayBtn: false,
    showClearDateBtn: true,
    closeAfterSelect: true,
    editableDateField: false,
    inputAutoFill: false,
    disableDateRanges: [],
  };


  // ****** UTILITIES FUNCTIONS ******

  public static getPlaceIconByType(type) {
    const res = Constants.PLACES_TYPES.find((el) => el.value === type);
    if (res) {
      return res.icon;
    }
  }

  public static getServicesIcons(id: number): string {
    switch (id) {
      case 1: // Wifi
        return 'fad fa-wifi-2';

      case 2: // Posto auto
        return 'far fa-cars';

      case 3: // Posto bici
        return 'fal fa-bicycle';

      case 4: // Garage
        return 'fal fa-warehouse';

      case 5: // Riscaldamento autonomo
        return 'fas fa-calculator';

      case 6: // Scrivania
        return 'fal fa-chair-office';

      case 7: // Armadio
        return 'far fa-cabinet-filing';

      case 8: // Lavatrice
        return 'fad fa-washer';

      case 9: // Doccia
        return 'fal fa-shower';

      case 10: // Giardino
        return 'fad fa-tree-alt';

      case 11: // Terrazzo
        return 'fal fa-container-storage';

      case 12: // Portone blindato
        return 'fal fa-door-closed';

      case 13: // Sala TV/Salotto
        return 'fal fa-tv';

      case 14: // Arredato
        return 'fas fa-chair';

      case 15: // Cucina
        return 'far fa-utensils-alt';

      case 16: // Ascensore
        return 'fad fa-sort-circle-up';

      case 17: // Pavimento riscaldato
        return 'fal fa-fireplace';

      case 18: // Lavastoviglie
        return 'far fa-vacuum-robot';

      case 19: // Allarme
        return 'fal fa-bell-exclamation';

      case 20: // Forno
        return 'fas fa-oven';

      case 21: // Congelatore
        return 'fal fa-snowflake';

      case 22: // Piano cottura
        return 'fal fa-dice-four';

      case 23: // Frigorifero
        return 'fas fa-refrigerator';

      case 24: // Climatizzatore
        return 'fad fa-wind';

      case 25: // Televisore
        return 'fal fa-tv';

      case 26: // Bagno condiviso
        return 'fal fa-restroom';

      case 27: // Bagno in camera
        return 'fal fa-toilet';

      case 28: // Riscaldamento centralizzato
        return 'fal fa-thermometer-half';

      case 29: // Ampie finestre
        return 'fal fa-solar-panel';

      case 30: // Utensili da cucina e pentole
        return 'fal fa-utensils';

      case 31: // Vasca
        return 'fal fa-bath';

      case 32: // Biancheria letto
        return 'fal fa-blanket';

      case 33: // Ospiti ammessi
        return 'fal fa-user-friends';

      case 34: // Mensa
        return 'fal fa-steak';

      default:
        return 'fal fa-thumbs-up';
    }
  }

  public static convertCurrencyToIcon = (currency?: CurrencyObject) => {
    let symbol: string;

    switch (currency.code) {
      case 'EUR':
        symbol = 'fal fa-euro-sign';
        break;
      case 'GBP':
        symbol = 'fal fa-pound-sign';
        break;
      case 'USD':
        symbol = 'fal fa-dollar-sign';
        break;
      case 'PLN':
        symbol = 'fal fa-coins'
        break;
      case 'CHF':
        symbol = 'fal fa-coins'
        break;
      case 'NOK':
        symbol = 'fal fa-coins'
        break;
      default:
        symbol = 'fal fa-coins'
    }

    return symbol;
  }

  public static isVoidArray(array: any[]): boolean {
    if (array === undefined ||
      array.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  public static cloneObj(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  public static dovevivoId = 14832

  public static computeDiscountedPrice(listing: Listing, owner_id: number, num_months: number): number {
    if (listing) {
      let price = listing.price;
      let city: string = '';
      if (listing.address && listing.address.addressCity)
        city = listing.address.addressCity;
      else if (listing.structure && listing.structure.address && listing.structure.address.addressCity)
        city = listing.structure.address.addressCity

      //Sconto 12 mesi per Dovevivo
      /* if (owner_id == Constants.dovevivoId && (
         (!city.includes("Torino") && !city.includes("Turin")) &&
         (!city.includes("Milano")) && (!city.includes("Milan")) &&
         (!city.includes("Trento"))
       )){
         if (num_months >= 12)
           return Math.round(price / 1.1)
         else
           return price;
       } else {
         return price;
       }*/

      return price;
    }
  }


}
