import { Injectable, Inject } from '@angular/core';
import { Constants } from '../Constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalFields } from '../GlobalFields';
import { Profile } from '../entities/profile';

import { AuthInfo, RoomlessHttpResponse } from '../entities/RoomlessHttpResponse';
import { Service } from './Service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { Subject } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private http: HttpClient,
    private service: Service,
    private router: Router
  ) {
    //  AuthenticationService.nativeStorage = nativeStorage;
  }
  // contiene il token, deve essere sempre aggiornato quando vengono fatte modifiche al token
  static actualToken: string;
  public isLoggedInObservable = new Subject<boolean>();

  authGuard = false;
  GlobalFields = GlobalFields;

  // static nativeStorage: NativeStorage;

  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
  });

  // indica se l'utente ha effettuato il login per disattivare il routing
  // è pesante come chiamata, nell'html utilizzare GlobalSettings.CurrentProfile, se è undefined vuol dire sloggato
  static isLoggedIn() {
    if (!AuthenticationService.actualToken) {
      return false;
    }
    const jwtHelper = new JwtHelperService();
    return !jwtHelper.isTokenExpired(AuthenticationService.actualToken);
  }

  registration(userData: Profile) {
    const serverURL = !GlobalFields.isTestEnvironment ? Constants.serverURL : Constants.testServerURL
    const url = serverURL + Constants.user_module + 'user/register';
    return this.http.post<RoomlessHttpResponse>(url, userData, { headers: this.headers });
  }

  login(username: string, password: string) {
    const serverURL = !GlobalFields.isTestEnvironment ? Constants.serverURL : Constants.testServerURL
    const url = serverURL + Constants.user_module + 'auth/signin';
    return this.http.post<RoomlessHttpResponse>(
      url,
      { username: username, password: password },
      { headers: this.headers }
    );
  }

  externalSignIn(arr: any) {
    const serverURL = !GlobalFields.isTestEnvironment ? Constants.serverURL : Constants.testServerURL
    const url = serverURL +
      Constants.rest_prefix + Constants.user_module +
      'auth/external_signin';
    return this.http.post<RoomlessHttpResponse>(url, arr, { headers: this.headers });
  }

  forgotPass(email: string) {
    const serverURL = !GlobalFields.isTestEnvironment ? Constants.serverURL : Constants.testServerURL
    const url = serverURL + Constants.user_module +
      'auth/password-recovery';
    return this.http.post<RoomlessHttpResponse>(url, { email: email }, { headers: this.headers });
  }

  storeToken(authInfo: AuthInfo) {
    if (GlobalFields.isPlatformBrowser()) {
      this.localStorage.setItem('authRoomless', JSON.stringify(authInfo));
      const token = this.getToken();
      if (token) {
        this.setActualToken(token);
        this.service.getUserInfo().subscribe(
          (result) => {
            if (result.success) {
              GlobalFields.currentUserInfo = result.data;
              // this.GlobalFields.getNotifications(this.service);
              this.emitIsLoggedIn(true);
            } else {
              console.log(result.errorMessage);
              this.emitIsLoggedIn(false);
            }
            GlobalFields.dataIsReady = true;
          },
          (err) => {
            console.error(err.message);
            GlobalFields.dataIsReady = true;
            this.emitIsLoggedIn(false);
          }
        );
      }
    }
  }

  getToken(): string | undefined {
    const authInfo = JSON.parse(localStorage.getItem('authRoomless')) as AuthInfo;
    return authInfo?.token;
  }

  // utilizzato per settare l'actual token se non puoi accedere allo static
  setActualToken(token) {
    AuthenticationService.actualToken = token;
  }

  public logout() {
    GlobalFields.loadingSoft = true;
    GlobalFields.showMenu = false;

    setTimeout(() => {
      GlobalFields.loadingSoft = false;
      this.router.navigate(['/login']);
    }, 1000);

    this.removeToken()
  }

  public removeToken() {
    this.localStorage.removeItem('authRoomless');
    AuthenticationService.actualToken = undefined;
    GlobalFields.currentUserInfo = undefined;
    this.emitIsLoggedIn(false);
  }

  emitIsLoggedIn(status: boolean) {
    this.isLoggedInObservable.next(status);
  }

  //return the URL
  downloadPdfFromHtml(fileName: string, htmlContent: string) {
    const serverURL = !GlobalFields.isTestEnvironment ? Constants.serverURL : Constants.testServerURL
    const url = serverURL + Constants.server_suffix1 + 'stream/generate-pdf';

    const body = {
      fileName: fileName,
      htmlContent: htmlContent
    };

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + this.getToken(),
        'withCredentials': 'true'
      });


    return this.service.httpIgnoreInterceptor.post<RoomlessHttpResponse>(url, body, {headers: headers});
  }
}
