import { Component, Inject, OnInit, PLATFORM_ID, RendererFactory2 } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { GlobalFields } from './GlobalFields';
import { Constants } from './Constants';
import { Service } from './services/Service';
import { AuthenticationService } from './services/AuthenticationService';
import { DOCUMENT, Location, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';
import { PathConstants } from './PathConstants';
import { AdminTypeEnum } from './entities/permissions/permissions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  GlobalFields = GlobalFields;
  Constants = Constants;
  PathConstants = PathConstants;
  isKeyboardHide = true;

  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    @Inject(WINDOW) public window: Window,
    @Inject(DOCUMENT) public document: Document,
    @Inject(Location) public location: Location,
    private platform: Platform,
    private router: Router,
    public service: Service,
    private rendererFactory: RendererFactory2,
    private authService: AuthenticationService,
    private title: Title,
    private meta: Meta,
    private navController: NavController
  ) {
    // Angular universal
    GlobalFields.window = this.window;
    GlobalFields.document = document;
    GlobalFields.location = location;
    GlobalFields.platformId = platformId;
    GlobalFields.url = this.getHostname();
    GlobalFields.rendererFactory = this.rendererFactory;
    GlobalFields.title = this.title;
    GlobalFields.meta = this.meta;


    if (!Constants.UNDER_CONSTRUCTION) {

      // TODO: spostare in cambio lingua
      registerLocaleData(localeIt);

      GlobalFields.loading = true;

      if (GlobalFields.isPlatformBrowser()) {
        GlobalFields.isBrowser = true;
        const token = authService.getToken();

        if (token) {
          this.authService.setActualToken(token);
          this.service.getUserInfo().subscribe(
            (result) => {
              if (result.success) {
                GlobalFields.currentUserInfo = result.data;
                this.authService.emitIsLoggedIn(true);
              } else {
                this.authService.removeToken();
              }
            },
            (err) => {
              if(err && err.status != 429) //If is too many req not remove the token
                this.authService.removeToken();
              GlobalFields.loading = false;
            }
          );
        } else {
          this.authService.emitIsLoggedIn(false);
          GlobalFields.loading = false;
        }
        /*      Keyboard.onKeyboardShow().subscribe(() => {
               this.isKeyboardHide = false;
             }); */

        /*    Keyboard.onKeyboardWillHide().subscribe(() => {
             this.isKeyboardHide = true;
           }); */

        //Getting tracking
        this.service.getTrackingQueryParam()

      } else {
        GlobalFields.isBrowser = false;
        this.authService.emitIsLoggedIn(false);
      }
    }
  }

  ngOnInit() {

    if (!Constants.UNDER_CONSTRUCTION) {

      if (GlobalFields.isPlatformBrowser()) {
        this.platform.ready().then(() => {
          console.log(this.platform.platforms());
          if (this.platform.is('mobile')) {
            this.GlobalFields.isMobile = true;
          } else {
            this.GlobalFields.isMobile = false;
          }
          // is tablet or not
          if (this.platform.is('tablet') && !this.platform.is('desktop')) {
            this.GlobalFields.isTablet = true;
          } else {
            this.GlobalFields.isTablet = false;
          }

          this.service.setServerURl();

          this.GlobalFields.init(this.service);
        });
      } else {
        // If server set some defaults
        this.GlobalFields.isMobile = true;
        this.isKeyboardHide = true;
        this.GlobalFields.dataIsReady = true;
      }

    }

  }

  public getHostname(): string {
    return this.window.location.hostname;
  }

  changeRoute(parentRoute: PathConstants) {
    let homeRoute: PathConstants;

    switch (parentRoute) {
      case PathConstants.insightsDashboard:
        homeRoute = this._insightsDashboardRouteByUser();
        break;
      default: homeRoute = PathConstants.salesInsights
    }

    this.router.navigate(['/', parentRoute, homeRoute]);
  }

  _insightsDashboardRouteByUser() {
    if (GlobalFields.isSuperAdmin()) {
      return PathConstants.salesInsights;
    }
    if (GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.HO_SALE ||
      GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.SALE) {
      return PathConstants.salesInsights;
    }
    if (GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.HO_SUPPLY ||
      GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.SUPPLY) {
      return PathConstants.supplyInsights;
    }
    return PathConstants.salesInsights;
  }

  openSidebar() {
    this.service.openSidebar();
  }

  closeSidebar() {
    this.service.closeSidebar();
  }

  openLink(url: string) {
    this.navController.navigateForward(url);
  }
}
