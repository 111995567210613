export class GoogleReviews{
  public static reviews: any[] = [
    {
      "@context": "http://schema.org/",
      "@type": "Organization",
      "name": "Roomless",
      "datePublished": "2021-03-10",
      "review": {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Nicola B."
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        },
        "reviewBody": "Ho prenotato un appartamento a Milano senza effettuare neanche una visita. Il team di Roomless mi ha supportato in ogni fase. Consiglio assolutamente il servizio di questi ragazzi"
      }
    },{
      "@context": "http://schema.org/",
      "@type": "Organization",
      "name": "Roomless",
      "datePublished": "2021-04-08",
      "review": {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Oleg T."
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        },
        "reviewBody": "Sito web facile da usare con ottimi appartamenti, servizio personale fantastico e reattivo. La spese di agenzia sono da tenere presente al momento della prenotazione degli appartamenti."
      }
    },{
      "@context": "http://schema.org/",
      "@type": "Organization",
      "name": "Roomless",
      "datePublished": "2021-04-08",
      "review": {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Elia F."
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        },
        "reviewBody": "Mi sono affidato a roomless su Milano e mi sono trovato in maniera eccezionale! I ragazzi sono efficienti e mi hanno trovato una sistemazione in breve tempo. Straconsigliato!"
      }
    },{
      "@context": "http://schema.org/",
      "@type": "Organization",
      "name": "Roomless",
      "datePublished": "2021-04-08",
      "review": {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Elia F."
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        },
        "reviewBody": "Ho prenotato un appartamento direttamente online, senza visita: l’annuncio corrispondeva perfettamente alla realtà. Team super professionale e disponibile 24/24. Esperienza incredibile, consiglio a tutti!"
      }
    },{
      "@context": "http://schema.org/",
      "@type": "Organization",
      "name": "Roomless",
      "datePublished": "2021-04-10",
      "review": {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Camilla C."
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        },
        "reviewBody": "Ho prenotato un appartamento direttamente online, senza visita: l’annuncio corrispondeva perfettamente alla realtà. Team super professionale e disponibile 24/24. Esperienza incredibile, consiglio a tutti!"
      }
    },{
      "@context": "http://schema.org/",
      "@type": "Organization",
      "name": "Roomless",
      "datePublished": "2021-04-10",
      "review": {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Giacomo F."
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        },
        "reviewBody": "Mi sono trovato davvero bene! Servizio comodo, veloce e soprattutto affidabile! Ultimo aspetto, ma forse quello più importante, ho trovato una offerta super vantaggiosa"
      }
    }
  ]
}















