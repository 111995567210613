<ion-app>
  <!----------- SCRIPTS ---------->

  <!-- Google Tag Manager (noscript) -->
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WVNHR59" height="0" width="0"
      style="display: none; visibility: hidden;"></iframe></noscript>
  <app-under-construction *ngIf="Constants.UNDER_CONSTRUCTION; else loadApp" />
  <ng-template #loadApp>
    <ng-container *ngIf="!GlobalFields.dataIsReady || GlobalFields.loadingSoft" class="cards-bg social-cards"
      style="z-index: 99999; ">
      <loading *ngIf="!GlobalFields.dataIsReady" [softLoading]="false"></loading>
      <loading *ngIf="GlobalFields.loadingSoft" [softLoading]="true"></loading>
    </ng-container>

    <ion-header *ngIf="GlobalFields.showMenu" class="hideDesktop"
      style="box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px; background-color: white; height: 62px; padding-top: 3px;">
      <ion-toolbar>
        <div style="width: 100%; text-align: center; padding: 0;">
          <button (click)='changeRoute(PathConstants.insightsDashboard)'
            style="background-color: transparent; margin-left: 8px; margin-bottom: 5px; float: left; cursor: pointer;">
            <img src="./assets/imgs/logo/logo_roomless_for_spacest.png" style="width: 110px" alt="Roomless">
          </button>
        </div>
        <ion-buttons slot="end" style="padding-right: 10px; height: 54px;">
          <!-- User -->
          <span *ngIf="GlobalFields.currentUserInfo && GlobalFields.currentUserInfo.userTypeId !== Constants.ADMIN_ID"
            style="display: inline-flex; vertical-align: middle; margin-right: 20px">
            <div class="imgProfileRounded" *ngIf="GlobalFields.currentUserInfo.profilePicUrl"
              [style.background]="'url(' +GlobalFields.currentUserInfo.profilePicUrl+')'"></div>
            <div *ngIf="!GlobalFields.currentUserInfo.profilePicUrl" class="imgProfile">
              <i class="fal fa-image" style="font-size: 13pt;color: #eeeeee;"></i>
            </div>
          </span>
          <!-- Admin -->
          <span *ngIf="GlobalFields.currentUserInfo && GlobalFields.currentUserInfo.userTypeId === Constants.ADMIN_ID"
            style="display: inline-flex; vertical-align: middle; margin-right: 20px">
            <div class="imgProfile" style="background-color: #ff646a;">
              <i class="fal fa-user-crown" style="font-size: 13pt;color: white;" (click)="openLink('admin-panel')"></i>
            </div>
          </span>
          <button class="btnSidebarIcon" *ngIf="!GlobalFields.sideMenuOpened" (click)="openSidebar()">
            <i class="fal fa-bars"></i>
          </button>
          <button class="btnSidebarIcon" *ngIf="GlobalFields.sideMenuOpened" (click)="closeSidebar()">
            <i class="fal fa-times colorPrimary"></i>
          </button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <app-header *ngIf="GlobalFields.showMenu" id="mainHeader"></app-header>

    <!-- Side menu -->
    <span *ngIf="GlobalFields.showMenu && GlobalFields.sideMenuOpened">
      <div class="backSideMemu" (click)="GlobalFields.sideMenuOpened = false"></div>
      <div class="sideMenu sideMenuOpened">
        <div style="background-color: white;">
          <div style="text-align: center; height: 50px; padding: 5px; position: sticky; top: 0;">
            <button (click)="GlobalFields.sideMenuOpened = false"
              style="left: 10px; top: 15px; position: absolute; background-color: white; font-size: 14pt;">
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
        <div>
          <menuComponent></menuComponent>
        </div>
      </div>
    </span>

    <!-- Main content, level 1 parent routing -->
    <!--  <div *ngIf="GlobalFields.dataIsReady" style="height: 100%; width: 100%; overflow: auto">
    <ion-router-outlet id="{{GlobalFields.showMenu ? 'mainRouterOutlet' : '' }}" [swipeGesture]="true" animated="false" main></ion-router-outlet>
  </div>-->
    <div *ngIf="GlobalFields.dataIsReady" class="mainContentWithHeader"
      style="height: 100%; width: 100%; overflow: auto;">
      <router-outlet></router-outlet>
      <app-fab-support></app-fab-support>
    </div>
  </ng-template>
</ion-app>