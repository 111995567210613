import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-under-construction',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})

export class UnderConstructionComponent {

  spacestLogo: string | null = './assets/imgs/logo/logo_roomless_for_spacest.png';
  wipLogo: { src: string } | null = { src: 'wip_logo.png' };
  styles = {
    container: 'container',
    logo: 'logo',
    messageContainer: 'messageContainer',
    logoWip: 'logoWip',
    message: 'message'
  };
  constructor() { }


}
