import { Component } from '@angular/core';
import { Service } from '../../services/Service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFields } from '../../GlobalFields';
import { ModalController, NavController } from '@ionic/angular';
import { Constants } from '../../Constants';
import { AuthenticationService } from '../../services/AuthenticationService';

@Component({
  selector: 'menuPage',
  templateUrl: 'menuPage.html',
  styleUrls: ['menuPage.scss'],
})
export class MenuPage {
  GlobalFields = GlobalFields;
  Constants = Constants;
  loadingSection = false;

  constructor(
    public service: Service,
    public translate: TranslateService,
    public navController: NavController,
    public modalCtrl: ModalController,
    public authService: AuthenticationService,
  ) {}

  ionViewDidEnter() {
    this.service.setWhiteBlackBackgroundStatusBar();
  }
}
