import { Constants } from '../Constants';
import { GlobalFields } from '../GlobalFields';

export class Filter {
  listingTypes?: number[] = []; //1: stanze singole, 2: real estate, 3: posti letto
  hideFakeUser?: boolean = true;
  fromPrice?: number = Constants.minDefaultPriceRange;
  toPrice?: number = GlobalFields.selectedSearchListingType == Constants.RENT_LISTING_TYPE ? Constants.maxDefaultPriceRange : Constants.maxDefaultPriceRangeSale;
  services?: number[] = [];
  checkInDate?: number; //timestamp
  whenFreeWithin?: number; //timestamp
  dateFilterType?: number;//1 if check-in and 2 if whenFreeWithin
  numMonthsRental?: number; //Numero mesi da prenotare
  userTypeId?: number; // filter by user type ADMIN, TENANT, OWNER (Constants)

  otherFreeRooms?: number;
  maxOtherRooms?: number; //Max num of total bedrooms in the house if acomodation
  numBedrooms?: number;
  numBathrooms?: number;

  // address searched/where you are
  address?: string;

  utilitiesIncluded?: boolean;

  // If you leave **pageSize** and **pageNumber** empty, the service will return all results that satisfy the search parameters.
  pageSize = Constants.defaultPagingSearch; // number of rows to be returned in a page
  pageNumber = 0; // number of the page to be returned

  sortField?: string; // possible values: PUBLISHED_DATE, PRICE, NAME
  sortOrder?: string; // possible values: ASC, DESC

  placeSlang?: string;
  latitude?: number;
  longitude?: number;
  radius?: number;
  upperId?: number;
  level?: number;
  path?: string;

  animalsPermission?: number;
  smokersPermission?: number;
  genderPreferences?: number;

  mq?: number;

  lang?: string; //Used for the translation of the search API

  //ADMIN

  name?: string;
  city?: string;
  province?: string;
  countryId?: number;
  ownerId?: number;
  email?: string;
  phone?: string;
  username?: string;
  status?: string;
  lastName?: string;
  userId?: string;
  verified?: boolean;
  verifiedOwner?: boolean;
  individual?: boolean;
  missingPaymentAccount?: boolean; // TODO unused now
  missingPaymentMethod?: boolean;
  deleted?: boolean;

  trackingData?: {
    utmSource: undefined
  };

  //ADS
  showAdsTexts?: boolean; // used to show titles for the ads in search page
}


export enum FilterBadgeId {
  UnManaged = 'unManaged',
  Managed = 'managed',
  ToBeConfirmed = 'toBeConfirmed',
  Accepted = 'accepted',
  ToBeAccepted = 'toBeAccepted',
  AltProposals = 'altProposals',
  Followups = 'followups',
  PendingVisit = 'pendingVisit'
}

export enum FilterBadgeKey {
  UnManaged = 'TO_BE_MANAGED',
  Managed = 'ONLY_MANAGED',
  ToBeConfirmed = 'IB_TO_BE_CONFIRMED',
  Accepted = 'ACCEPTED',
  ToBeAccepted = 'REQUESTED',
  AltProposals = 'PROP_ALT',
  Followups = 'FOLLOWUP',
  PendingVisit = 'VISIT'
}

export enum CrmFiltersType {
  MANAGED = 'managed',
  RENT_STATUS = 'rentStatus',
  PENDING_FOLLOWUP = 'pendingFollowup',
  PENDING_VISIT = 'pendingVisit'
}