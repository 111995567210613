import { Component, Input } from '@angular/core';
import { GlobalFields } from '../../GlobalFields';
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'loading',
  templateUrl: 'loading.html',
  styleUrls: ['loading.scss'],
  imports: [
    CommonModule,
    IonicModule
  ],
  standalone: true

})
export class Loading {
  @Input() softLoading: boolean;

  GlobalFields = GlobalFields;
}
