import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GoogleTranslateService {
  // private url = 'https://www.googleapis.com/language/translate/v2';
  private url =
    'https://translation.googleapis.com/v3/projects/1567874236882/locations/global:translateText';

  constructor(private http: HttpClient) {}

  translate(query: string) {
    const params = new HttpParams();
    return this.http.get(this.url, { params: params });
  }
}
