import { Injectable } from '@angular/core';
import { Constants } from '../Constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalFields } from '../GlobalFields';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  GlobalFields: GlobalFields;

  constructor(private http: HttpClient) {}

  getMyAddressFromGoogle(lat: any, long: any) {
    return this.http.get(
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
        lat +
        ',' +
        long +
        '&key=' +
        Constants.GoogleMapsKEY
    );
  }

  getMyLatLongFromGoogle(address: any): Observable<any> {
    return this.http.get(
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        address +
        '&key=' +
        Constants.GoogleMapsKEY
    );
  }

  getAddressFromOpenMaps(lat: any, long: any): Observable<any> {
    return this.http.get(
      'https://nominatim.openstreetmap.org/reverse.php?format=json&lat=' + lat + '&lon=' + long
    );
  }

  getLatLongFromOpenMaps(address: string): Observable<any> {
    const addr = address.replace(' ', '+');
    console.log(address);
    console.log(addr);
    return this.http.get(
      'http://nominatim.openstreetmap.org/search?q=' +
        addr +
        '&format=json&polygon=1&addressdetails=1'
    );
  }

  getLocationsAutocomplete(str: string): Observable<any> {
    const url =
      'https://search-api-new.uniplaces.com/locations_autocomplete?source=homepage&locale=it_IT&' +
      'query=' +
      str;

    return this.http.get(url);
  }
}
