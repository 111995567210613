import { Listing } from './entities/listing/listing';
import { AlertController } from '@ionic/angular';
import { Service } from './services/Service';
import { Constants } from './Constants';
import { LocationService } from './services/LocationService';
import { Profile } from './entities/profile';
import { Country, SelectIonicItem } from './services/SelectIonicItem';
import { Filter } from './entities/filter';
import { Place } from './entities/place';
import { Renderer2, RendererFactory2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { ListingSales } from './entities/sales/listingSales';
import { GoogleReviews } from './entities/googleReviews';
import { TrackingData } from './entities/trackingData';
import { Rent } from './entities/calendar/Visit';
import { AdminTypeEnum, PathPermissionsList, PermissionsNames, SubPathPermissionsList } from "./entities/permissions/permissions";
import { PathConstants } from "./PathConstants";

// analytics
declare let gtag: Function;
// gb pixel
declare let fbq: Function;
// tag manager
declare global {
  interface Window {
    dataLayer: any[];
  }
}

export class GlobalFields {
  public static currentLanguage = '';

  public static websiteURL = 'https://spacest.com'

  // general
  public static languagesList: SelectIonicItem[] = []; // {id: number, name: string, code?: string}
  public static countries: Country[] = []; // {id: number, name: string}
  public static states: SelectIonicItem[] = []; // {id: number, name: string}
  public static cities: SelectIonicItem[] = []; // {id: number, name: string}
  public static occupations: SelectIonicItem[] = []; // {id: number, name: string}
  public static isTestEnvironment = false; //Force the environment to be in test
  public static isBrowser = true; // true if in browser, false if in SSR server
  public static isMobile = false;
  public static isTablet = false;
  public static sideMenuOpened = false;

  // user
  public static currentUserInfo: Profile;
  public static anonymousUserType: number = Constants.TENANT_ID;
  public static anonymousOwnerType: number = Constants.OWNER_ID;
  public static currentTrackingData: TrackingData = {}; //Rent or Sale listings type selected


  public static selectedSearchListingType: number; //Rent or Sale listings type selected

  // listings_rent
  public static filteredListings: Listing[] = [];
  public static listingServices: SelectIonicItem[] = []; // {id: number, name: string, listingTypeIds: number[]}
  public static searchFilter: Filter;
  public static placeSelected: Place;
  public static listingToEdit: Listing; // used as palceholder to know which listing have to be edited
  public static externalListing: Listing; // used to store temporarly the listing taken from external url if you were not logged in


  // listings_sale
  public static filteredListingsSale: ListingSales[] = [];


  // geo part
  public static lat: any;
  public static long: any;
  public static address: any;
  public static locationService: LocationService;

  // visits
  public static num_Pending_Slot: number;
  public static num_Incoming_Virtual_Visit: number;
  public static num_Incoming_Physical_Visit: number;
  public static num_rent_request: number;

  public static dataIsReady = false;
  static numInitialCallsCompleted = 0;

  public static showMenu = false;

  // **** ANGULAR UNIVERSAL ****
  public static window: Window;
  public static document: Document;
  public static location: Location;
  public static platformId: Object;
  public static url: string;
  public static rendererFactory: RendererFactory2;
  public static title: Title;
  public static meta: Meta;


  // Used by httpInterceptor
  public static avoidApplicationJson: boolean = false;

  /********************* OLD THINGS **************************/

  // OLD THINGS

  // filters, selected type part and other selected listings_rent data
  public static selectedListing: Listing;

  // app status part
  public static loading = false;
  public static loadingSoft = false;
  public static errorIni = false;
  public static errorString;


  public static isPlatformBrowser() {
    // To wrap code only if is running in a Browser
    return isPlatformBrowser(this.platformId);
  }

  public static isPlatformServer() {
    // To wrap code only if is running in a Server
    return isPlatformServer(this.platformId);
  }

  // **** END - ANGULAR UNIVERSAL ****

  // Appends the meta tags to a single page to customize them
  public static setMetaDataTags(
    seo_obj: any,
    noIndex?: boolean,
    pageType?: string,
    imgUrl?: string
  ) {
    let lanKey;

    switch (GlobalFields.currentLanguage) {
      case 'it':
        lanKey = 'it';
        break;
      case 'de':
        lanKey = 'de';
        break;
      case 'es':
        lanKey = 'es';
        break;
      case 'fr':
        lanKey = 'fr';
        break;
      default:
        lanKey = 'en';
        break;
    }


    const title = seo_obj.title[lanKey];
    const descr = seo_obj.description[lanKey];
    const url = GlobalFields.url;

    // if (GlobalFields.isPlatformBrowser()) {
    // Implement any logic that dynamically adds metadata for specific route data, e.g.:
    const renderer: Renderer2 = GlobalFields.rendererFactory.createRenderer(null, null);

    // img
    let img = 'https://spacest.com/assets/imgs/home/living-room-home.jpg';
    if (imgUrl) {
      img = imgUrl;
    }

    // noindex
    if (noIndex || url.includes('beta') || url.includes('localhost')) {
      GlobalFields.meta.updateTag({ name: 'robots', content: 'noindex' });
    }

    // seo tags
    GlobalFields.title.setTitle(title);
    GlobalFields.meta.updateTag({ name: 'description', content: descr });

    // Generic tags
    this.document.documentElement.lang = GlobalFields.currentLanguage;
    GlobalFields.meta.updateTag({ name: 'locale', content: GlobalFields.currentLanguage });

    // FB tags
    GlobalFields.meta.updateTag({ name: 'og:site_name', content: 'Roomless' });
    /* GlobalFields.meta.addTags([
       { name: 'og:url', content: 'og:url'}
     ],false)*/
    GlobalFields.meta.updateTag({ name: 'og:title', content: title });
    GlobalFields.meta.updateTag({ name: 'og:description', content: descr });
    GlobalFields.meta.updateTag({ name: 'og:image', content: img });
    GlobalFields.meta.updateTag({ name: 'og:type', content: pageType ? pageType : 'website' });
    let lang;
    switch (GlobalFields.currentLanguage) {
      case 'it':
        lang = 'it_IT';
        break;
      case 'de':
        lang = 'de_DE';
        break;
      case 'es':
        lang = 'es_ES';
        break;
      case 'fr':
        lang = 'fr_FR';
        break;
      default:
        lang = 'en_EN';
        break;
    }
    GlobalFields.meta.updateTag({
      name: 'og:locale',
      content: lang,
    });

    // Twitter tags
    GlobalFields.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    GlobalFields.meta.updateTag({ name: 'twitter:title', content: title });

    // Hreflang tags

    // Remove old hrefLangTags
    const hrefLangTags = this.document.head.getElementsByClassName('hrefLangTags');
    if (hrefLangTags && hrefLangTags.length > 0) {
      for (let i = 0; i < hrefLangTags.length; i++) {
        renderer.removeChild(this.document.head, hrefLangTags.item(i));
      }
    }

    const current_path = GlobalFields.location.path().split('?')[0];
    const it_path =
      current_path.includes('/en/') || current_path.includes('/it/')
        ? current_path.replace('/en/', '/it/')
        : '/it' + current_path;
    const en_path =
      current_path.includes('/en/') || current_path.includes('/it/')
        ? current_path.replace('/it/', '/en/')
        : '/en' + current_path;

    GlobalFields.addNewHreflangTagInHeader(
      renderer,
      'link',
      'rel',
      'alternate',
      'href',
      Constants.prodSiteURL + it_path,
      'hreflang',
      'it'
    );

    GlobalFields.addNewHreflangTagInHeader(
      renderer,
      'link',
      'rel',
      'alternate',
      'href',
      Constants.prodSiteURL + en_path,
      'hreflang',
      'en'
    );

    // TODO capire se questo può essere usato e perchè lo usavo
    // this.document.head.get;
    // }
  }

  // Appends the schema tags to a single page to customize them
  // Schema.org microdata tags
  // https://www.w3.org/TR/microdata/
  public static setReviewsSchema() {
    const res = GlobalFields.document.getElementsByTagName('head');
    if (res && res.length > 0) {
      const head = res[0];
      if (!GlobalFields.document.getElementById('googleReviesId')) {
        let script = GlobalFields.document.createElement('script');
        script.id = 'googleReviesId';
        script.type = 'application/ld+json';
        script.text = JSON.stringify(GoogleReviews.reviews);
        head.appendChild(script);

      }
    }
  }

  public static addNewHreflangTagInHeader(
    renderer: Renderer2,
    elemName: string,
    attributeName1: string,
    attributeValue1: string,
    attributeName2: string,
    attributeValue2?: string,
    attributeName3?: string,
    attributeValue3?: string
  ) {
    if (this.isPlatformBrowser()) {
      const tag: HTMLElement = renderer.createElement(elemName);
      tag.className = 'hrefLangTags';
      tag.setAttribute(attributeName1, attributeValue1);
      tag.setAttribute(attributeName2, attributeValue2);
      tag.setAttribute(attributeName3, attributeValue3);
      renderer.appendChild(this.document.head, tag);
    }
  }

  public static init(service: Service) {
    // GlobalFields.searchFilter = new Filter();

    service.getServices().subscribe(
      (res) => {
        if (res.success) {
          GlobalFields.listingServices = res.data;
          if (GlobalFields.listingServices) {
            GlobalFields.listingServices.forEach(
              (el) => (el.icon = Constants.getServicesIcons(el.id))
            );
          }
        }
        this.numInitialCallsCompleted = this.numInitialCallsCompleted + 1;
        this.endOfInit();
      },
      (err) => {
        this.numInitialCallsCompleted = this.numInitialCallsCompleted + 1;
        this.endOfInit();
      }
    );

    service.getLanguagesList().subscribe(
      (res) => {
        if (res.success) {
          GlobalFields.languagesList = res.data;
        } else {
          console.log(res.errorMessage);
        }
        this.numInitialCallsCompleted = this.numInitialCallsCompleted + 1;
        this.endOfInit();
      },
      (err) => {
        console.log(err.message);
        this.numInitialCallsCompleted = this.numInitialCallsCompleted + 1;
        this.endOfInit();
      }
    );
  }

  public static endOfInit() {
    if (this.numInitialCallsCompleted === 2) {
      this.dataIsReady = true;
    }
  }

  public static isInitFinished(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.dataIsReady) {
        resolve(true);
      } else {
        resolve(undefined);
      }
    });
  }

  public static isAdmin() {
    if (!GlobalFields.currentUserInfo) {
      return false;
    }
    if (GlobalFields.currentUserInfo.userTypeId === Constants.ADMIN_ID) {
      return true;
    }
    return false;
  }


  public static isHeadOf() {
    if (!GlobalFields.currentUserInfo) {
      return false;
    }
    if (GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.HO_SALE || GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.HO_SUPPLY) {
      return true;
    }
    return false;
  }

  public static isSuperAdmin(): boolean {
    if (this.isAdmin() && GlobalFields.currentUserInfo.permissions) {
      return GlobalFields.currentUserInfo.permissions.includes(PermissionsNames.SUPERADMIN)
    }
    return false;
  }
  /* Check if user has at least a "HEAD OF" permission */
  public static isHeadOfOrGreater(): boolean {
    if (this.isAdmin() && GlobalFields.currentUserInfo.permissions) {
      return this.isSuperAdmin() || this.isHeadOf()
    }
    return false;
  }

  /* Check if user has is a Sales agent */
  public static isASalesOrHeadOf(): boolean {
    if (GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.HO_SALE ||
      GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.SALE) {
      return true;
    }
    return false;
  }

  /* Check if user has is a Supply agent */
  public static isASupplyOrHeadOf(): boolean {
    if (GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.HO_SUPPLY ||
      GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.SUPPLY) {
      return true
    }
    return false;
  }

  /* Utility function to check if user has a certain permission*/
  public static hasPermission(permission: PermissionsNames): boolean {
    if (this.isAdmin() && GlobalFields.currentUserInfo.permissions) {
      return GlobalFields.currentUserInfo.permissions.includes(permission)
    }
    return false;
  }


  /*
  * Checks if the current user has permission to access a given path by comparing the user's permissions with the allowed paths for that path.
  * */
  public static checkPathPermission(path: PathConstants): boolean {
    if (this.isSuperAdmin()) return true;

    const currentUserPermissions = GlobalFields.currentUserInfo?.permissions;

    if (currentUserPermissions && path) {
      const allowedPaths = PathPermissionsList.get(path);
      if (allowedPaths)
        return currentUserPermissions.some(permission => allowedPaths.includes(permission))
    }
    return false
  }

  public static checkSubPathPermission(path: PathConstants): boolean {
    if (this.isSuperAdmin()) return true;

    const currentUserPermissions = GlobalFields.currentUserInfo?.permissions;

    if (currentUserPermissions && path) {
      const allowedPaths = SubPathPermissionsList.get(path);
      if (allowedPaths)
        return currentUserPermissions.some(permission => allowedPaths.includes(permission))
    }
    return false
  }



  /*  public static getMyAddress(
     service: Service,
     locationService: LocationService,
     nativeGeocoder: NativeGeocoder
   ) {
     console.log('Get my location');

     const options: NativeGeocoderOptions = {
       useLocale: true,
       maxResults: 5,
     };

     if (true) {
       // Google Maps
       nativeGeocoder
         .reverseGeocode(GlobalFields.lat, GlobalFields.long, options)
         .then((result: any[]) => {
           console.log(JSON.stringify(result[0]));
           GlobalFields.address =
             result[0].subLocality +
             ' ' +
             result[0].administrativeArea +
             ' ' +
             result[0].countryName;
         })
         .catch((error: any) => {
           // not avalailable the native service
           console.log(error);
           locationService
             .getMyAddressFromGoogle(GlobalFields.lat, GlobalFields.long)
             .subscribe((data: any) => {
               if (data && data.results && data.results.length > 0) {
                 GlobalFields.address = data.results[0].formatted_address;
               }
             });
         });
     } else {
       // Open Street Maps
       locationService
         .getAddressFromOpenMaps(GlobalFields.lat, GlobalFields.long)
         .subscribe((res) => {
           GlobalFields.address = res.display_name;
         });
     }
   }
  */
  // call pixels for steps
  public static callConversionStep(event?: string, url?: string, rent?: Rent) {
    if (isPlatformBrowser(this.platformId)) {
      const host = this.window.location.hostname;

      //if (host && host !== 'localhost') {
      if (host) {
        // analytics NOT USED
        if (url) {
          // call it to track url changed programmatically instead of using the router
          gtag('config', 'UA-114546529-2', { page_path: url });

          // fb pixel
          if (fbq) {
            fbq('track', 'PageView');
          }
        }

        //The one REAL USED
        if (event) {
          // used to track steps to the conversion
          if (isPlatformBrowser(this.platformId)) {
            // tag manager
            const dataLayer = (this.window.dataLayer = this.window.dataLayer || []);
            if (dataLayer && event) {
              console.log(event);
              console.log(rent);


              //Ecommerce
              if (rent && rent.listing) {
                let type = undefined;

                if (rent.listing.type == Constants.ACCOMODATION_LISTING_TYPE.id && rent.listing.accomodationDetails.roomType === 1)
                  type = "Single Room";
                else if (rent.listing.type === Constants.ACCOMODATION_LISTING_TYPE.id && rent.listing.accomodationDetails.roomType === 2)
                  type = "Shared Room"
                else if (rent.listing.type === Constants.ESTATE_LISTING_TYPE.id && rent.listing.accomodationDetails.houseType === 1)
                  type = "Apartment"

                dataLayer.push({
                  event: event,
                  user_id: GlobalFields.currentUserInfo ? GlobalFields.currentUserInfo.id : undefined, //https://stackoverflow.com/questions/66012064/send-user-id-from-google-tag-manager-to-google-analytics-4
                  //Custom for Google Ads
                  value: rent.fees,
                  currency: 'EUR',
                  campaignSource: rent.trackingData && rent.trackingData.utmSource ? rent.trackingData.utmSource : undefined,
                  campaignMedium: rent.trackingData && rent.trackingData.utmMedium ? rent.trackingData.utmMedium : undefined,
                  campaignName: rent.trackingData && rent.trackingData.utmCampaign ? rent.trackingData.utmCampaign : undefined,
                  //Transaction for Analytics e-commerce
                  ecommerce: { //Doc: https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#purchases
                    purchase: {
                      actionField: {
                        id: rent.id,
                        revenue: rent.fees,
                      },
                      products: [{
                        name: rent.listing.name,
                        id: rent.listing.id,
                        price: rent.fees,
                        affiliation: rent.trackingData && rent.trackingData.utmSource ? rent.trackingData.utmSource : undefined,
                        category: type,
                        quantity: 1
                      }]
                    }
                  }
                });
              } else
                dataLayer.push({
                  event: event,
                  user_id: GlobalFields.currentUserInfo ? GlobalFields.currentUserInfo.id : undefined
                });
            }
            // fb pixel
            // fbq('track', 'ViewContent', { content_name: 'Homepage' });
          }
        }
      }
    }
  }

  /* TODO remove notifications call
   public static getNotifications(service: Service) {
      service.getNotifications().subscribe((res) => {
        if (res.success) {
          GlobalFields.num_Pending_Slot = res.data.numPendingSlot;
          GlobalFields.num_Incoming_Virtual_Visit = res.data.numIncomingVirtualVisit;
          GlobalFields.num_Incoming_Physical_Visit = res.data.numIncomingPhysicalVisit;
          GlobalFields.num_rent_request = res.data.numRentRequest;
        }
      });
    }*/

  public static clearFilter() {
    GlobalFields.searchFilter = new Filter();
  }

  public static clearFilterButNotPlace() {
    const address = GlobalFields.searchFilter.address;
    const placeSlang = GlobalFields.searchFilter.placeSlang;
    const latitude = GlobalFields.searchFilter.latitude;
    const longitude = GlobalFields.searchFilter.longitude;
    const radius = GlobalFields.searchFilter.radius;
    GlobalFields.searchFilter = new Filter();
    GlobalFields.searchFilter.address = address;
    GlobalFields.searchFilter.placeSlang = placeSlang;
    GlobalFields.searchFilter.latitude = latitude;
    GlobalFields.searchFilter.longitude = longitude;
    GlobalFields.searchFilter.radius = radius;
  }

  // Controlla se una visita virtuale è una chiamata in arrivo oppure no, ovvero se puoi partecipare o no alla chiamata
  public static checkIfIncomingVirtualCall(startTimestamp: number) {
    const nowTimestamap = new Date().getTime();
    // ora è  tra 15 minuti prima e 45 minuti dopo lo start della visita
    if (
      nowTimestamap > startTimestamp - 15 * 60 * 1000 &&
      nowTimestamap < startTimestamp + 45 * 60 * 1000
    ) {
      // ora è 45 minuti dopo lo start della visita
      return true;
    } else {
      return false;
    }
  }

  // Controlla se una visita in loco è in arrivo
  public static checkIfIncomingPhisicalVisit(startTimestamp: number) {
    const nowTimestamap = new Date().getTime();
    // ora è tra 3 ore prima e 45 minuti dopo lo start della visita
    if (
      nowTimestamap > startTimestamp - 180 * 60 * 1000 &&
      nowTimestamap < startTimestamp + 45 * 60 * 1000
    ) {
      // ora è 45 minuti dopo lo start della visita
      return true;
    } else {
      return false;
    }
  }

  public static getHowManyTimeToNow(timestamp1: number) {
    let difference = timestamp1 - new Date().getTime();

    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24;

    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    const minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;

    const secondsDifference = Math.floor(difference / 1000);

    let res = '';

    if (daysDifference >= 1) {
      res = res + '' + daysDifference + ' days, ';
    }
    if (hoursDifference >= 1) {
      res = res + '' + hoursDifference + ' hours, ';
    }
    if (minutesDifference >= 1) {
      res = res + '' + minutesDifference + ' minutes ';
    }

    return res;
  }

  public static getPublicAddressFromListing(listing: Listing): string {
    let addressLabel = '';

    let addr;

    if (listing && listing.address) {
      addr = listing && listing.address;
    } else if (listing && listing.structure && listing.structure.address) {
      addr = listing.structure.address;
    }

    if (addr) {
      if (addr.addressRoute) {
        addressLabel = addressLabel + addr.addressRoute;
      }
      if (addr.addressRoute && addr.addressCity) {
        addressLabel = addressLabel + ', ';
      }
      if (addr.addressCity) {
        addressLabel = addressLabel + addr.addressCity;
      }

      //addressState is present only if the city is not "important", means place with priority not 0
      if (addr.addressCity && addr.addressState) {
        addressLabel = addressLabel + ', ';
      }
      if (addr.addressState) {
        addressLabel = addressLabel + addr.addressState;
      }
    }

    return addressLabel;
  }

  // Calcolo della fee di roomless sul rental blocking
  public static getRoomlessRentFee(numMonths: number, price: number) {
    let fee = 0;
    for (let i = 1; i <= numMonths; i++) {
      let perc = 0;
      if (i >= 1 && i <= 3) {
        perc = 0.1;
      } else if (i >= 4 && i <= 6) {
        perc = 0.08;
      } else {
        perc = 0.05;
      }
      fee = fee + price * perc;
    }
    // console.log(fee.toFixed(2));
    // console.log(parseFloat(fee.toFixed(2)));
    return parseFloat(fee.toFixed(2));
  }

  public static async openAlert(
    alertController: AlertController,
    header?: string,
    subHeader?: string,
    msg?: string
  ) {
    const alert = await alertController.create({
      header: 'Error',
      subHeader: 'Error on your server',
      message: msg,
      buttons: ['OK'],
    });

    await alert.present();
  }


  // Method to convert Zulu time to Italy time format
	public static convertToItalyTime(zuluTime: string): string {
		const date = new Date(zuluTime);

		// Options for formatting in Italy's time zone
		const options: Intl.DateTimeFormatOptions = {
		  timeZone: 'Europe/Rome',
		  hour: '2-digit',
		  minute: '2-digit',
		};

		// Format the date in Italy's time zone: NB. returns only the time in HH:mm format
		const formatter = new Intl.DateTimeFormat('it-IT', options);
		return formatter.format(date);
	}
}
