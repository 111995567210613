<div 
    class="overlay" 
    *ngIf="isOpen" 
    (click)="toggleGuide()">
</div>
<div class="floating-container">
    <button class="floating-btn" [class.open]="isOpen" (click)="toggleGuide()">
        <i *ngIf="!isOpen" class="fas fa-question" style="cursor: pointer; margin: 0;"></i>
        <i *ngIf="isOpen" class="fal fa-times" style="cursor: pointer; margin: 0;"></i>
    </button>
    
    <div class="guide-panel" [class.open]="isOpen">
        <div class="flex items-end mb-3 ml-[-6px] relative">
            
            <img src="../../../assets/imgs/logo/spacest_logo_s.jpg" style="width: 28px; height: 28px;" alt="">
            <h3 class="absolute left-[23px]">upport Corner</h3>
        </div>
        <h5 *ngIf="currentSupportLinks">
            Guides
        </h5>
        <ul class="list-disc">
            <li class="list-inside mb-1" *ngFor="let link of currentSupportLinks">
                <a [href]="link.url" target="_blank" rel="noopener noreferrer">{{ link.label }}
                    <i class="fal fa-external-link" style="cursor: pointer"></i>
                </a>
            </li>
            
        </ul>

        <h5 *ngIf="currentSupportLinks" class="m-0 mt-1 mb-2">Still have questions?</h5>
        <h5 *ngIf="!currentSupportLinks" class="m-0 mt-1 mb-2">Any questions?</h5>
        <ul>
            <li *ngFor="let contact of agentContacts">
                <a [href]="'https://wa.me/' + contact.phone" target="_blank" rel="noopener noreferrer">
                Text {{ contact.name }}
                <i class="fab fa-whatsapp" style="color: #4caf50; cursor: pointer"></i>
                </a>
            </li>
        </ul>

        <h5 class="m-0 mb-2">Have technical issues?</h5>
        <ul>
            <li *ngFor="let contact of technicalContacts">
                <a [href]="'https://wa.me/' + contact.phone" target="_blank" rel="noopener noreferrer">
                Text {{ contact.name }}
                <i class="fab fa-whatsapp" style="color: #4caf50; cursor: pointer"></i>
                </a>
            </li>
        </ul>

        <h5 class="m-0 mb-2">Need to open a ticket?</h5>
        <span>
            <a href="https://openproject.roomlessrent.com/" target="_blank" rel="noopener noreferrer" style="text-decoration-line: underline ;">Go to Openproject</a>
            and
            <a [href]="ticketGuide.url" target="_blank" rel="noopener noreferrer" style="text-decoration-line: underline ;"> follow these rules</a>

        </span>
        
    </div>
</div>
  