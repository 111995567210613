import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocationService } from './services/LocationService';
import { AuthenticationService } from './services/AuthenticationService';
import { HeaderComponent } from './utilities/header/header.component';
import { MobileFooterComponent } from './utilities/mobileFooter/mobileFooter.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CompleteRegModal } from './utilities/completeRegModal/completeRegModal';
import { MenuPageModule } from './components/menuPage/menuPage.module';
import { GoogleTranslateService } from './services/GoogleTranslateService';
import { SelectModule, ToastModule, ToastService } from 'ng-uikit-pro-standard';
import { LoginSharedModule } from './utilities/loginShared.module';
import { CommonModule } from '@angular/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { Loading } from "./components/loading/loading";
import { CustomDateAdapter } from "./entities/custom-date-adapter";
import { ErrorsInterceptor } from './interceptors/errors.interceptor';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { SupportFabSharedModule } from './utilities/supportFabShared.module';
import {DatepickerComponent} from "./utilities/dataPicker/dataPicker";

@NgModule({
	declarations: [AppComponent, CompleteRegModal, HeaderComponent, MobileFooterComponent],
	imports: [
		SelectModule,
		BrowserModule,
		UnderConstructionComponent,
		NoopAnimationsModule,
		FormsModule,
		HttpClientModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		ReactiveFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		MenuPageModule,
		ToastModule.forRoot(),
		LoginSharedModule,
		CommonModule,
		//TransferHttpCacheModule,
		NgtUniversalModule,
		LazyLoadImageModule,
		OverlayModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		Loading,
		SupportFabSharedModule,
		//ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
		// ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
	],
	providers: [
		AuthenticationService,
		GoogleTranslateService,
		LocationService,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorsInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{ provide: DateAdapter, useClass: CustomDateAdapter },
		{ provide: LOCALE_ID, useValue: 'it-IT' },
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
	],
	exports: [
		IonicModule,
		RouterModule,
		TranslateModule,
		HttpClientModule,
		LoginSharedModule,
		LazyLoadImageModule,
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
	// return new TranslateHttpLoader(http, 'https://cdn.roomlessrent.com/resources/assets/i18n/', '.json');
}
