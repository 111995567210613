import { CheckInOutType, LandlordFeePaymentType, PaymentAccount, PaymentMethods, SeasonalPriceType } from '../Model';
import { AdminTypeEnum, PermissionsNames } from "./permissions/permissions";
import { TrackingData } from './trackingData';
import { AdminAgents } from "./adminSettings";

export class BillingDetails {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string; //Billing email
  fiscalCode?: string;
  country?: string;
  nationalityId?: number;
  state?: string;
  city?: string;
  address?: string;
  houseNumber?: number;
  zipCode?: string;
  vatNumber?: string;
  businessName?: string;
  type?: number; //0: Individual, 1: Professional
  userId?: number;
  birthCountryId?: number;
  birthCity?: string;
  birthDate?: string;
  billingCycleTypeId?: number; // 0 = ON_BOOKING (landlord fee detratta dal primo mese e fattura emessa immediatamente) | 1 = MONTHLY (landlord fee non detratta dal primo mese e fattura emessa a fine mese)
  nationality?: Nationality;
}

export class Nationality {
  id: number;
  code: string;
  iso3Code: string;
  name: string;
  phonePrefix: string;
  addressEnabled: boolean;
  nationalityName: string;
  nationalityNameEn: string;
  eInvoice: boolean;
  enableKlarna: boolean;
  isUe: boolean;
  countryLang: string;
}

export class DocumentUploaded {
  id?: number;
  requiredDocumentId?: number;
  path?: string;
  createdAt?: number;
  updatedAt?: number;
  requiredDocument?: DocumentRequired;
}

export class DocumentRequired {
  id?: number;
  ownerId?: number;
  templateId?: number = -1; // default value for Other/Custom document type
  requiredOccupationType?: RequiredOccupationType = 3; //1: Student, 2: Worker, 3: Both
  translations?: DocumentTranslation[] = [];
  createdAt?: number;
  updatedAt?: number;
}

export class DocumentTranslation {
  id?: number;
  lang?: string;
  name?: string;
  description?: string;
}

export class Callbell {
  assignedUser?: string
  createdAt?: string
  id?: number
  link?: string
  name?: string
  phone?: string
  channelUuid: string;
  channelName: string; //SALES, SUPPLY
}

export class Profile {
  id?: number;
  userTypeId?: number; // 1: admin, 2: tenant, 3: landlord
  userAccountType?: number // 1: Real/Registered 2:Fake/Not registered yet
  adminTypeId?: AdminTypeEnum;
  username?: string;
  password?: string;
  firstName?: string;
  email?: string;
  phonePrefix?: string;
  phonePrefixId?: number;
  phone?: string;
  lastName?: string;
  primaryName?: string;
  nameLabel?: string; // nome già composto dal server
  alias?: string; // used for the agent entity only
  gender?: number;
  bio?: string;
  userQuality?: number;
  credits?: number;
  requiresBirthData?: boolean;
  birthDate?: number; //timestamp
  trackingData?: TrackingData;
  fiscalCode?: string;
  nationalityId?: number;
  //Documents uploaded
  documents?: DocumentUploaded[];
  //Documents of the guests
  guestDocument?: DocumentUploaded[];
  callbell?: Callbell;
  callbells?: Callbell[];
  lastWhatsappMessageStatus?: WhatsappMessageStatusEnum;
  note?: {
    agent?: string,
    system?: string
  };


  //*********** Landlord settings ************
  cancellationPolicy?: string;
  notificationEmail?: string;
  verifiedOwner?: boolean; //True: Landlord verified. Allora può vedere info del richiedente --> verifiedOwner=True, individual=false
  collectDeposit?: boolean = undefined;  // può essere true/1, false/0, not specificato / undefined
  individual?: boolean; // True: Proprietario privato --> individual=true, verifiedOwner=false
  hideExternal?: boolean; // if true then hide external info on listings
  hideVisit?: boolean; //True: Mostro. False: Allora NON si mostra bottone visite
  requireTransitory?: boolean; //True: mostra caricamento documento che attesti il transitorio in creazione richiesta affitto
  minDaysFromNowCheckin?: number; //Indica min giorni da oggi per poter fare checkin
  maxDaysFromNowCheckin?: number; //Indica max giorni da quando si libera l'annuncio per poter fare checkin
  instantBooking?: boolean; //True: instant booking abilitato
  hasExternalPaymentUrl?: boolean; //True: instant booking abilitato e pagamento da url esterno
  requireIdentityDocuments?: boolean; //True: instant booking abilitato
  allowCheckoutEndMonth?: boolean; //True: Setta automaticamente all'ultimo giorno del mese il checkout
  checkInType?: CheckInOutType;
  checkOutType?: CheckInOutType;
  seasonalPriceDisplayType?: SeasonalPriceType;

  idDocUrl?: string //Contains tmpFileName or url of the file
  financialDocUrl?: string; //Contains tmpFileName or url of the file
  parentDocUrl?: string //Contains tmpFileName or url of the file
  occupationTypeId?: number; //Student 1 / Worker 2
  externalAuthType?: ExternalAuthProviderEnum; // SSO: Facebook 1 / Google 2
  //Documents required
  requiredDocuments?: DocumentRequired[];
  landlordFeePaymentType?: LandlordFeePaymentType;
  cityId?: string;
  city?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  province?: string;
  provinceId?: number;
  country?: string;
  countryId?: number;
  primaryLanguageId?: number;
  primaryLanguage?: string;
  profilePicUrl?: string;
  profileImgTmpFile?: string;
  profileImgContentType?: string;
  profileImgFileName?: string;
  status?: any;
  statusFlow?: number; //Status funnel
  lastLoginAt?: Date;
  createdAt?: Date;

  agent?: AdminAgents;


  acceptPolicy?: boolean | true = true;
  acceptMarketing?: boolean;

  paymentInfo?: {
    id?: number;
    type?: number; // 1= PayPal, 2=Bonifico
    email?: string;
    accountOwner?: string;
    accountIBAN?: string;
    accountSwiftCode?: string;
    paymentInfo?;
  }[];

  statistic?:{
    nextVisit: Date;
    nextFollowup: Date;
    userId?: number;
  }

  feeType?: number;
  feeValue?: number;
  minimumFee?: number;
  maximumFee?: number;
  feeVatIncluded?: boolean;

  landlordFeeType?: number;
  landlordFeeValue?: number;
  landlordFeeMin?: number;
  landlordFeeMax?: number;
  landlordFeeVatIncluded?: boolean;


  tenantFeeSplitType?: number;
  tenantFeeSplitValue?: number;
  tenantFeeSplitMin?: number;
  tenantFeeSplitMax?: number;

  billingInfo?: BillingDetails;
  paymentAccounts?: PaymentAccount[];
  paymentAccountDetails?: PaymentMethods; // default account

  permissions?: PermissionsNames[]; // list of user permissions

  numDraft?: number;
  numPublished?: number;
  numApproval?: number;

  fullPhone?: string;
  nationality?: string;
  userTypeEnum?: string;
  isUe?: boolean;

  //old
  cookie?: string;
  error?: string;
  user?: any;
  deletedAt?: string;
}

export enum UserTypeEnum {
  ADMIN_ID = 1,
  TENANT_ID = 2,
  OWNER_ID = 3,
}


export enum RequiredOccupationType {
  STUDENT = 1,
  WORKER = 2,
  BOTH = 3
}

export enum ExternalAuthProviderEnum {
  FACEBOOK = 1,
  GOOGLE = 2
}
export enum LeadManagementStatusEnum {
  NEW = 0,
  MANAGED = 1
}

export enum TenantFunnelStatus {
  NEW = 0,
  SALES_CONTACTED = 1,
  SALES_TO_SEND_OPTIONS = 2,
  SALES_PROPOSAL_SENT = 3,
  SALES_VISIT_ORGANIZED = 4,
  SALES_READY_TO_BOOK = 5,
  SALES_REQUEST_SENT = 6,
  COMPLETED = 7,
  NOT_INTERESTED = 8,
  SALES_READY_TO_ORGANIZE_VISIT = 9,
  SALES_STANDBY = 10,
  SALES_TO_BE_CALLED = 11,

}


export enum LandlordFunnelStatus {
  NEW = 0,
  CONTACTED = 1,
  SUPPLY_INTERESTED = 21,
  SUPPLY_DOUBT = 22,
  SUPPLY_PRIORITY = 23,
  NOT_INTERESTED = 8,
  COMPLETED = 7,
  SUPPLY_DELETED = 24,
  SUPPLY_CONTACTED = 25,
  SUPPLY_TO_BE_CALLED = 26,
}
export enum ListingStatusEnum {
  HIDDEN = 1,
  PUBLIC = 2,
  EXPIRED = 3,
  DELETED = 4,
  UNDER_APPROVAL = 5,
  REJECTED = 6,
  DRAFT = 7,
}

export enum WhatsappMessageStatusEnum {
  SUCCESS = 1,
  FAILED = 2
}

export enum LeadTypeEnum {
  SALE = 1,
  SUPPLY = 2,
  UNKNOWN = 3, // Usato solo per status interno nostro, non per il front
}

export enum LeadLandlordTypeEnum {
  PRIVATE = 1,
  PROFESSIONAL = 2
}

export enum ExternalPortalSourceEnum {
  ROOMLESS = 0,
  IMMOBILIARE = 1,
  IDEALISTA_IT = 2,
  IDEALISTA_ES = 3,
  IDEALISTA_PT = 4,
  FACEBOOK = 5,
  WG_GESUCHT = 6,
  SUBITO = 7,
  IMMO_SCOUT_24 = 8,
  IMMOJEUNE = 9,
  PISO = 10,
  IO_AFFITTO = 11,
  CALLBELL = 12,
  ROOMLESS_HELP = 13,
  SE_LOGER = 14,
  IMOVIRTUAL = 15,
  AMBER = 16,
  MYSTUDENTDIGS = 17,
  ROOMLESS_CALL = 18,
}
