import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  if (window) {
    // Don't log anything on production
    window.console.log = window.console.warn = window.console.info = () => {};
  }
}

document.addEventListener(
  'DOMContentLoaded',
  () => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => {console.log(err);});
  },
  { passive: true }
);
