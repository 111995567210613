import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FabSupportComponent } from '../components/fabSupport/fab-support.component';


@NgModule({
  declarations: [FabSupportComponent],
  imports: [CommonModule],
  exports: [FabSupportComponent]
})
export class SupportFabSharedModule {}
