import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {GlobalFields} from "./GlobalFields";
import { Constants } from './Constants';
import { ToastService } from 'ng-uikit-pro-standard';
import { AdminTypeEnum } from './entities/permissions/permissions';

@Injectable({
	providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanActivateChild {

	constructor(
		private router: Router,
		private toastrService: ToastService

	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		
		// If the user is a LOCARE admin and trying to access insights, redirect to sales cause he does not have the permission to see insights
		// so if locare user access to the root path the app does not crash and redirect to sales
		if (route.data.path === 'insights' && GlobalFields.currentUserInfo.adminTypeId === AdminTypeEnum.LOCARE) {
			return this.router.createUrlTree(['/sales']);
		}

		if (GlobalFields.checkPathPermission(route.data.path)) {
			return true;
		}

		this.toastrService.error(`Permission missing to visit: /${route.data.path}`, 'You do not have permission to access this page', Constants.ToastOptions);

		// this.router.navigateByUrl('/').then(() => {
		// 	this.router.navigate(['/']);
		// });
		return false;
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		if (GlobalFields.checkSubPathPermission(childRoute.data.path)) {
			return true;
		}

		this.toastrService.error(`Permission missing to visit: /${childRoute.data.path}`, 'You do not have permission to access this page', Constants.ToastOptions);

		// reload if current page == navigateTo page // TODO removed skip location change
		this.router.navigateByUrl('/').then(() => {
			this.router.navigate([`${childRoute.data.parent}`]);
		});

		return false;
	}
}