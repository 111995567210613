import { Component, Input, OnInit } from '@angular/core';
import { GlobalFields } from '../../../app/GlobalFields';
import { Constants } from '../../../app/Constants';
import { Service } from '../../services/Service';
import { ModalController } from '@ionic/angular';
import { Profile } from '../../entities/profile';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'completeRegModal',
  templateUrl: 'completeRegModal.html',
  styleUrls: ['completeRegModal.scss'],
})
export class CompleteRegModal implements OnInit {
  GlobalFields = GlobalFields;
  Constants = Constants;
  @Input() isModal?: boolean;


  countries: any[] = [];

  errorMgs = '';
  newProfile: Profile = new Profile();

  constructor(public service: Service, public modalCrtl: ModalController, private translate: TranslateService) {}

  ngOnInit() {
    this.newProfile = new Profile();
    if (GlobalFields.anonymousUserType === Constants.OWNER_ID) {
      this.newProfile.userTypeId = Constants.OWNER_ID;
    } else {
      this.newProfile.userTypeId = Constants.TENANT_ID;
    }

    this.service.getCountryList().subscribe(
      (res) => {
        if (res.success) {
          this.GlobalFields.countries = res.data;
          this.GlobalFields.countries.forEach((el) => {
            this.countries.push({ label: el.name + ' (' + el.phonePrefix + ')', value: el.id, icon: './assets/imgs/flags/' + el.code.toLowerCase() + '.png' });
          });
        } else {
          console.log(res.errorMessage);
        }
      },
      (err) => {
        console.error(err.message);
      }
    );
  }

  saveRoleType() {
    if (this.GlobalFields.isPlatformBrowser()) {
      this.GlobalFields.loadingSoft = true;

      if (
        this.newProfile.phone &&
        this.newProfile.phone !== '' &&
        this.newProfile.phone.length >= 9
      ) {
        this.GlobalFields.document.getElementById('phoneErr').style.display = 'none';
        this.GlobalFields.document.getElementById('phone').style.borderColor = '#ced4da';
        const tmpUserData: Profile = this.Constants.cloneObj(GlobalFields.currentUserInfo);
        tmpUserData.userTypeId = this.newProfile.userTypeId;
        tmpUserData.phone = this.newProfile.phone;
        this.service.editUserDetail(tmpUserData).subscribe(
          (res) => {
            if (res.success) {
              GlobalFields.currentUserInfo.userTypeId = this.newProfile.userTypeId;
              GlobalFields.currentUserInfo.phone = this.newProfile.phone;
              this.GlobalFields.loadingSoft = false;
            } else {
              this.errorMgs = res.errorMessage;
              this.GlobalFields.document.getElementById('phoneErr').style.display = 'block';
              this.GlobalFields.document.getElementById('phone').style.borderColor = 'red';
              this.GlobalFields.loadingSoft = false;
            }
          },
          (err) => {
            this.errorMgs = err.message;
            this.GlobalFields.loadingSoft = false;
          }
        );
      } else {
        this.GlobalFields.document.getElementById('phoneErr').style.display = 'block';
        this.GlobalFields.document.getElementById('phone').style.borderColor = 'red';
        this.errorMgs = this.translate.instant("LEAD_PROFESSIONAL.FILL_FIELDS");
        this.GlobalFields.loadingSoft = false;
      }
    }
  }

  confirmRegistration() {
    if (this.GlobalFields.isPlatformBrowser()) {
      this.GlobalFields.loadingSoft = true;

      if (this.newProfile.email) {
        this.GlobalFields.document.getElementById('emailErr').style.display = 'none';
        this.GlobalFields.document.getElementById('email').style.borderColor = '#ced4da';
        const tmpUserData: Profile = this.Constants.cloneObj(GlobalFields.currentUserInfo);
        tmpUserData.email = this.newProfile.email;
        this.service.editUserDetail(tmpUserData).subscribe(
          (res) => {
            if (res.success) {
              GlobalFields.currentUserInfo.email = this.newProfile.email;
              this.GlobalFields.loadingSoft = false;
              this.GlobalFields.callConversionStep('registration_complete_ty');
            } else {
              this.errorMgs =
                'La mail inserita è errata o già utilizzata da un altro utente. Inserisci una nuova mail';
              this.GlobalFields.document.getElementById('emailErr').style.display = 'block';
              this.GlobalFields.document.getElementById('email').style.borderColor = 'red';
              this.GlobalFields.loadingSoft = false;
            }
          },
          (err) => {
            this.errorMgs = err.message;
            this.GlobalFields.loadingSoft = false;
          }
        );
      } else {
        this.GlobalFields.document.getElementById('emailErr').style.display = 'block';
        this.GlobalFields.document.getElementById('email').style.borderColor = 'red';
        this.errorMgs = this.translate.instant("LEAD_PROFESSIONAL.FILL_FIELDS");
        this.GlobalFields.loadingSoft = false;
      }
    }
  }

  closeModal(reload?: boolean) {
    this.modalCrtl.dismiss({ reload: true });
  }
}
