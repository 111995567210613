import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ToastService } from 'ng-uikit-pro-standard';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelpersService } from '../services/helpers.service';
import { Constants } from '../Constants';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(
    private helpersService: HelpersService,
    private toastrService: ToastService
  ) {}
  /*
  * Intercept any API call and handle the error response by displaying the error message in the title + message format.
  * */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.toastrService.error(this.helpersService.findErrorMessage(error), this.helpersService.findErrorTitle(error), Constants.ToastOptions);
        return throwError(error);
      })
    )
  }
}
