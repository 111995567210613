export class PathConstants {
  public static searchPageRent = 'rent-listings';
  public static listingPageRent = 'rent-listing';
  public static hotelPage = 'travel/travel-listing';
  public static searchPageSale = 'properties-for-sale';
  public static listingPageSale = 'sale-listing';

  public static login = 'login';
  public static profile = 'profile';
  public static profile_old = 'profilo';

  public static calendar = 'calendar';
  public static calendar_visit = 'visit-request';
  public static requests = 'requests';
  public static requests_rent_request = 'rent-request';
  public static travel_booking = 'travel/my-booking';
  public static desk = 'desk';
  public static howItWorks = 'how-works';
  public static manual = 'manual';
  public static help = 'help';
  public static createListing = 'create-listing';

  // admin panel navigation
  // Dashboards
  public static adminDashboard = 'admin-panel'
  public static salesDashboard = 'sales'
  public static supplyDashboard = 'supply'
  public static seoDashboard = 'seo'
  public static notificationDashboard = 'notification'
  public static travelDashboard = 'travel'
  public static insightsDashboard = 'insights'
  // internal navigation
  public static visits = 'visits';
  public static settings = 'settings';
  public static rentRequests = 'rent-requests';
  public static leads = 'leads';
  public static users = 'users';
  public static structures = 'structures';
  public static listings = 'listings';
  public static tenantsPayments = 'tenants-payments';
  public static landlordsPayments = 'landlords-payments';
  public static partnerImport = 'partner-import';
  public static partnerExport = 'partner-export';
  public static settingsExport = 'settings-export';
  public static customerCare = 'customer-care';
  public static seoSettings = 'seo-settings';
  public static places = 'places';
  public static emails = 'emails';
  public static whatsapp = 'whatsapp';
  public static cities = 'cities';
  public static agents = 'agents';
  public static confirmedRequests = 'confirmed-requests';
  public static billings = 'billings';
  public static refundRequests = 'refund-requests';
  public static missingPayments = 'missing-payments';
  public static contracts = 'contracts';
  public static companyTargets = 'company-targets';
  public static salesInsights = 'sales';
  public static supplyInsights = 'supply';
  public static agentsInsights = 'agents-ranking';
  public static coupons = 'coupons';
  public static locare = 'locare';
  public static travelInsights = 'insights'
  public static travelBookings = 'bookings'

  // Personal pages
  public static myLeads = "my-leads-new"
  //public static myLeads = "my-leads"
}
