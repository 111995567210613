import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/Constants';
import { PathConstants } from 'src/app/PathConstants';


@Component({
  selector: 'app-fab-support',
  templateUrl: './fab-support.component.html',
  styleUrls: ['./fab-support.component.scss']
})
export class FabSupportComponent {
    Constants = Constants;
    PathConstants = PathConstants;
    isOpen = false;
    
    ticketGuide = {
      url: "https://bookstack.roomlessrent.com/books/open-project/page/ticket-managment",
      label: "Ticket Management Guide"
    }

    agentContacts = [
      { name: 'Giulia', phone: '+393285716319' }
    ];
  
    technicalContacts = [
      { name: 'Andrea', phone: '+393496521275' },
      { name: 'Michele', phone: '+393343777333' }
    ];

    toggleGuide(): void {
        this.isOpen = !this.isOpen;
    }

    currentSupportLinks: { url: string; label: string }[] | null = null;

    constructor(private router: Router) {
      this.router.events.subscribe(() => {
        const urlSegments = this.router.url.split('/');
        const lastSegment = urlSegments[urlSegments.length - 1];
        this.currentSupportLinks = Constants.SupportLinks[lastSegment] || null;
      });
      
    }
}
