import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {LoginComponent} from '../components/login/login.component';
import {ModalLogin} from '../components/login/modalLogin/modalLogin';
import {SelectModule} from 'ng-uikit-pro-standard';

@NgModule({
    imports: [
        SelectModule,
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        RouterModule
    ],
    declarations: [LoginComponent, ModalLogin],
    exports: [LoginComponent, ModalLogin],
    providers: []
})
export class LoginSharedModule {
}
